import {ENDPOINT} from '../config';
import { toggleButtonLoading} from '../utils';

$(function () {
  const $replacePincodeForm = $('[data-selector*="replace-pincode-form"]');
  const $passwordInput = $('[data-selector*="password"]');
  const $passwordConfirmInput = $('[data-selector*="confirm-password"]');
  const $formError = $('[data-selector*="replace-pincode-error"]');
  const $submitBtn = $('[data-selector*="replace-pincode-button"]');

  $replacePincodeForm.submit(function (e) {
    e.preventDefault();

    $formError.html('');
    $formError.hide();

    const parsleyForm = $replacePincodeForm.parsley();
    const payload = {
      password: $passwordInput.val(),
      confirmation_password: $passwordConfirmInput.val()
    };

    if (!parsleyForm.isValid()) {
      parsleyForm.validate();
      return;
    }

    toggleButtonLoading($submitBtn, true);

    $.ajax({
      url: ENDPOINT.SET_PASSWORD,
      contentType: 'application/json',
      type: 'PUT',
      data: JSON.stringify(payload)
    }).then(function (response) {
      if (response && response.redirect_url) {
        window.location.href = response.redirect_url;
      } else {
        window.location = '/';
      }
    }, function (error) {
      toggleButtonLoading($submitBtn, false);

      const errors = JSON.parse(error.responseText);
      $formError.html(errors.error);
      $formError.show();
    });
  });
});
