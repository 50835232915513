import {
  getCurrencyPrice,
  getSelectedOfferDetails,
  getTotalPricesInCents,
  handleDropinToken,
  setCheckoutBtnTitle,
  setDefaultOfferSubmitText
} from './subscription.utils';
import {
  braintreeDropinConfig,
  capitalize,
  getError,
  getUserSources,
  isFormValid,
  isThreeDSecureCanceled,
  scrollToHashedElement,
  threeDSecureConfig,
  toggleButtonLoading,
  storage,
  toggleStepProgressBar
} from '../utils';
import {
  ACCOUNT_TYPES,
  CURRENCY,
  CUSTOM_MESSAGE,
  DROPIN_SCRIPT_URL,
  ENDPOINT,
  SUBSCRIPTION_PERIOD_MAPPING,
  SUBSCRIPTION_PERIODS,
  URLS,
  LOCAL_STORAGE_KEYS
} from '../config';

$(document).ready(() => {
  const $subscriptionSliderSection = $('[data-selector="subscription-slider-section"]');
  if (!$subscriptionSliderSection.length) return;

  const subscriptionPlansSliderHash = '#subscription-plans-slider';
  const $shippingFormItem = $('[data-selector="shipping-addon-form-item"]');
  const shippingCountriesSelect = '[data-selector="shipping-countries-select"]';
  const shippingFormItemSelect = '[data-selector="shipping-addon-form-item"]';
  const hiddenInputSelect = '[data-selector="buy-parent-subscription-slider-special-input"]';
  const section = {
    first: 0,
    second: 1
  };
  const checkoutTextAttr = {
    trial: 'data-trial-text',
    noTrial: 'data-no-trial-text'
  };

  const isEbloxAdded = false;

  // tabs block
  const tabSelector = '[data-selector="subscription-plans-tab"]';
  const $tab = $(tabSelector);
  const activeTabClass = '_active';
  const tabPeriodAttr = 'data-tab-period';

  // slider
  const $sliderSection = $('[data-selector="subscription-slider-section"]');
  const $sliderLoader = $('[data-selector="subscription-slider-loader"]');
  const $subscriptionSlider = $('[data-selector="subscription-slider"]');
  const slideSelector = '[data-selector="subscription-slider-item"]';
  const $slide = $(slideSelector);
  const slideIndexAttr = 'data-slick-index';

  // plan attributes
  const planAttr = {
    isTrialRadioEnabled: 'data-is-trial-radio-enabled',
    slug: 'data-slug',
    trialDuration: 'data-trial-duration',
    offerPeriod: 'data-offer-period',
    price: 'data-price',
    backUrl: 'data-offer-back-url',
    offerPeriodDisplay: 'data-offer-period-display',
    submitText: 'data-offer-submit',
    isCouponEnabled: 'data-is-coupon-enabled',
    showOnboardingSteps: 'data-show-onboarding-steps'
  };

  // subscription plans
  const planInput = '[data-selector="subscription-plan-slider-input"]';
  const $planInput = $(planInput);
  const selectedPlanInput = `${planInput}:checked`;
  const goToPaymentStepBtn = '[data-selector="go-to-checkout-slider-btn"]';
  const $goToPaymentStepBtn = $(goToPaymentStepBtn);

  // subscription purchase (second step)
  const $purchaseSection = $('[data-selector="subscription-slider-payment"]');
  const $submitPaymentBtn = $('[data-selector="slider-submit-payment-btn"]');
  const $subscriptionError = $('[data-selector="slider-subscription-error"]');
  const prevSectionButton = '[data-selector="back-to-prev-slider-section-btn"]';
  const $prevSectionButton = $(prevSectionButton);
  const braintreeDropin = '#subscription-slider-plans-dropin-container';
  const $stepProgressBar = $('[data-selector="step-progress-bar"]');

  // subscription details elements
  const $planPeriod = $('[data-selector="slider-plan-period-value"]');
  const $planFullPrice = $('[data-selector="slider-plan-full-price-value"]');
  const $planTrialPeriod = $('[data-selector="slider-plan-trial-period-value"]');
  const $planDiscountRow = $('[data-selector="slider-plan-discount-row"]');
  const $planTrialRow = $('[data-selector="slider-plan-trial-row"]');
  const $planDiscount = $('[data-selector="slider-plan-discount-value"]');
  const $planTotalPrice = $('[data-selector="slider-plan-total-price-value"]');
  const $planRenewCondition = $('[data-selector="slider-plan-renew-condition"]');

  // coupon block is not provided
  const amountOff = 0;
  const isSpecialCouponApplied = false;
  const specialCouponTrialDuration = null;
  const activeCoupon = null;


  const getPreselectedSlide = () => {
    if (!$(selectedPlanInput).length) return 0;

    const $selectedSlide = $(selectedPlanInput).closest(slideSelector);
    return $slide.index($selectedSlide);
  };

  const initSlider = (initialSlideIndex) => {
    return new Promise((resolve) => {
      // callback for the first time Slick initializes
      $subscriptionSlider.on('init', () => {
        $sliderLoader.hide();
        $subscriptionSlider.show();
        resolve();
      });

      $subscriptionSlider.not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        swipeToSlide: true,
        centerPadding: 50,
        infinite: false,
        initialSlide: initialSlideIndex,
        responsive: [{
          breakpoint: 767,
          settings: {
            adaptiveHeight: true
          }
        }]
      });
    });
  };

  const changeActiveTab = ($targetTab) => {
    $tab.each((index, tab) => {
      $(tab).removeClass(activeTabClass);
      $targetTab.addClass(activeTabClass);
    });
  };

  const getSlideToShowIndex = ($activeTab) => {
    const activeTabPeriod = $activeTab.attr(tabPeriodAttr);
    const $relatedSlider = $planInput
      .filter(`[${planAttr.offerPeriod}=${activeTabPeriod}]`)
      .closest(slideSelector);

    return $relatedSlider.attr(slideIndexAttr);
  };

  const handleSliderIndexChange = (event, slick, currentSlideIndex, nextSlideIndex) => {
    const $nextSlide = $slide.filter(`[${slideIndexAttr}=${nextSlideIndex}]`);
    const nextSlidePeriod = $nextSlide.find(planInput).attr(planAttr.offerPeriod);
    const $relatedTab = $tab.filter(`[${tabPeriodAttr}=${nextSlidePeriod}]`);
    if ($relatedTab.hasClass(activeTabClass)) return;

    changeActiveTab($relatedTab);
  };

  const selectCurrentSlide = (event, slick, currentSlideIndex) => {
    const $currentSlide = $slide.filter(`[${slideIndexAttr}=${currentSlideIndex}]`);
    $currentSlide.find(planInput).prop('checked', true);
    setDefaultOfferSubmitText($goToPaymentStepBtn, $(selectedPlanInput).attr(planAttr.submitText));
  };

  const dropinTokenCallback = () => {
    if (!window.braintree) {
      goToPaymentErrorHandler();
      return;
    }

    toggleButtonLoading($goToPaymentStepBtn, false);
    preparePurchaseStep();
  };

  const goToPaymentErrorHandler = (err) => {
    toastr.error(getError(err), '', {timeOut: 3000});
    toggleButtonLoading($goToPaymentStepBtn, false);
  };

  const isTrialActive = () => {
    if (!window.ableToGetTrial) return false;

    if (isSpecialCouponApplied) return true;

    const trialDuration = $(selectedPlanInput).attr(planAttr.trialDuration);
    const isTrialDisabled = parseInt(trialDuration) === 0;
    if (isTrialDisabled) return false;

    if (activeCoupon) return false;

    return true;
  };

  const doSignUp = () => {
    ga('send', 'event', 'Registration', 'Go', 'Parent');

    $.ajax({
      url: ENDPOINT.USER_PROFILE,
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({
        account_type: ACCOUNT_TYPES.PARENT,
        email: null,
        need_to_login: true,
        source: getUserSources('source'),
        medium: getUserSources('medium'),
        campaign: getUserSources('campaign'),
        placement: getUserSources('placement')
      })
    }).then(() => {
      window.isLoggedIn = true;
      $.getScript(
        DROPIN_SCRIPT_URL,
        handleDropinToken(dropinTokenCallback, goToPaymentErrorHandler)
      );
    }, (error) => goToPaymentErrorHandler(error));
  };

  const renderSelectedOfferDetails = (
    planPeriod,
    planFullPrice,
    planTrialPeriod,
    planFormattedNewPrice,
    planPeriodDisplay
  ) => {
    $planPeriod.text(capitalize(planPeriodDisplay));
    $planFullPrice.text(`${CURRENCY}${planFullPrice}`);

    if (activeCoupon && amountOff > 0) {
      $planDiscount.text(`${CURRENCY}${amountOff}`);
      $planDiscountRow.show();
    }

    if (isTrialActive()) {
      $planTrialPeriod.text(`${planTrialPeriod} days`);
      $planTrialRow.show();
      $planTotalPrice.text(`${CURRENCY}0.00`);

      if (planPeriod !== SUBSCRIPTION_PERIODS.LIFETIME) {
        const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriod](planFullPrice);
        $planRenewCondition.html(text);
      }

      $planRenewCondition.show();
    } else $planTotalPrice.text(`${CURRENCY}${planFormattedNewPrice}`);
  };

  const getTotalPrices = (isTrialEnabled) => {
    const planFullPriceInCents = $(selectedPlanInput).attr(planAttr.price);
    const totalPrices = getTotalPricesInCents(
      planFullPriceInCents,
      isTrialEnabled,
      isEbloxAdded,
      activeCoupon,
      amountOff,
      shippingCountriesSelect
    );

    return totalPrices;
  };

  const setTotalPriceToUI = (totalPriceInCents) => {
    const currencyPrice = getCurrencyPrice(totalPriceInCents);
    $planTotalPrice.html(currencyPrice);
  };

  const triggerGAEventOnGoToPayment = (offerPeriod) => {
    if (typeof offerPeriod === 'string' && offerPeriod.length) {
      const formattedOfferPeriod = offerPeriod.replace(/^./, offerPeriod[0].toUpperCase());
      ga('send', 'event', 'Subscription', 'Initiated', formattedOfferPeriod);
    }
  };

  const handleProgressBar = () => {
    const isStepsShownStr = $(selectedPlanInput).attr(planAttr.showOnboardingSteps);
    storage.set(LOCAL_STORAGE_KEYS.IS_SHOW_STEP_PROGRESS_BAR, isStepsShownStr);

    toggleStepProgressBar($stepProgressBar);
  };

  const prepareFirstSectionUI = () => {
    $purchaseSection.hide();
    $sliderSection.show();
    $subscriptionError.hide();
    $(braintreeDropin).html('');
    $planRenewCondition.text('');
  };

  const prepareSecondSectionUI = () => {
    toggleButtonLoading($prevSectionButton, true);
    $sliderSection.hide();
    $planDiscountRow.hide();
    $planTrialRow.hide();
    $planRenewCondition.hide();
    $purchaseSection.show();
    handleProgressBar();
  };

  const selectSection = (sectionId) => {
    if (sectionId === section.first) {
      prepareFirstSectionUI();
      // Remove an event listener set by braintree-creating function so as
      // to prevent creating multiple event handlers in case of function call repeat
      $submitPaymentBtn.off('click');
      return;
    }

    if (sectionId === section.second) {
      prepareSecondSectionUI();
      setCheckoutBtnTitle($submitPaymentBtn, isTrialActive(), checkoutTextAttr);
    }
  };

  const preparePurchaseStep = () => {
    scrollToHashedElement(subscriptionPlansSliderHash, {scrollTimeout: 0});
    $submitPaymentBtn.hide();
    selectSection(section.second);

    const offer = $(selectedPlanInput);
    const {
      planPeriod,
      planFullPrice,
      planFormattedNewPrice,
      planSlug,
      planBackUrl,
      planPeriodDisplay
    } = getSelectedOfferDetails(offer, amountOff, planAttr);
    const planTrialPeriod = (isSpecialCouponApplied && specialCouponTrialDuration)
        ? specialCouponTrialDuration
        : offer.attr(planAttr.trialDuration);

    const isTrialEnabled = isTrialActive();

    renderSelectedOfferDetails(
      planPeriod,
      planFullPrice,
      planTrialPeriod,
      planFormattedNewPrice,
      planPeriodDisplay
    );

    const { totalPriceInCents, priceFor3DSecure } = getTotalPrices(isTrialEnabled);

    setTotalPriceToUI(totalPriceInCents);

    triggerGAEventOnGoToPayment(planPeriod);

    initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planSlug, planBackUrl, isTrialEnabled);
  };

  const getPurchaseRequestData = (paymentMethodNonce, slug, isTrialEnabled) => {
    const result = {
      payment_method_nonce: paymentMethodNonce,
      plan_slug: slug,
      source: 'main-flow-offer',
      discount_id: activeCoupon,
      trial_enabled: isTrialEnabled,
      note: ''
    };

    if (isEbloxAdded) {
      // collect eblox addon & shipping data
      const formFields = {};
      const shippingFormDataArray = $shippingFormItem.serializeArray();

      shippingFormDataArray.forEach((item) => {
        formFields[item.name] = item.value;
      });

      result.shipping_address = formFields;
      result.addons = [{addon_id: ebloxAddonId}, {addon_id: shippingAddonId}];
    }

    return result;
  };

  const triggerGAEventOnCheckout = (isTrialEnabled) => {
    if (isTrialEnabled) {
      ga('send', 'event', 'Trial', 'Started', 'Parent');
      if (isEbloxAdded) ga('send', 'event', 'Eblox', 'Started', 'Parent');
      return;
    }

    ga('send', 'event', 'Subscription', 'Started', 'Parent');
  };

  const triggerAnalyticsOnCheckout = (response, slug, backUrl) => {
    (window.ableToGetTrial && window.user.email)
        ? window.location = `/${URLS.SIGN_UP_PARENT_COMPLETE}/`
        : window.location = backUrl;
  };

  const initBraintreeDropin = (
    totalPriceInCents,
    priceFor3DSecure,
    slug,
    backUrl,
    isTrialEnabled
  ) => {
    if (braintree && $(braintreeDropin).length) {
      const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
      const braintreeConf = braintreeDropinConfig(totalPriceInDollars, braintreeDropin);

      braintree.dropin.create(braintreeConf,
        function (createErr, instance) {
          if (createErr) {
            // An error in the create call is likely due to
            // incorrect configuration values or network issues.
            // An appropriate error will be shown in the UI.

            $subscriptionError.html(
              `${createErr.message}
               <br>${CUSTOM_MESSAGE.BRAINTREE_CREATE_ERROR}`
            );
            $subscriptionError.show();
            toggleButtonLoading($prevSectionButton, false);
            // eslint-disable-next-line no-console
            console.error('dropin create error: ', createErr);
            return;
          }

          if (instance.isPaymentMethodRequestable()) {
            // This will be true if you generated the client token
            // with a customer ID and there is a saved payment method
            // available to tokenize with that customer.
            $submitPaymentBtn.show();
          }

          instance.on('paymentMethodRequestable', () => {
            $submitPaymentBtn.show();
            $subscriptionError.hide();
          });

          instance.on('noPaymentMethodRequestable', () => {
            $submitPaymentBtn.hide();
            $subscriptionError.hide();
          });

          instance.on('paymentOptionSelected', () => {
            $subscriptionError.hide();
            if (instance.isPaymentMethodRequestable()) {
              $submitPaymentBtn.show();
              return;
            }
            $submitPaymentBtn.hide();
          });

          toggleButtonLoading($prevSectionButton, false);
          toggleButtonLoading($submitPaymentBtn, false);

          $submitPaymentBtn.click(() => {
            // To prevent bot spamming we use solution from
            // https://www.thryv.com/blog/honeypot-technique/
            const $hiddenInput = $(hiddenInputSelect);
            const hiddenInputVal = $hiddenInput.val();
            if (hiddenInputVal) return;

            if (isEbloxAdded && !isFormValid($(shippingFormItemSelect))) return;

            $subscriptionError.hide();
            $prevSectionButton.hide();
            toggleButtonLoading($submitPaymentBtn, true);

            const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
            const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

            instance.requestPaymentMethod({
              threeDSecure: threeDSecureConf
            }, function (requestPaymentMethodErr, payload) {
              if (requestPaymentMethodErr) {
                // eslint-disable-next-line no-console
                console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);
                $subscriptionError.text(requestPaymentMethodErr.message);
                $subscriptionError.show();
                $prevSectionButton.show();
                toggleButtonLoading($submitPaymentBtn, false);
                return;
              }

              if (isThreeDSecureCanceled(payload)) {
                instance.clearSelectedPaymentMethod();
                $subscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                $subscriptionError.show();
                toggleButtonLoading($submitPaymentBtn, false);
                $prevSectionButton.show();
                return;
              }

              // Submit payload.nonce to your server
              const requestData = getPurchaseRequestData(payload.nonce, slug, isTrialEnabled);
              $.post(ENDPOINT.BUY_SUBSCRIPTION, requestData)
                .then((response) => {
                  triggerGAEventOnCheckout(isTrialEnabled);
                  triggerAnalyticsOnCheckout(response, slug, backUrl);
                }, (errorResponse) => {
                  instance.clearSelectedPaymentMethod();
                  $subscriptionError.text('');
                  $subscriptionError.append(getError(errorResponse));
                  $subscriptionError.show();

                  toggleButtonLoading($submitPaymentBtn, false);
                });
            });
          });
        });
    }
  };

  $subscriptionSlider
    .on('beforeChange', handleSliderIndexChange.bind(this))
    .on('afterChange', selectCurrentSlide.bind(this));

  $(document)
    .on('click', tabSelector, (e) => {
      const $clickedTab = $(e.target).closest(tabSelector);
      if ($clickedTab.hasClass(activeTabClass)) return;

      changeActiveTab($clickedTab);
      const targetSlideIndex = getSlideToShowIndex($clickedTab);
      $subscriptionSlider.slick('slickGoTo', targetSlideIndex);
    })
    .on('click', goToPaymentStepBtn, (e) => {
      e.preventDefault();
      toggleButtonLoading($goToPaymentStepBtn, true);
      if (!window.isLoggedIn) {
        doSignUp();
      } else {
        $.getScript(DROPIN_SCRIPT_URL,
          handleDropinToken(dropinTokenCallback, goToPaymentErrorHandler)
        );
      }
    })
    .on('click', prevSectionButton, () => {
      scrollToHashedElement(subscriptionPlansSliderHash, {scrollTimeout: 0});
      selectSection(section.first);
      initSlider(initialSlideIndex).then($subscriptionSlider.slick('refresh'));
    });

  const initialSlideIndex = getPreselectedSlide();

  // 'refresh' method is called to solve width:0 problem with Slick initialized on hidden element
  initSlider(initialSlideIndex).then($subscriptionSlider.slick('refresh'));
  setDefaultOfferSubmitText($goToPaymentStepBtn, $(selectedPlanInput).attr(planAttr.submitText));
});
