import {
  CUSTOM_MESSAGE,
  ENDPOINT,
  URLS
} from '../config';
import {
  initGoogleRecaptcha,
  getError,
  handleCAPTCHAError,
  braintreeDropinConfig,
  threeDSecureConfig,
  isASCII,
  toggleButtonLoading,
  isThreeDSecureCanceled
} from '../utils';
import {handleDropinToken} from '../payment/subscription.utils';


$(document).ready(function () {
    const braintreeDropin = '#purchase-dropin-container';
    const $errorElement = $('#card-errors');
    const $button = $('#submit-button');

    const $emailForm = $('#email-form');
    const $purchaserEmail = $('#email-from-email');
    const $purchaserName = $('#email-from-name');
    const $recipientEmail = $('#email-to-email');
    const $recipientName = $('#email-to-name');
    const $message = $('#email-message');

    const $billingName = $('#billing-name');
    const $billingSurname = $('#billing-surname');

    const $termsCheckbox = $('#terms-checkbox');

    const selectedSubscriptionTypeSelector = 'input[name=subscription-type]:checked';

    const formParsley = $emailForm.parsley();
    const termsParsley = $termsCheckbox.parsley();

    let isPaymentMethodSelected = false;

    let googleCaptchaToken = null;

    const onRecaptchaExpire = () => {
      googleCaptchaToken = null;
    };

    function setInputsDisabled(value) {
      $purchaserEmail.prop('disabled', value);
      $purchaserName.prop('disabled', value);
      $recipientEmail.prop('disabled', value);
      $recipientName.prop('disabled', value);
      $message.prop('disabled', value);
      $billingName.prop('disabled', value);
      $billingSurname.prop('disabled', value);
    }

    function disabledSubmitBtn() {
      $button.prop('disabled', true);
    }

    function enabledSubmitBtn() {
      $button.prop('disabled', false);
    }

    function isTermsAgreed() {
      return $termsCheckbox.is(':checked');
    }

    $termsCheckbox.change(function () {
      if (isTermsAgreed() && isPaymentMethodSelected) {
        enabledSubmitBtn();
      } else {
        disabledSubmitBtn();
      }
    });

    const currentUrl = $('[data-url]').attr('data-url');
    if (currentUrl === URLS.GIFT_PURCHASE) {
      handleDropinToken(() => {
        const planPrice = $(selectedSubscriptionTypeSelector).data('price');
        const braintreeConf = braintreeDropinConfig(planPrice, braintreeDropin);

        if (typeof braintree !== 'undefined' && $(braintreeDropin).length) {
          braintree.dropin.create(braintreeConf, function (createErr, dropInstance) {
          if (createErr) {
            // An error in the create call is likely due to
            // incorrect configuration values or network issues.
            // An appropriate error will be shown in the UI.

            // eslint-disable-next-line no-console
            console.error(createErr);
            return;
          }

          if (dropInstance.isPaymentMethodRequestable()) {
            // This will be true if you generated the client token
            // with a customer ID and there is a saved payment method
            // available to tokenize with that customer.
            isPaymentMethodSelected = true;
          }

          dropInstance.on('paymentMethodRequestable', function () {
            isPaymentMethodSelected = true;
            if (isTermsAgreed()) {
              enabledSubmitBtn();
            }
          });

          dropInstance.on('noPaymentMethodRequestable', function () {
            isPaymentMethodSelected = false;
            disabledSubmitBtn();
          });

        $button.click(function () {
          // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
          const hiddenInputVal = $('[data-selector="gift-purchase-special-input"]').val();
          if (hiddenInputVal) return;

          if (!googleCaptchaToken) {
            $errorElement.html('Please complete the CAPTCHA');
            $errorElement.show();
            return;
          }

            if (isTermsAgreed() && formParsley.isValid()) {
              toggleButtonLoading($button, true);
              setInputsDisabled(true);

              const product = $('input[name=subscription-type]:checked');
              const productPrice = product.attr('data-price');
              const threeDSecureConf = threeDSecureConfig(productPrice);
              threeDSecureConf.email = $purchaserEmail.val();
              let givenName = $billingName.val();
              let surname = $billingSurname.val();
              if(!isASCII(givenName) || !isASCII(surname)) {
                givenName = '';
                surname = '';
              }
              threeDSecureConf.billingAddress.givenName = givenName;
              threeDSecureConf.billingAddress.surname = surname;

              dropInstance.requestPaymentMethod({
                threeDSecure: threeDSecureConf
              }, function (requestPaymentMethodErr, payload) {
                if (requestPaymentMethodErr) {
                  $errorElement.text(requestPaymentMethodErr.message);
                  $errorElement.show();

                  toggleButtonLoading($button, false);
                  setInputsDisabled(false);
                  return;
                }

                if (isThreeDSecureCanceled(payload)) {
                  dropInstance.clearSelectedPaymentMethod();
                  $errorElement.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                  $errorElement.show();
                  toggleButtonLoading($button, false);
                  setInputsDisabled(false);
                  return;
                }

                ga('send', 'event', 'Button', 'Submit', 'Gift');
                // Submit payload.nonce to your server
                $.post(ENDPOINT.GIFT_PURCHASE, {
                  nonce: payload.nonce,
                  product: $(selectedSubscriptionTypeSelector).val(),
                  purchaser_email: $purchaserEmail.val(),
                  purchaser_name: $purchaserName.val(),
                  captcha_response_token: googleCaptchaToken,
                  recipient_email: $recipientEmail.val(),
                  recipient_name: $recipientName.val(),
                  message: $message.val(),
                  billing_name: $billingName.val()
                }).then(function () {
                  window.location.href = '/gift/purchase/success/';
                }, function (errorResp) {
                  dropInstance.clearSelectedPaymentMethod();
                  toggleButtonLoading($button, false);
                  setInputsDisabled(false);
                  disabledSubmitBtn();
                  handleCAPTCHAError(errorResp);

                  const error = getError(errorResp);
                  $errorElement.text(error);
                  $errorElement.show();
                });
              });
            } else {
              formParsley.validate();
              termsParsley.validate();
            }
          });
        });

          initGoogleRecaptcha((token) => {
            $errorElement.hide();
            googleCaptchaToken = token;
          }, onRecaptchaExpire);
        }
      }, () => {});
    }
  }
);
