import {
  getError,
  getUserSources,
  toggleButtonLoading,
  scrollToTop,
} from '../utils';
import toastr from 'toastr';
import {
  SUBJECTS_CONFIG,
  ENDPOINT,
  ACCOUNT_TYPES,
  URLS
} from '../config';


$(document).ready(function () {
  const $signUpChessPage = $('[data-selector="sign-up-chess"]');
  if (!$signUpChessPage.length) return;

  // steps
  const $chooseAccountTypeStep = $('[data-selector="chess-choose-account-type-step"]');
  const $setNameStep = $('[data-selector="chess-set-name-step"]');
  const $chessLevelStep1 = $('[data-selector="chess-level-question-step-1"]');
  const $chessLevelStep2 = $('[data-selector="chess-level-question-step-2"]');
  const $finalStep = $('[data-selector="chess-last-step"]');

  // kid name
  const kidNameInput = '[data-selector="chess-kid-name-input"]';
  const $kidNameElem = $('[data-selector="chess-kid-name-elem"]');

  // choose acc step
  const parentButton = '[data-selector="sign-up-chess-parent-btn"]';

  // set name, avatar, garde step
  const kidCreateForm = '[data-selector="create-kid-form"]';
  const avatarRadioBtn = '[data-selector="sign-up-chess-avatar-radio"]';
  const gradeRadioBtn = '[data-selector="sign-up-chess-grade-radio"]';
  const selectedAvatarRadioBtn = avatarRadioBtn + ':checked';
  const selectedGradeRadioBtn = gradeRadioBtn + ':checked';
  const chessSetNameNextBtn = '[data-selector="sign-up-chess-go-to-chess-level-step"]';
  const chessSetNameBackBtn = '[data-selector="sign-up-chess-back-to-first-step"]';

  // chess question 1 step
  const chessQuestion1StepNextBtn = '[data-selector="chess-question-1-step-next-btn"]';
  const chessQuestion1StepBackBtn = '[data-selector="chess-question-1-step-back-btn"]';
  const chessQuestion1StepRadio = '[data-selector="chess-question-1-radio"]';
  const selectedChessQuestion1Radio = chessQuestion1StepRadio + ':checked';

  // chess question 2 step
  const chessQuestion2StepNextBtn = '[data-selector="chess-question-2-step-next-btn"]';
  const chessQuestion2StepBackBtn = '[data-selector="chess-question-2-step-back-btn"]';

  const chessGoodLevelRadio = '[data-selector="chess-question-2-step-radio"]';
  const selectedChessGoodLevelRadio = chessGoodLevelRadio + ':checked';

  const $chessQuestion2StepGoodText = $('[data-selector="chess-question-2-step-good-text"]');
  const $chessQuestion2StepBadText = $('[data-selector="chess-question-2-step-bad-text"]');

  // final step
  const goToPlansButton = '[data-selector="chess-last-step-go-to-plans-button"]';

  const $gradeHTMLElem = $('[data-selector="chess-grade"]');
  const $subjectsHTMLElem = $('[data-selector="chess-subjects"]');

  const $chessLastStepBadTexts = $('[data-selector="chess-last-step-bad-text"]');
  const $chessLastStepGoodTexts = $('[data-selector="chess-last-step-good-text"]');

  // kid data
  const kidData = {
    gradeName: '',
    gradeID: '',
    gradeVal: '',
    subjectsArray: [],
    avatar:'',
    name: ''
  };

  const setKidNameToHTML = () => {
    const kidName = $(kidNameInput).val();

    if (kidName.length) {
      $kidNameElem.html(kidName);
    }
  };

  const getSubjectsArrayForSelectedGrade = (gradeID) => {
    let subjectsTitlesArray = [];
    const subjectsArray = SUBJECTS_CONFIG[gradeID];

    kidData.subjectsArray = subjectsArray;

    subjectsTitlesArray = subjectsArray.map((subject) => subject.name);
    kidData.subjectsArray = subjectsTitlesArray;
    return subjectsTitlesArray;
  };

  $(document)
    .on('click', parentButton, () => {
      ga('send', 'event', 'Registration', 'Go_Chess', 'Parent');
      const $parentButton = $(parentButton);

      toggleButtonLoading($parentButton, true);

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          account_type: ACCOUNT_TYPES.PARENT,
          email: null,
          need_to_login: true,
          source: getUserSources('source'),
          medium: getUserSources('medium'),
          campaign: getUserSources('campaign'),
          placement: getUserSources('placement')
        })
      }).then(() => {
        toggleButtonLoading($parentButton, false);
        $chooseAccountTypeStep.hide();
        $setNameStep.show();
      }, (error) => {
        toggleButtonLoading($parentButton, false);
        const errors = JSON.parse(error.responseText);
        toastr.error(errors.error, '', {timeOut: 3000});
      });
    })
    // set name step back button click
    .on('click', chessSetNameBackBtn, () => {
      scrollToTop();
      $chooseAccountTypeStep.show();
      $setNameStep.hide();
    })
    // avatar/grade/name step next btn click
    .on('click', chessSetNameNextBtn, () => {
      const $selectedAvatar = $(selectedAvatarRadioBtn);
      const $selectedGrade = $(selectedGradeRadioBtn);

      const isAvatarSelected = !!$selectedAvatar.length;
      const isGradeSelected = !!$selectedGrade.length;

      const kidCreateFormParsley = $(kidCreateForm).parsley();
      kidCreateFormParsley.validate();

      const isNameValid = kidCreateFormParsley.isValid();

      if(!isAvatarSelected) toastr.warning('Please, select avatar');
      if(!isGradeSelected) toastr.warning('Please, select grade');

      if (!isGradeSelected
       || !isAvatarSelected
       || !isNameValid) return;


      kidData.avatar = $selectedAvatar.val();
      kidData.gradeName = $selectedGrade.attr('data-grade');
      kidData.gradeID = $selectedGrade.attr('id');
      kidData.gradeVal = $selectedGrade.val();
      kidData.name = $(kidNameInput).val();

      $setNameStep.hide();
      $chessLevelStep1.show();

      setKidNameToHTML();

      ga('send', 'event', 'Name_Chess', 'Next', 'Parent');
    })
    // question 1 next button
    .on('click', chessQuestion1StepNextBtn, () => {
      const $selectedChessQuestion1Radio = $(selectedChessQuestion1Radio);
      const isChessLevelSelected = !!$selectedChessQuestion1Radio.length;

      if(!isChessLevelSelected) {
        toastr.warning('Please, answer the question');
        return;
      }

      switch($selectedChessQuestion1Radio.val()) {
        case '0':
          $chessQuestion2StepGoodText.show();
          break;

        case '1':
          $chessQuestion2StepBadText.show();
          break;
      }

      $chessLevelStep1.hide();
      $chessLevelStep2.show();

      ga('send', 'event', 'Question_1', 'Next', 'Parent');
    })
    // question 1 step back button
    .on('click', chessQuestion1StepBackBtn, () => {
      $chessLevelStep1.hide();
      $setNameStep.show();
    })
    // question 2 step next button
    .on('click', chessQuestion2StepNextBtn, () => {
      const $selectedChessGoodLevelRadio = $(selectedChessGoodLevelRadio);
      const isRadioSelected = !!$selectedChessGoodLevelRadio.length;

      if(!isRadioSelected) {
        toastr.warning('Please, answer the question');
        return;
      }

      switch($selectedChessGoodLevelRadio.val()) {
        case '0':
          $chessLastStepGoodTexts.show();
          break;

        case '1':
          $chessLastStepBadTexts.show();
          break;
      }

      $gradeHTMLElem.html(kidData.gradeName);
      const subjectsArray = getSubjectsArrayForSelectedGrade(kidData.gradeID);
      const subjectsFormattedString = subjectsArray.join(', ');
      $subjectsHTMLElem.html(subjectsFormattedString);

      $chessLevelStep2.hide();
      $finalStep.show();

      ga('send', 'event', 'Question_2', 'Next', 'Parent');
    })
    // question 2 step back button
    .on('click', chessQuestion2StepBackBtn, () => {
      $chessLevelStep1.show();
      $chessLevelStep2.hide();

      $chessQuestion2StepGoodText.hide();
      $chessQuestion2StepBadText.hide();
    })
    .on('click', goToPlansButton, () => {
      const $goToPlansButton = $(goToPlansButton);
      toggleButtonLoading($goToPlansButton, true);

      const data = {
        'first_name': kidData.name,
        'account_type': ACCOUNT_TYPES.KID,
        'source': 'website',
        'medium': 'kid-chess-flow',
        'grade': kidData.gradeVal,
        'data': {
          avatarId: kidData.avatar,
          subjects: kidData.subjectsArray
        }
      };

      $.ajax({
        url: ENDPOINT.KID_PROFILE_CREATE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify(data)
      }).then(() => {
        ga('send', 'event', 'PlanReady_Chess', 'StartLearning', 'Parent');

        window.location = `/${URLS.PARENT_SUBSCRIPTION_PLANS}`;
      }, (error) => {
        toggleButtonLoading($goToPlansButton, false);
        const errorText = getError(error);

        toastr.warning(errorText);
      });
    });
});
