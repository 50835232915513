$(document).ready(() => {
  const fbShareButtonSelector = '[data-selector="fb-share-button"]';
  const twitterShareButtonSelector = '[data-selector="twitter-share-button"]';
  const pinterestShareButtonSelector = '[data-selector="pinterest-share-button"]';

  const pageUrl = window.location.href;
  const metaUserId = document.head.querySelector('[name=certificateUserId]');
  const certificateUserId = metaUserId ? metaUserId.content : null;

  // facebook sharing button
  $(document)
    .on('click', fbShareButtonSelector, () => {
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + pageUrl,
        'facebook-share-dialog',
        'width=800,height=600'
      );
      if (certificateUserId) {
        ga('send', 'event', 'Certificate', 'Facebook', `${certificateUserId}`);
      };
    })
    .on('click', twitterShareButtonSelector, () => {
      if (certificateUserId) {
        ga('send', 'event', 'Certificate', 'Twitter', `${certificateUserId}`);
      };
    })
    .on('click', pinterestShareButtonSelector, () => {
      if (certificateUserId) {
        ga('send', 'event', 'Certificate', 'Pinterest', `${certificateUserId}`);
      };
    });
});
