import {
  ACCOUNT_TYPES,
  ENDPOINT,
  LOCAL_STORAGE_KEYS,
  MODALS
} from '../config';
import {
  getError,
  getUserSources,
  toggleButtonLoading,
  storage
} from '../utils';
import toastr from 'toastr';

$(document).ready(() => {
  const $freeSignUpModal = $('[data-selector="free-sign-up-modal"]');
  if (!$freeSignUpModal.length) return;

  const $mailingCheckboxBlock = $('[data-selector="free-sign-up-terms-checkbox-block"]');
  const $termsCheckboxBlock = $('[data-selector="free-sign-up-mailing-checkbox-block"]');
  const $confirmPswBlock = $('[data-selector="free-sign-up-terms-confirm-psw-block"]');

  const $authForm = $('[data-selector="free-sign-up-form"]');
  const email = '[data-selector="free-sign-up-email"]';
  const password = '[data-selector="free-sign-up-password"]';
  const passwordRepeat = '[data-selector="free-sign-up-password-repeat"]';
  const termsCheckBox = '[data-selector="free-sign-up-terms-checkbox"]';
  const mailingCheckBox = '[data-selector="free-sign-up-mailing-checkbox"]';

  const signUpBtn = '[data-selector="free-sign-up-reg-btn"]';
  const $signUpBtn = $(signUpBtn);
  const loginBtn = '[data-selector="free-sign-up-login-btn"]';
  const $loginBtn = $(loginBtn);
  const checkedUserTypeCheckbox = '[data-selector="free-signup-account-radio"]:checked';

  const loginCheckbox = '[data-selector="free-sign-up-login-checkbox"]';

  const setWorksheetToDownloadData = () => {
    const id = $(MODALS.FREE_SIGN_UP_FOR_DOWNLOAD_W).attr('data-worksheet-id');
    const dataSelector = $(MODALS.FREE_SIGN_UP_FOR_DOWNLOAD_W).attr('data-worksheet-selector');
    storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_TO_DOWNLOAD_DATA, `${id}_${dataSelector}`);

    setTimeout(() => {
      window.location.reload();
    }, 400);
  };

  $(document)
    .on('change', loginCheckbox, (e) => {
      const isLogin = $(e.currentTarget).is(':checked');
      $authForm.parsley().reset();

      if (isLogin) {
        $termsCheckboxBlock.hide();
        $mailingCheckboxBlock.hide();
        $confirmPswBlock.hide();
        $signUpBtn.hide();
        $loginBtn.show();

        $(termsCheckBox).removeAttr('required');
        $(passwordRepeat).removeAttr('required');
      } else {
        $termsCheckboxBlock.show();
        $mailingCheckboxBlock.show();
        $signUpBtn.show();
        $confirmPswBlock.show();
        $loginBtn.hide();

        $(termsCheckBox).prop('required',true);
        $(passwordRepeat).prop('required',true);
      }
    })
    .on('click', signUpBtn, (e) => {
      e.preventDefault();

      $authForm.parsley().validate();
      if (!$authForm.parsley().isValid()) return;

      toggleButtonLoading($signUpBtn, true);

      const userType = $(checkedUserTypeCheckbox).val();
      const payload = {
        account_type: ACCOUNT_TYPES.PARENT,
        email: $(email).val(),
        password: $(password).val(),
        subscribe_to_emails: $(mailingCheckBox).prop('checked'),
        source: getUserSources('source'),
        medium: getUserSources('medium'),
        campaign: getUserSources('campaign'),
        placement: getUserSources('placement')
      };

      if (userType) payload.website_account_type = userType;

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify(payload)
      }).then(() => {
        setWorksheetToDownloadData();
      }, (error) => {
        toggleButtonLoading($signUpBtn, false);

        const errorText = getError(error);
        toastr.warning(errorText);
      });
    })
    .on('click', loginBtn, (e) => {
      e.preventDefault();

      $authForm.parsley({
        excluded: passwordRepeat, termsCheckBox
      });

      $authForm.parsley().validate();
      if (!$authForm.parsley().isValid()) return;

      toggleButtonLoading($loginBtn, true);

      $.ajax({
        url: ENDPOINT.USER_LOGIN,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          username: $(email).val(),
          password: $(password).val()
        })
      }).then((response) => {
        if (response && response.error_code) {
          toastr.warning('Something went wrong! Please contact us info@kidsacademy.mobi!');
          return;
        }

        setWorksheetToDownloadData();
      }, (error) => {
        toggleButtonLoading($loginBtn, false);

        const errorText = getError(error);
        toastr.warning(errorText);
      });
    });
});
