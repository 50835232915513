import {
  getError,
  toggleButtonLoading,
  getUrlParam,
  fixPlusSymbolInProvidedEmail,
  isBase64,
  isFormValid,
  capitalize,
  braintreeDropinConfig,
  threeDSecureConfig,
  isThreeDSecureCanceled,
  storage,
  toggleStepProgressBar,
  scrollToHashedElement,
  showSignUpModal,
} from '../utils';
import {
  CURRENCY,
  CUSTOM_MESSAGE,
  DROPIN_SCRIPT_URL,
  ENDPOINT,
  GET_PARAMS,
  SUBSCRIPTION_PERIOD_MAPPING,
  SUBSCRIPTION_PERIODS,
  LOCAL_STORAGE_KEYS,
  URLS,
  MODALS
} from '../config';
import {
  toggleApplyCouponBtn,
  toggleCouponBlock,
  doCheckIsCouponDistributorCode,
  getSelectedOfferDetails,
  handleDropinToken,
  setCheckoutBtnTitle,
  setDefaultOfferSubmitText,
  getShippingPriceInCents,
  getCurrencyPrice
} from './subscription.utils';


$(document).ready(() => {
  const $subscriptionPromoSection = $('[data-selector="subscription-promo-section"]');
  const $subscriptionPromoPayment = $('[data-selector="subscription-promo-payment"]');
  const $promoSubscriptionPage = $('[data-selector="promo-subscription-page"]');
  const $subscriptionPromoModal = $('[data-selector="subscription-promo-modal"]');
  const subscriptionPlansHash = '#subscription-promo-payment';

  if (!$subscriptionPromoSection.length
    && !$promoSubscriptionPage.length
    && !$subscriptionPromoPayment.length
    && !$subscriptionPromoModal.length) {
    return;
  }

  const slugAttr = 'data-slug';
  const offerItem = '[data-selector="subscription-promo-plan-item"]';
  const worksheetLP = 'worksheet-landing';
  const braintreeDropin = '#promo-plans-dropin-container';
  const shippingCountriesSelect = '[data-selector="shipping-countries-select"]';
  const plansSlugs = {
    worksheetLP: {
      yearly: 'web-mobi-ka-braintree-subscription-yearly-65-worksheets-3ft',
      quarterly: 'web-mobi-ka-braintree-subscription-quarterly-19-worksheets-3ft'
    }
  };

  const couponBlockParams = {
    activeClass: '_active',
    emptyCouponClass: '_empty',
    showCouponText: 'Enter coupon code',
    hideCouponText: 'Hide coupon code'
  };

  const hiddenInputSelect = '[data-selector="buy-promo-subscription-special-input"]';

  const componentContext = $subscriptionPromoPayment.attr('data-type');
  const $trialFamilyPic = $('[data-selector="promo-family-picture"]');
  const $securePaymentLabel = $('[data-selector="payment-secure-label"]');

  const $purchaseSection = $('#purchase-step-promo');
  const section = {
    first: 0,
    second: 1
  };
  const checkoutTextAttr = {
    trial: 'data-trial-text',
    noTrial: 'data-no-trial-text'
  };

  // mailing subscribe form
  const subscribeForm = '[data-selector="promo-subscribe"]';
  const $subscribeForm = $(subscribeForm);
  const $subscribeEmailInput = $('[data-selector="promo-subscribe-email"]');
  const $subscribeEmailSubmitBtn = $('[data-selector="promo-subscribe-submit-btn"]');
  const $subscribeConfirm = $('[data-selector="promo-subscribe-confirm"]');

  // sliders
  const $imagesSlider = $('[data-selector="subscription-promo-slider"]');

  // promo subscription modal
  const savedUserEmailAttr = 'data-user-email';

  // first step
  const promoSectionTitle = {
    default: 'Try Kids Academy now!',
    trial: 'Try Kids Academy with FREE TRIAL'
  };
  const $promoSectionTitle = $('[data-selector="promo-section-title"]');
  let isEbloxAdded = false;

  // second step
  const $modalTransitionLoader = $('[data-selector="modal-transition-loader"]');
  const $subscriptionError = $('[data-selector="promo-subscription-error"]');
  const $submitPaymentBtn = $('[data-selector="promo-submit-payment-btn"]');
  const $stepProgressBar = $('[data-selector="step-progress-bar"]');

  // offer plans
  const planInput = '[data-selector="subscription-promo-plan-input"]';
  const selectedPlanInput = `${planInput}:checked`;
  const prevSectionButton = '[data-selector="back-to-prev-promo-section-btn"]';
  const $prevSectionButton = $(prevSectionButton);
  const goToPaymentStepBtn = '[data-selector="go-to-checkout-promo-btn"]';
  const $goToPaymentStepBtn = $(goToPaymentStepBtn);

  // plan attributes
  const planAttr = {
    slug: 'data-slug',
    trialDuration: 'data-trial-duration',
    offerPeriod: 'data-offer-period',
    price: 'data-price',
    offerPeriodDisplay: 'data-offer-period-display',
    backUrl: 'data-offer-back-url',
    submitText: 'data-offer-submit',
    isCouponEnabled: 'data-is-coupon-enabled',
    showOnboardingSteps: 'data-show-onboarding-steps',
    upgradeDiscount: 'data-upgrade-discount',
    offerNote: 'data-offer-note'
  };

  // promo email form
  const promoEmailForm = '[data-selector="promo-email-form"]';
  const promoEmailInput = '[data-selector="promo-email-input"]';

  // subscription details elements
  const $planPeriod = $('[data-selector="promo-plan-period-value"]');
  const $planFullPrice = $('[data-selector="promo-plan-full-price-value"]');
  const $planTrialPeriod = $('[data-selector="promo-plan-trial-period-value"]');
  const $planDiscountRow = $('[data-selector="promo-plan-discount-row"]');
  const $planTrialRow = $('[data-selector="promo-plan-trial-row"]');
  const $planDiscount = $('[data-selector="promo-plan-discount-value"]');
  const $planTotalPrice = $('[data-selector="promo-plan-total-price-value"]');
  const $planRenewCondition = $('[data-selector="promo-plan-renew-condition"]');
  const $planOfferNote = $('[data-selector="subscription-promo-payment-plan-offer-note"]');

  //e-blox addon
  // eslint-disable-next-line max-len
  const $ebloxAddonCheckbox = $('[data-selector="subscription-promo-payment-eblox-addon-checkbox"]');

  const $ebloxRow = $('[data-selector="promo-eblox-row"]');
  const $ebloxPrice = $('[data-selector="eblox-info-value"]');
  const $shippingRow = $('[data-selector="promo-shipping-row"]');
  const $shippingPrice = $('[data-selector="shipping-info-value"]');

  const $shippingForm = $('[data-selector="shipping-addon-form"]');
  const $shippingFormItem = $('[data-selector="shipping-addon-form-item"]');
  const $ebloxInfoSection = $('[data-selector="eblox-info-section"]');

  // coupon
  const $couponInputSection = $('[data-selector="coupon-input-promo-section"]');
  const $couponBlock = $('[data-selector="coupon-block-promo"]');
  const toggleCouponBlockBtn = '[data-selector="toggle-coupon-block-promo-btn"]';
  const $toggleCouponBlockBtn = $(toggleCouponBlockBtn);
  const couponForm = '[data-selector="coupon-form-promo"]';
  const $couponForm = $(couponForm);
  const $couponError = $('[data-selector="coupon-error-promo"]');
  const couponInput = '[data-selector="enter-coupon-input-promo"]';
  const $couponInput = $couponForm.find(couponInput);
  const applyCoupon = '[data-selector="apply-coupon-promo-btn"]';
  const $applyCoupon = $couponForm.find(applyCoupon);
  const removeCoupon = '[data-selector="remove-coupon-promo-btn"]';
  const $removeCoupon = $couponForm.find(removeCoupon);
  const $couponMessage = $couponForm.find('[data-selector="coupon-message-promo"]');
  const $licenseActivationCodeModal = $('#licenseActivationCode');
  let isSpecialCouponApplied = false;
  let specialCouponTrialDuration = null;
  let activeCoupon = null;
  let amountOff = 0;
  let removedSubscriptions = [];
  let dropinInstance;

  const showPurchaseStep = () => {
    callGoToPaymentAnalytics();

    $modalTransitionLoader.show();
    $(MODALS.SUBSCRIPTION_PROMO_MODAL).modal('show');

    $.getScript(
      DROPIN_SCRIPT_URL,
      handleDropinToken(dropinTokenCallback, goToPaymentErrorHandler)
    );
  };

  const doCheckForPreselectedPlan = () => {
    if ($(selectedPlanInput).length) return;

    $(planInput).first().prop('checked', true);
  };

  const setStyleSelectedPlan = () => {
    $(planInput).each((index, plan) => {
      const $planInputWrapper = $(plan).closest(offerItem);
      if ($planInputWrapper.hasClass('_checked')) {
        $planInputWrapper.removeClass('_checked');
      }
    });

    $(selectedPlanInput).closest(offerItem).addClass('_checked');
  };

  const initFirstSectionUI = () => {
    applyProvidedParams();
    setTrialDuration($promoSectionTitle);
    setDefaultOfferSubmitText($goToPaymentStepBtn, $(selectedPlanInput).attr(planAttr.submitText));
    toggleIsCouponInputSection();
    toggleApplyCouponBtn($couponInput, $applyCoupon, couponBlockParams);
    setStyleSelectedPlan();
    $planOfferNote.html($(selectedPlanInput).attr(planAttr.offerNote));
  };

  const toggleIsCouponInputSection = () => {
    const isCouponInputAvailable = $(selectedPlanInput).attr(planAttr.isCouponEnabled) === 'True';
    isCouponInputAvailable ? $couponInputSection.show() : $couponInputSection.hide();
  };

  const initSlider = () => {
    if ($imagesSlider && $imagesSlider.length) {
      $imagesSlider.not('.slick-initialized').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        responsive: [{
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        },{
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },{
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }]
      });
    }
  };

  const onPageLoad = () => {
    doCheckForPreselectedPlan();
    initFirstSectionUI();
    initSlider();

    // show plans modal purchase step
    // eslint-disable-next-line max-len
    const purchaseShow = storage.get(LOCAL_STORAGE_KEYS.SHOW_PROMO_PLANS_MODAL_PURCHASE, true) === 'true';
    // eslint-disable-next-line max-len
    const isEblox = storage.get(LOCAL_STORAGE_KEYS.WEBSITE_PLANS_MODAL_EBLOX_ADDED, true) === 'true';
    if (isEblox) $ebloxAddonCheckbox.prop('checked', true);


    setTimeout(() => {
      // timeout to apply eblox checkbox changes
      if (purchaseShow && window.isSignUpComplete) showPurchaseStep();
    }, 0);
  };

   const getEbloxPriceInCents = () => {
    const result = parseInt(ebloxAddonPrice);
    return result;
  };

  const addEbloxDataUItoPaymentStep = () => {
    // set eblox price in UI
    const ebloxPrice = (getEbloxPriceInCents() / 100).toFixed(2).toString();
    $ebloxPrice.html(`${CURRENCY}${ebloxPrice}`);

    updateUIbyShippingPrice();

    // toggle eblox & shipping prices
    $shippingForm.show();
    $ebloxRow.show();
    $shippingRow.show();
  };

  const removeEbloxDataUIFromPaymentStep = () => {
    $shippingForm.hide();
    $shippingRow.hide();
    $shippingPrice.html('');
    $ebloxRow.hide();
    $ebloxPrice.html('');
    updateUIbyShippingPrice();
  };

  const updateUIbyShippingPrice = () => {
    const shippingPriceInCents = getShippingPriceInCents(shippingCountriesSelect);
    const formattedShippingPrice = (shippingPriceInCents / 100).toFixed(2).toString();
    $shippingPrice.html(`${CURRENCY}${formattedShippingPrice}`);
  };

  const applyProvidedParams = () => {
    applyProvidedEmail();
    applyProvidedCoupon();
  };

  const applyProvidedEmail = () => {
    const providedEmail = getUrlParam(GET_PARAMS.EMAIL);
    if (!providedEmail) return;

    if (!window.isLoggedIn) {
      if (isBase64(providedEmail)) {
        const decryptedEmail = atob(providedEmail);
        $subscriptionPromoModal.attr(savedUserEmailAttr, decryptedEmail);
      } else {
        const fixedProvidedEmail = fixPlusSymbolInProvidedEmail(providedEmail);
        $subscriptionPromoModal.attr(savedUserEmailAttr, fixedProvidedEmail);
      }

      if ($trialFamilyPic) {
        $trialFamilyPic.show();
      }

      $goToPaymentStepBtn.click();
    }
  };

  const applyProvidedCoupon = () => {
    const providedCoupon = getUrlParam(GET_PARAMS.COUPON);
    if (!providedCoupon) return;
    $couponInput.val(providedCoupon);
    $(toggleCouponBlockBtn).click();
  };

  const setTrialDuration = ($element) => {
    const selectedOfferTrialDuration = activeCoupon
      ? specialCouponTrialDuration || 0
      : $(selectedPlanInput).attr(planAttr.trialDuration);
    const isTrialDisabled = parseInt(selectedOfferTrialDuration) === 0;

    isTrialDisabled
      ? $element.html(promoSectionTitle.default)
      : $element.html(promoSectionTitle.trial);
  };

  const callApplyCouponAnalytics = () => {
    switch (componentContext) {
      case worksheetLP:
        // worksheet landing page events
        ga('send', 'event', 'Button', 'CouponApplied', 'Worksheet_Page');
        break;

      default:
        ga('send', 'event', 'Button', 'CouponApplied', 'Parent');
        break;
    }
  };

  const checkCouponApplicability = () => {
    const planSlug = $(selectedPlanInput).attr(planAttr.slug);
    const itemIndex = removedSubscriptions.indexOf(planSlug);
    const isAppliesToOffer = !removedSubscriptions[itemIndex];

    if (!isAppliesToOffer) {
      $goToPaymentStepBtn.prop('disabled', true);
      $couponError.show();
    } else {
      $goToPaymentStepBtn.prop('disabled', false);
      $couponError.hide();
    }

    return isAppliesToOffer;
  };

  const validateCouponCode = (coupon) => {
    $.post(ENDPOINT.COUPON_VALIDATE, {
      coupon: coupon
    }).then((response) => {
      toggleButtonLoading($applyCoupon, false);

      callApplyCouponAnalytics();

      activeCoupon = coupon;
      amountOff = response.amount_off;
      removedSubscriptions = response.exclude_subscriptions;

      $applyCoupon.prop('disabled', false);
      $couponInput.parsley().removeError('coupon');
      $couponInput.prop('disabled', true);
      $applyCoupon.hide();
      $removeCoupon.show();
      const isCouponValid = checkCouponApplicability();
      if (isCouponValid) {
        isSpecialCouponApplied = response.has_trial;
        specialCouponTrialDuration = response.trial_duration;
        $couponMessage.html(response.description);
        $couponMessage.show();
        setTrialDuration($promoSectionTitle);
      }
    }, (error) => {
      toggleButtonLoading($applyCoupon, false);

      activeCoupon = null;
      $couponMessage.empty();
      $couponMessage.hide();

      const errorObject = JSON.parse(error.responseText);
      if (errorObject.error) {
        $couponInput.parsley().removeError('coupon');
        $couponInput.parsley().addError('coupon', {
          message: errorObject.error
        });
      }
    });
  };

  const clearComponentVariables = () => {
    activeCoupon = null;
    amountOff = 0;
    isSpecialCouponApplied = false;
    specialCouponTrialDuration = null;
    removedSubscriptions = [];
  };

  const callGoToPaymentAnalytics = () => {
    const offerSlug = $(selectedPlanInput).attr(planAttr.offerPeriod);

    switch (componentContext) {
      case worksheetLP:
        // worksheet landing page events
        switch (offerSlug) {
          case plansSlugs.worksheetLP.yearly:
            ga('send', 'event', 'Subscription', 'Yearly', 'Worksheet_Page');
            break;

          case plansSlugs.worksheetLP.quarterly:
            ga('send', 'event', 'Subscription', 'Quarterly', 'Worksheet_Page');
            break;
        }
        break;

      default:
        ga('send', 'event', 'Registration_flow', 'ButtonSubmit', 'step5');
        break;
    }
  };

  /**
   * @param upgradeDiscountNumCents {number} in cents 1000 (=10$)
   * @param couponDiscount {number} in dollars 10 (=10$)
   * @param planFullPrice {string} in dollars 10.00 (=10$)
   */
  const getDiscount = (upgradeDiscountNumCents, couponDiscount, planFullPrice) => {
    const fullPriceNum = parseFloat(planFullPrice);
    let totalDiscount = 0;

    const couponDiscountCents = couponDiscount * 100;
    const fullPriceNumCents = fullPriceNum * 100;

    totalDiscount += upgradeDiscountNumCents;
    totalDiscount += couponDiscountCents;

    if ((fullPriceNumCents - totalDiscount) < 0) totalDiscount = fullPriceNumCents;

    return ((totalDiscount) / 100).toFixed(2) ;
  };

  const renderPlanDiscountRow = (planFullPrice) => {
    const upgradeDiscountStr = $(selectedPlanInput).attr(planAttr.upgradeDiscount);
    const upgradeDiscountNum = upgradeDiscountStr ? parseFloat(upgradeDiscountStr) : 0;

    if (activeCoupon && amountOff > 0 || upgradeDiscountNum > 0) {
      $planDiscount.text(`${CURRENCY}${getDiscount(upgradeDiscountNum, amountOff, planFullPrice)}`);
      $planDiscountRow.show();
    }
  };

  const renderTodayPlanPriceRow = (planUpdatedPrice) => {
    const upgradeDiscountStrCents = $(selectedPlanInput).attr(planAttr.upgradeDiscount);
    const upgradeDiscountNumCents = parseInt(upgradeDiscountStrCents);
    const planUpdatedPriceCents = (parseFloat(planUpdatedPrice) * 100).toFixed();

    let price = planUpdatedPrice;
    const priceInCents = planUpdatedPriceCents - upgradeDiscountNumCents;

    if (priceInCents > 0 ) price = (parseInt(priceInCents) / 100).toFixed(2);
    if (priceInCents < 0) price = '0.00';

    $planTotalPrice.text(`${CURRENCY}${price}`);
  };

  const renderSelectedOfferDetails = (
    planPeriod,
    planFullPrice,
    planTrialPeriod,
    planFormattedNewPrice,
    planPeriodDisplay
  ) => {

    $planPeriod.text(capitalize(planPeriodDisplay));
    $planFullPrice.text(`${CURRENCY}${planFullPrice}`);

    renderPlanDiscountRow(planFullPrice);

    if (isTrialActive()) {
      $planTrialPeriod.text(`${planTrialPeriod} days`);
      $planTrialRow.show();
      $planTotalPrice.text(`${CURRENCY}0.00`);

      if (planPeriod !== SUBSCRIPTION_PERIODS.LIFETIME) {
        const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriod](planFullPrice);
        $planRenewCondition.html(text);
      }

      $planRenewCondition.show();
    } else {
      renderTodayPlanPriceRow(planFormattedNewPrice);
    }

    isEbloxAdded = $ebloxAddonCheckbox.is(':checked');
    if (isEbloxAdded) {
      addEbloxDataUItoPaymentStep();
    } else {
      removeEbloxDataUIFromPaymentStep();
    }
  };

  const getPurchaseRequestData = (paymentMethodNonce, slug) => {
    const result = {
      payment_method_nonce: paymentMethodNonce,
      plan_slug: slug,
      source: 'special-offer',
      discount_id: activeCoupon,
      trial_enabled: isTrialActive(),
    };

    if (isEbloxAdded) {
      // collect eblox addon & shipping data
      const formFields = {};
      const shippingFormDataArray = $shippingFormItem.serializeArray();

      shippingFormDataArray.forEach((item) => {
        formFields[item.name] = item.value;
      });

      result.shipping_address = formFields;
      result.addons = [{addon_id: ebloxAddonId}, {addon_id: shippingAddonId}];
    }

    if (window.user.email) result.email =  window.user.email;

    return result;
  };

  const dropinTokenCallback = () => {
    if (!window.braintree) {
      goToPaymentErrorHandler();
      $(MODALS.SUBSCRIPTION_PROMO_MODAL).modal('hide');
      return;
    }

    toggleButtonLoading($goToPaymentStepBtn, false);
    const isModalOpen = ($subscriptionPromoModal.data('bs.modal') || {}).isShown;
    if (!isModalOpen) return;

    preparePurchaseStep();
  };

  const goToPaymentErrorHandler = (err) => {
    toastr.error(getError(err), '', {timeOut: 3000});
    toggleButtonLoading($goToPaymentStepBtn, false);
  };

  const isTrialActive = () => {
    if (window.hasValidParentSubscription) return false;
    if (isSpecialCouponApplied) return true;

    const trialDuration = $(selectedPlanInput).attr(planAttr.trialDuration);
    const isTrialDisabled = parseInt(trialDuration) === 0;
    if (isTrialDisabled) return false;

    return !activeCoupon;
  };

  const setTotalPriceToUI = (totalPriceInCents) => {
    const currencyPrice = getCurrencyPrice(totalPriceInCents);
    $planTotalPrice.html(currencyPrice);
  };

  const getTotalPricesInCents = (
    planFullPriceInCents,
    isTrialActive = false,
    isEbloxAdded = false,
    activeCoupon = false,
    amountOff = 0,
    shippingCountriesSelect
  ) => {
    const mainSubscriptionPlans = parseFloat(planFullPriceInCents);
    let subscriptionPriceInCents = mainSubscriptionPlans;
    let ebloxAndShippingPriceInCents = 0;
    const minCapFor3DSecure = 1;
    let priceFor3DSecure = minCapFor3DSecure;
    let totalPriceInCents = 0;

    if (isTrialActive) subscriptionPriceInCents = 0;

    if (isEbloxAdded) {
      ebloxAndShippingPriceInCents =
        getShippingPriceInCents(shippingCountriesSelect) + getEbloxPriceInCents();
    }

    totalPriceInCents = subscriptionPriceInCents + ebloxAndShippingPriceInCents;
    priceFor3DSecure = mainSubscriptionPlans + ebloxAndShippingPriceInCents;

    if (activeCoupon) {
      const amountPriceInCents = parseFloat((amountOff * 100).toFixed());
      const priceAfterDiscount = parseFloat((totalPriceInCents - amountPriceInCents).toFixed());
      // fix for 3d secure, we CAN NOT use 0 as price for 3d secure
      priceFor3DSecure = priceAfterDiscount <= 0 ? minCapFor3DSecure : priceAfterDiscount;
      totalPriceInCents = priceAfterDiscount < 0 ? 0 : priceAfterDiscount;
    }

    const upgradeDiscountStrCents = $(selectedPlanInput).attr(planAttr.upgradeDiscount);
    const upgradeDiscountNumCents = parseInt(upgradeDiscountStrCents);

    if (upgradeDiscountNumCents > 0) {
      const updated3dSecurePrice = priceFor3DSecure - upgradeDiscountNumCents;
      const updatedTotalPriceInCents = totalPriceInCents - upgradeDiscountNumCents;

      if (updated3dSecurePrice > 0) priceFor3DSecure = updated3dSecurePrice;
      if (updatedTotalPriceInCents > 0 ) totalPriceInCents = updatedTotalPriceInCents;

      if (updated3dSecurePrice < 0) priceFor3DSecure = minCapFor3DSecure;
      if (updatedTotalPriceInCents < 0 ) totalPriceInCents = 0;
    }

    return {totalPriceInCents, priceFor3DSecure};
  };

  const getTotalPrices = (isTrialEnabled) => {
    const planFullPriceInCents = $(selectedPlanInput).attr(planAttr.price);

    const totalPrices = getTotalPricesInCents(
      planFullPriceInCents,
      isTrialEnabled,
      isEbloxAdded,
      activeCoupon,
      amountOff,
      shippingCountriesSelect
    );

    return totalPrices;
  };

  const handleProgressBar = () => {
    const isStepsShownStr = $(selectedPlanInput).attr(planAttr.showOnboardingSteps);
    storage.set(LOCAL_STORAGE_KEYS.IS_SHOW_STEP_PROGRESS_BAR, isStepsShownStr);

    toggleStepProgressBar($stepProgressBar);
  };

  const prepareFirstSectionUI = () => {
    $purchaseSection.hide();
    $subscriptionError.hide();
    $submitPaymentBtn.hide();
    $modalTransitionLoader.hide();
    $(braintreeDropin).html('');
    $planRenewCondition.text('');
    $ebloxInfoSection.show();
    $planOfferNote.html($(selectedPlanInput).attr(planAttr.offerNote));
  };

  const prepareSecondSectionUI = () => {
    toggleButtonLoading($prevSectionButton, true);
    $modalTransitionLoader.hide();
    $planDiscountRow.hide();
    $planTrialRow.hide();
    $planRenewCondition.hide();
    $purchaseSection.show();
    handleProgressBar();
    $ebloxInfoSection.hide();
  };

  const selectSection = (sectionId) => {
    if (sectionId === section.first) {
      prepareFirstSectionUI();
      // Remove an event listener set by braintree-creating function so as
      // to prevent creating multiple event handlers in case of function call repeat
      $submitPaymentBtn.off('click');
      return;
    }

    if (sectionId === section.second) {
      prepareSecondSectionUI();
      setCheckoutBtnTitle($submitPaymentBtn, isTrialActive(), checkoutTextAttr);
    }
  };

  const preparePurchaseStep = () => {
    $submitPaymentBtn.hide();
    selectSection(section.second);

    scrollToHashedElement(subscriptionPlansHash, {scrollTimeout: 0});

    const offer = $(selectedPlanInput);
    const $promoEmail = offer.closest(offerItem).find(promoEmailInput);
    const userEmail = $promoEmail.length ? $promoEmail.val().trim() : null;
    const {
      planPeriod,
      planFullPrice,
      planFormattedNewPrice,
      planSlug,
      planBackUrl,
      planPeriodDisplay
    } = getSelectedOfferDetails(offer, amountOff, planAttr, userEmail);

    const planTrialPeriod = (isSpecialCouponApplied && specialCouponTrialDuration)
        ? specialCouponTrialDuration
        : offer.attr(planAttr.trialDuration);

    const isTrialEnabled = isTrialActive();

    // eslint-disable-next-line max-len
    renderSelectedOfferDetails(planPeriod, planFullPrice, planTrialPeriod, planFormattedNewPrice, planPeriodDisplay, amountOff);

    const {
      totalPriceInCents,
      priceFor3DSecure
    } = getTotalPrices(isTrialEnabled);

    setTotalPriceToUI(totalPriceInCents);

    initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planSlug, planBackUrl, isTrialEnabled);
  };

  const triggerGAEventOnCheckout = () => {
    if (isTrialActive()) {
      ga('send', 'event', 'Trial', 'Started', 'Parent');
      if (isEbloxAdded) ga('send', 'event', 'Eblox', 'Started', 'Parent');
      return;
    }

    ga('send', 'event', 'Subscription', 'Started', 'Parent');
  };

  const triggerAnalyticsSubmit = () => {
    ga('send', 'event', 'Registration_flow', 'SubmitPayment', 'step7');
  };

  const triggerAnalyticsOnCheckout = (response, slug, backUrl) => {
    if (response && response.amount_paid && response.is_trial) {
      window.location = backUrl;
    } else {
      window.location = backUrl ? backUrl : `/${URLS.PARENT_SUBSCRIPTION_SUCCESS}/`;
    }
  };

  const initBraintreeDropin = (totalPriceInCents, priceFor3DSecure, slug, backUrl) => {
    if (braintree && $(braintreeDropin).length) {
      const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
      const braintreeConf = braintreeDropinConfig(totalPriceInDollars, braintreeDropin);

      braintree.dropin.create(braintreeConf,
        function (createErr, instance) {
          if (createErr) {
            // An error in the create call is likely due to
            // incorrect configuration values or network issues.
            // An appropriate error will be shown in the UI.

            $subscriptionError.html(
              `${createErr.message}
               <br>${CUSTOM_MESSAGE.BRAINTREE_CREATE_ERROR}`
            );
            $subscriptionError.show();
            toggleButtonLoading($prevSectionButton, false);
            // eslint-disable-next-line no-console
            console.error('dropin create error: ', createErr);
            return;
          }

          dropinInstance = instance;

          $securePaymentLabel.show();

          if (instance.isPaymentMethodRequestable()) {
            // This will be true if you generated the client token
            // with a customer ID and there is a saved payment method
            // available to tokenize with that customer.
            $submitPaymentBtn.show();
          }

          instance.on('paymentMethodRequestable', () => {
            $submitPaymentBtn.show();
            $subscriptionError.hide();
          });

          instance.on('noPaymentMethodRequestable', () => {
            $submitPaymentBtn.hide();
            $subscriptionError.hide();
          });

          instance.on('paymentOptionSelected', () => {
            $subscriptionError.hide();
            if (instance.isPaymentMethodRequestable()) {
              $submitPaymentBtn.show();
              return;
            }
            $submitPaymentBtn.hide();
          });

          toggleButtonLoading($prevSectionButton, false);
          toggleButtonLoading($submitPaymentBtn, false);

          $submitPaymentBtn.click(() => {
            // To prevent bot spamming we use solution from
            // https://www.thryv.com/blog/honeypot-technique/
            const $hiddenInput = $(hiddenInputSelect);
            const hiddenInputVal = $hiddenInput.val();
            if (hiddenInputVal) return;

            if (isEbloxAdded) {
              const shippingFormItemParsley = $shippingFormItem.parsley();
              if (!shippingFormItemParsley.isValid()) {
                shippingFormItemParsley.validate();
                return;
              }
            }

            $subscriptionError.hide();
            $prevSectionButton.hide();
            toggleButtonLoading($submitPaymentBtn, true);

            const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
            const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

            instance.requestPaymentMethod({
              threeDSecure: threeDSecureConf
            }, function (requestPaymentMethodErr, payload) {
              if (requestPaymentMethodErr) {
                // eslint-disable-next-line no-console
                console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);

                $subscriptionError.text(requestPaymentMethodErr.message);
                $subscriptionError.show();
                $prevSectionButton.show();
                toggleButtonLoading($submitPaymentBtn, false);
                return;
              }

              if (isThreeDSecureCanceled(payload)) {
                instance.clearSelectedPaymentMethod();
                $subscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                $subscriptionError.show();
                toggleButtonLoading($submitPaymentBtn, false);
                $prevSectionButton.show();
                return;
              }

              triggerAnalyticsSubmit(slug, totalPriceInDollars);

              const endpoint = window.hasValidParentSubscription ?
                ENDPOINT.UPGRADE_SUBSCRIPTION : ENDPOINT.BUY_SUBSCRIPTION_APP;

              const requestData = getPurchaseRequestData(payload.nonce, slug);
              $.ajax({
                url: endpoint,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(requestData)
              })
                .then((response) => {
                  triggerGAEventOnCheckout();
                  triggerAnalyticsOnCheckout(response, slug, backUrl);
                }, (errorResponse) => {
                  instance.clearSelectedPaymentMethod();
                  $subscriptionError.text('');
                  $subscriptionError.append(getError(errorResponse));
                  $subscriptionError.show();

                  toggleButtonLoading($submitPaymentBtn, false);
                });
            });
          });
        });
    }
  };

  const handleGoToPurchaseStep = (e) => {
    e.preventDefault();

    const $button = $(e.target);
    const planSlug = $button.attr(slugAttr);
    const isEbloxAdded = $ebloxAddonCheckbox.is(':checked');

    if (!window.isLoggedIn) {
      showSignUpModal({isPromo: true, planSlug, isEbloxAdded});
    } else {
      showPurchaseStep();
    }
  };

  // event listeners
  $(document)
    .on('change', shippingCountriesSelect, () => {
      const {totalPriceInCents, priceFor3DSecure} = getTotalPrices();
      const offer = $(selectedPlanInput);
      const {
        planSlug,
        planBackUrl,
      } = getSelectedOfferDetails(offer, amountOff, planAttr);

      $submitPaymentBtn.off('click');

      dropinInstance.teardown((teardownErr) => {
        if (teardownErr) {
          // eslint-disable-next-line no-console
          console.error('dropin teardown error: ', teardownErr);
        } else {
          initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planSlug, planBackUrl);
        }
      });

      updateUIbyShippingPrice();
      setTotalPriceToUI(totalPriceInCents);
      $subscriptionPromoModal.animate({scrollTop: 0}, 500);
    })
    .on('submit', subscribeForm, (e) => {
      e.preventDefault();
      const subscribeEmail = $subscribeEmailInput.val().trim();
      if (!subscribeEmail) return;

      if (!isFormValid($subscribeForm)) return;

      toggleButtonLoading($subscribeEmailSubmitBtn, true);

      $.ajax({
        url: ENDPOINT.USER_SUBSCRIBE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          list_name: 'kidsacademy_newsletter',
          email_address: subscribeEmail,
          REF_LANDIN: window.location.href
        })
      }).then(() => {
        toggleButtonLoading($subscribeEmailSubmitBtn, false);
        $subscribeForm.hide();
        $subscribeConfirm.show();
      }, (error) => {
        toggleButtonLoading($subscribeEmailSubmitBtn, false);
        toastr.error(getError(error), '', {timeOut: 3000});
      });
    })
    // modal actions
    .on('change', planInput, () => {
      setDefaultOfferSubmitText(
        $goToPaymentStepBtn,
        $(selectedPlanInput).attr(planAttr.submitText)
      );
      toggleIsCouponInputSection();
      setTrialDuration($promoSectionTitle);
      checkCouponApplicability();
      $planOfferNote.html($(selectedPlanInput).attr(planAttr.offerNote));
    })
    .on('click', toggleCouponBlockBtn, (e) => {
      e.preventDefault();
      toggleCouponBlock($couponBlock, $toggleCouponBlockBtn, couponBlockParams);
      $toggleCouponBlockBtn.hide();
    })
    .on('input', couponInput, () => {
      $couponInput.parsley().reset();
      toggleApplyCouponBtn($couponInput, $applyCoupon, couponBlockParams);
    })
    .on('click', applyCoupon, (e) => {
      e.preventDefault();
      // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
      const hiddenInputVal = $('[data-selector="promo-coupon-check-special-input"]').val();
      if (hiddenInputVal) return;

      if (!isFormValid($couponForm)) return;

      const coupon = $couponInput.val().trim();
      $couponError.hide();

      if (coupon) {
        toggleButtonLoading($applyCoupon, true);
        doCheckIsCouponDistributorCode(coupon)
          .then(() => {
            toggleButtonLoading($applyCoupon, false);
            $licenseActivationCodeModal.modal('show');
          }, () => {
            validateCouponCode(coupon);
          });
      }
    })
    .on('click', removeCoupon, (e) => {
      e.preventDefault();
      clearComponentVariables();
      $couponInput.parsley().reset();
      $couponInput.prop('disabled', false);
      $couponInput.val('');
      toggleApplyCouponBtn($couponInput, $applyCoupon, couponBlockParams);
      $applyCoupon.show();
      $removeCoupon.hide();
      $couponMessage.empty();
      $couponMessage.hide();
      checkCouponApplicability();
      setTrialDuration($promoSectionTitle);
    })
    .on('focus', promoEmailInput, (e) => {
      const $triggeredEmailInput = $(e.target);
      const $relatedOffer = $triggeredEmailInput.closest(offerItem).find(planInput);
      $relatedOffer.prop('checked', true);
    })
    // go to purchase step (checkout)
    .on('click', goToPaymentStepBtn, handleGoToPurchaseStep.bind(this))
    .on('submit', promoEmailForm, handleGoToPurchaseStep.bind(this))
    .on('hidden.bs.modal', $subscriptionPromoModal, () => {
      prepareFirstSectionUI();
      $submitPaymentBtn.off('click');
      $subscriptionPromoModal.removeAttr(savedUserEmailAttr);
    });

  onPageLoad();
});
