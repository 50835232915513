$(function () {
  const $items = $('.accordion-item');

  if (!$items.length) return;

  function closeItems() {
    $items.removeClass('active');
  }

  function openItem($item) {
    closeItems();
    $item.addClass('active');
    setTimeout(function () {
      const yCoordinate = $item.offset().top - 80;
      window.scrollTo(0, yCoordinate);
    }, 0);
  }

  $('.accordion-item h2').click(function (e) {
    const $target = $(e.currentTarget);
    const $item = $target.closest('.accordion-item');
    if (!$item.hasClass('active')) {
      window.location.hash = '#' + $item.attr('id');
    } else {
      window.location.hash = '';
    }
  });

  function goToHash() {
    const hash = window.location.hash.substr(1);
    if (hash) {
      const $currentItem = $('#' + hash);
      if ($currentItem.length) {
        openItem($currentItem);
      }
    } else {
      closeItems();
    }
  }

  goToHash();

  $(window).on('hashchange', function () {
    goToHash();
  });
});
