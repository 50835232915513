import {ENDPOINT, FEEDBACK_TYPES, URLS} from '../config';
import {getUrlParam, toggleButtonLoading} from '../utils';

$(document).ready(function () {
  const $quotePage = $('[data-selector="quote-request-page"]');
  const $quoteRequestForm = $('[data-selector="quote-request-page-content"]');
  const $requestError = $('[data-selector="quote-request-page-error"]');
  const $requestSuccess = $('[data-selector="quote-request-page-success"]');

  const dataForm = '[data-selector="quote-request-form"]';
  const $dataForm = $quotePage.find(dataForm);

  const $schoolCountrySelect = $quotePage.find('[data-selector="quote-request-country"]');
  const $formSubmitBtn = $quotePage.find('[data-selector="quote-request-submit-btn"]');

  const utmCampaignParam = 'utm_campaign';
  const utmMediumParam = 'utm_medium';
  const utmSourceParam = 'utm_source';

  if (!$quotePage.length) return;

  const doOnPageLoad = function () {
    $schoolCountrySelect.select2({width: 'resolve'});
  };

  /**
   * Create formatted string from form data object (string like: School: qqq@ww.ww
   *                                                             Grades: David
   *                                                             Position: Teacher
   *                                                             etc..) without email and full-name
   * @param formData - form data object
   * @returns {string} - message string
   */
  const getMessageText = function (formData) {
    let message = '';
    if (!formData) return '';
    const seoData = getMailSEOData();
    const messageData = Object.assign(formData, seoData);
    for (const key in messageData) {
      if (formData.hasOwnProperty(key)
        && key !== 'email'
        && key !== 'full-name') {

        const title = key.replace('-_', ' ');
        const capitalizedTitle = title.charAt(0).toUpperCase() + title.substring(1);

        if (!formData[key]) {
          message += capitalizedTitle + ': - ' + '\n';
        } else {
          message += capitalizedTitle + ': ' + formData[key] + '\n';
        }
      }
    }

    return message;
  };

  const getMailSEOData = function () {
    const prevPath = document.referrer && new URL(document.referrer).pathname;
    return {
      'page': prevPath ? prevPath : '-',
      [utmSourceParam]: getUrlParam(utmSourceParam),
      [utmCampaignParam]: getUrlParam(utmCampaignParam),
      [utmMediumParam]: getUrlParam(utmMediumParam)
    };
  };

  const showSuccessBlock = function () {
    $quoteRequestForm.hide();
    $requestError.hide();
    $requestSuccess.show();
  };

  const showErrorBlock = function () {
    $quoteRequestForm.hide();
    $requestSuccess.hide();
    $requestError.show();
  };

  const getContactFormData = function () {
    const dataArr = $dataForm.serializeArray();

    const formFields = {};
    dataArr.forEach(function (item) {
      formFields[item.name] = item.value;
    });

    return {
      feedback_type: FEEDBACK_TYPES.QUOTE_REQUEST,
      subject: 'Sign up school/district quote request',
      device_os: navigator.platform,
      previous_page: document.referrer,
      name: formFields['full-name'],
      email: formFields.email,
      time: new Date(),
      screen_resolution: screen.width + 'x' + screen.height,
      message: getMessageText(formFields)
    };
  };

  // event listeners
  $(document)
    // submit data form
    .on('submit', dataForm, function (e) {
      e.preventDefault();

      const parsleyDataForm = $dataForm.parsley();
      parsleyDataForm.validate();

      if (parsleyDataForm.isValid()) {
        toggleButtonLoading($formSubmitBtn, true);

        $.ajax({
          url: ENDPOINT.FEEDBACK,
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(getContactFormData())
        })
          .then(function () {
            showSuccessBlock();
            toggleButtonLoading($formSubmitBtn, false);

            const prevUrl = document.referrer ? new URL(document.referrer) : null;
            if (prevUrl && prevUrl.pathname === `/${URLS.HYBRID_LEARNING_PLATFORM}/`) {
              ga('send', 'event', 'Quote', 'Requested', 'HybridLearning');
              return;
            }
            ga('send', 'event', 'Button', 'RequestQuoteSent', 'District');
          }, function () {
            showErrorBlock();
            toggleButtonLoading($formSubmitBtn, false);
            // TODO add error notification if needed
          });
      }
    });

  doOnPageLoad();
});
