import {
  ENDPOINT,
  FEEDBACK_TYPES
} from '../config';
import {
  getBrowser,
  toggleButtonLoading
} from '../utils';

$(function () {
  const $promoForSchoolsForm = $('[data-selector="promo-for-school-form"]');
  const promoForSchoolsFormSubmitBtn = '[data-selector="promo-for-school-form-submit"]';
  const $contactUsFormSubmitBtn = $(promoForSchoolsFormSubmitBtn);
  const $promoForSchoolsModal = $('#promo-for-school-modal');
  const $promoForSchoolsSuccess = $('#school-promo-modal-success');
  const $contactUsErrorModal = $('#contactUsError');
  const formValidation = $promoForSchoolsForm.parsley();

  const clearFields = function () {
    const $inputs = $promoForSchoolsForm.find('input');
    const $textareas = $promoForSchoolsForm.find('textarea');

    $textareas.val('');
    $inputs.val('');
  };

  const showSuccessModal = function() {
    $promoForSchoolsSuccess.modal('show');
  };

  const showErrorModal = function() {
    $contactUsErrorModal.modal('show');
  };

  const hideContactUsModal = function() {
    $promoForSchoolsModal.modal('hide');
    formValidation.reset();
    clearFields();
  };

  const getContactFormData = function() {
    const formDataArr = $promoForSchoolsForm.serializeArray();
    const data = {};

    formDataArr.forEach(function (item) {
      data[item.name] = item.value;
    });

    data.account_type = 'school';
    data.feedback_type = FEEDBACK_TYPES.SCHOOL_REQUEST;
    data.subject = 'Request for discount';
    data.device_page = window.location.href;
    data.device_browser = getBrowser();
    data.device_os = navigator.platform;

    return data;
  };

  const submitForm = function () {
    const data = getContactFormData();
    toggleButtonLoading($contactUsFormSubmitBtn, true);

    $.ajax({

      url: ENDPOINT.FEEDBACK,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data)
    })
      .then(function () {
        toggleButtonLoading($contactUsFormSubmitBtn, false);
        hideContactUsModal();
        showSuccessModal();
      }, function () {
        toggleButtonLoading($contactUsFormSubmitBtn, false);
        hideContactUsModal();
        showErrorModal();
      });
  };

  $(document)
    // next lines was commented to prevent open "get discount for school modal",
    // in current flow click on teachers banner opens sign up teacher modal

    // on schools banner click
    // .on('click', '#schools-page-banner', function () {
    //   $promoForSchoolsModal.modal('show');
    // })
    .on('click', promoForSchoolsFormSubmitBtn, function () {
      if (!formValidation.isValid()) return;

      submitForm();
    });

  $promoForSchoolsModal.on('hidden.bs.modal', function () {
    formValidation.reset();
    clearFields();
  });
});
