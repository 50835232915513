$(document).ready(() => {
  const $appList = $('[data-selector="app-list"]');
  if (!$appList.length) return;

  const $thAppSlider = $('[data-selector="th-app-slider"]');

  $thAppSlider.not('.slick-initialized').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      swipeToSlide: true,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }]
    });


});
