import toastr from 'toastr/toastr';

import {
  toggleButtonLoading,
  showJoinKidsAcademyModal,
  storage
} from '../utils';

import {
  ENDPOINT,
  LOCAL_STORAGE_KEYS,
  MAILING_LISTS
} from '../config';

/* eslint-disable */
$(document).ready(() => {
  const $tgcLanding = $('[data-selector="tgc-landing"]');
  if (!$tgcLanding.length) return;

  const $tgcLandingSlider = $('[data-selector="tgc-landing-slider"]');
  const tgcLandingSliderItem = '[data-selector="tgc-landing-slider-item"]';
  const tgcPersonalPlanBtn = '[data-selector="personal-plan-btn"]';
  const tgcStartBtn = '[data-selector="tgc-start-btn"]';
  const tgcStartTrialBtn = '[data-selector="start-trial-btn"]';
  const tgcSignInBtn = '[data-selector="sign-in-btn"]';
  const tgcEdutainmentBtn = '[data-selector="tgc-edutainment-btn"]';
  const tgcToyBoxBtn = '[data-selector="tgc-toy-box-btn"]';
  const tgcTvBtn = '[data-selector="tgc-tv-btn"]';
  const tgcGetStarted = '[data-selector="tgc-get-started-btn"]';

  const $tgcSubscriptionPopup = $('[data-selector="tgc-subscription-popup"]');
  const $tgcSubscriptionPopupSubscribe = $('[data-selector="tgc-subscription-popup-subscribe"]');
  const $tgcSubscriptionPopupSuccess = $('[data-selector="tgc-subscription-popup-success"]');
  const tgcSubscriptionPopupSubmit = '[data-selector="tgc-subscription-popup-submit"]';
  const $tgcSubscriptionPopupSubmit = $(tgcSubscriptionPopupSubmit);
  const $tgcSubscriptionPopupForm = $('[data-selector="tgc-subscription-popup-form"]');
  const $tgcSubscriptionPopupInput = $('[data-selector="tgc-subscription-popup-input"]');

  showJoinKidsAcademyModal({ checkStorage: false });

  $tgcLandingSlider.on('init', () => {
    $tgcLandingSlider.show();
  });

  $tgcLandingSlider.not('.slick-initialized').slick({
    slidesToShow: 5,
    infinite: false,
    swipeToSlide: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 5
      }
    }, {
      breakpoint: 680,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 3
      }
    }]
  });

  /* eslint-disable */
  const onElementClick = (e) => {
    // e.preventDefault();
    // $tgcSubscriptionPopup.modal('show');
  };
  /* eslint-enable */

  const submitSubscriptionForm = () => {
    const tgcSubscriptionPopupFormParsley = $tgcSubscriptionPopupForm.parsley();

    if (!tgcSubscriptionPopupFormParsley.isValid()) {
      tgcSubscriptionPopupFormParsley.validate();
      return;
    }

    toggleButtonLoading($tgcSubscriptionPopupSubmit, true);
    const subscribeEmail = $tgcSubscriptionPopupInput.val().trim();

    $.ajax({
      url: ENDPOINT.USER_SUBSCRIBE,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        list_name: MAILING_LISTS.TGC_LIST,
        email_address: subscribeEmail,
        REF_LANDIN: window.location.href
      })
    })
      .then(() => {
        storage.set(LOCAL_STORAGE_KEYS.IS_BLOG_SUBSCRIBED, 'true');
        toggleButtonLoading($tgcSubscriptionPopupSubmit, false);
        $tgcSubscriptionPopupSubscribe.hide();
        $tgcSubscriptionPopupSuccess.show();
      }, () => {
        toggleButtonLoading($tgcSubscriptionPopupSubmit, false);
        $tgcSubscriptionPopup.modal('hide');

        // TODO add support for spanish lang
        toastr.warning('Something went wrong. Please contact us.');
      });
  };

  $(document)
    .on('click', tgcSubscriptionPopupSubmit, (e) => {
      e.preventDefault();
      submitSubscriptionForm();
    })
    .on('click', tgcLandingSliderItem, (e) => {
      onElementClick(e);
    })
    .on('click', tgcPersonalPlanBtn, (e) => {
      onElementClick(e);
    })
    .on('click', tgcStartBtn, (e) => {
      onElementClick(e);
    })
    .on('click', tgcStartTrialBtn, (e) => {
      onElementClick(e);
    })
    .on('click', tgcSignInBtn, (e) => {
      onElementClick(e);
    })
    .on('click', tgcEdutainmentBtn, (e) => {
      onElementClick(e);
    })
    .on('click', tgcToyBoxBtn, (e) => {
      onElementClick(e);
    })
    .on('click', tgcTvBtn, (e) => {
      onElementClick(e);
    })
    .on('click', tgcGetStarted, (e) => {
      onElementClick(e);
    });
});
