import {ENDPOINT} from '../config';
import { toggleButtonLoading } from '../utils';

$(document).ready(function () {
  const fetc20Form = '[data-selector*="fetc-20-form"]';
  const $fetc20Form = $(fetc20Form);

  $(document).on('submit', fetc20Form, function(e) {
    e.preventDefault();

    const $form = $(e.target).closest($fetc20Form);
    const $button = $form.find('button');
    const email = $form.find('[name="email"]').val();
    const fetc20ParsleyForm = $form.parsley();

    fetc20ParsleyForm.validate();
    if (!fetc20ParsleyForm.isValid()) return;

    toggleButtonLoading($button, true);

    $.ajax({
      url: ENDPOINT.WAIT_LIST,
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({
        list_name: 'FETC 20',
        email: email
      })
    }).then(function () {
      window.location = 'https://docs.google.com/forms/d/e/1FAIpQLScAG-a4FY-QomRXZQvtdW451MGVn-mzI-WpZr-YdgpkKfHNlg/viewform?usp=sf_link';
    }, function () {
      toggleButtonLoading($button, false);
    });
  });
});
