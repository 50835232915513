import {
  callYTPlayer,
  scrollToHashedElement,
  copyStringToBuffer,
  isBrowserSupportsWebp
} from '../utils';

import toastr from 'toastr';

$(document).ready(function () {
  const $helpCenterRelated = $('[data-selector="help-center"]');
  const $faqPage = $('[data-selector="faq-page"]');

  if (!$helpCenterRelated.length && !$faqPage.length) return;

  const animationSpeed = 400;
  const scrollIntoViewDelay = 1000;

  const expandClassName = '_expanded';
  const imgModalSelector = '#img-modal';
  const $imgModal = $(imgModalSelector);
  const $modalContentClassName = $imgModal.find('.modal-content');

  const expandBtnText = 'Expand all';
  const collapseBtnText = 'Collapse all';

  const shareButtonClassName = '.help-center__share-btn';
  const expandButtonClassName = '.help-center__block-btn';
  const accordionBlockClassName = '.help-center__block';
  const accordionItemClassName = '.help-center__accordion-item';
  const accordionContentClassName = '.help-center__accordion-content';
  const accordionTitleClassName = '.help-center__accordion-title';
  const helpCenterVideoContainer = '.help-center__video-container';

  const helpCenterImage = '.data-selector-help-center-image-modal';

  const ytVideoIdsArray = [];
  let currentWorkingPlayer;

  const expandSection = ($elem) => {
    const $item = $elem.closest(accordionItemClassName);
    const $content = $item.find(accordionContentClassName);

    $item.addClass(expandClassName);
    $content.slideDown(animationSpeed);
  };

  const appendImg = (src) => {
    const html = $('<img src="' + src + '" alt="image">');
    html.appendTo($modalContentClassName);
    $('#img-modal').modal('show');
  };

  // remove img from modal on modal close
  $imgModal.on('hidden.bs.modal', (e) => {
    $(e.currentTarget).find('.modal-content').html('');
  });

  const stopBlockRelatedVideo = ($contentBlock) => {
    const $videoContainers = $contentBlock.find(helpCenterVideoContainer);
    $videoContainers.each((_idx, element) => {
      callYTPlayer($(element).find('iframe'), 'stopVideo');
    });
  };

  const setUniqIdToAllYoutubeVideos = () => {
    $(helpCenterVideoContainer).each((idx, element) => {
      const uniqId = `yt-video-${idx}`;
      $(element).find('iframe').attr('id', uniqId);
      ytVideoIdsArray.push(uniqId);
    });
  };

  const handleImgCLick = (e) => {
    isBrowserSupportsWebp((isSupportWebp) => {
      // supports webp, use webp
      if (isSupportWebp) {
        let webpSrc = $(e.target).closest('picture').find('source').attr('srcset');
        if (!webpSrc) webpSrc = $(e.target).closest('picture').find('source').attr('src');

        // has webp src, use webp
        if (webpSrc) {
          if (webpSrc) appendImg(webpSrc);
          return;
        }

        // no webp src
        const imgSource = $(e.target).attr('src');
        appendImg(imgSource);
        return;
      }

      // not support webp, use png
      const src = $(e.target).attr('src');
      appendImg(src);
    });
  };

  $(document)
    .on('click', accordionTitleClassName, (e) => {
      // expand item
      const $accordionItem = $(e.target).closest(accordionItemClassName);
      const $accordionContent = $accordionItem.find(accordionContentClassName);

      if ($accordionItem.hasClass(expandClassName)) {
        $accordionItem.stop().stop().removeClass(expandClassName);
        $accordionContent.stop().slideToggle(animationSpeed);
        stopBlockRelatedVideo($accordionContent);
      } else {
        $accordionItem.stop().stop().addClass(expandClassName);
        $accordionContent.stop().slideToggle(animationSpeed);
      }
    })
    .on('click', expandButtonClassName, (e) => {
      // expand all items in block
      const $button = $(e.target);
      const $accordionBlock = $button.closest(accordionBlockClassName);
      const $accordionItem = $accordionBlock.find(accordionItemClassName);

      if ($button.hasClass(expandClassName)) {
        const $accordionItemExpanded = $accordionItem.filter('.' + expandClassName);
        const $accordionContentsExpanded = $accordionItemExpanded.find(accordionContentClassName);

        $accordionContentsExpanded.stop().slideToggle(animationSpeed);
        $accordionItemExpanded.removeClass(expandClassName);
        stopBlockRelatedVideo($helpCenterRelated);
        $button.removeClass(expandClassName);
        $button.text(expandBtnText);
      } else {
        const $accordionItemNotExpanded = $accordionItem.not('.' + expandClassName);
        const $accordionContentsNotExpanded = $accordionItemNotExpanded
          .find(accordionContentClassName);

        $accordionContentsNotExpanded.stop().slideToggle(animationSpeed);
        $accordionItemNotExpanded.stop().stop().addClass(expandClassName);
        $button.addClass(expandClassName);
        $button.text(collapseBtnText);
      }
    })
    .on('click', shareButtonClassName, (event) => {
      event.stopPropagation();

      const hash = $(event.target).closest('.help-center__accordion-title').attr('data-hash');
      if (!hash) return;

      const str = window.location.origin + window.location.pathname + '#' + hash;
      copyStringToBuffer(str);
      toastr.success('Link copied', '', {timeOut: 3000});
    })
    // append img to modal
    .on('click', helpCenterImage, (e) => {
      handleImgCLick(e);
    })
    .on('click', '[data-target="#img-modal"]', (e) => {
      handleImgCLick(e);
    });


  scrollToHashedElement(window.location.hash, {scrollIntoViewDelay, callback: expandSection});
  //each youtube video container should have id attribute to be called in callYTPlayer func
  setUniqIdToAllYoutubeVideos();

  function onYoutubeReady() {
    ytVideoIdsArray.forEach((ytVideoId) => {
      const player = new window.YT.Player(ytVideoId, {
        events: {
          onStateChange: (event) => {
            if (event.data === window.YT.PlayerState.PLAYING) {
              if (currentWorkingPlayer && currentWorkingPlayer !== player) {
                currentWorkingPlayer.stopVideo();
              }
              currentWorkingPlayer = player;
            } else if (event.data === window.YT.PlayerState.ENDED) {
              currentWorkingPlayer = null;
            }
          }
        }
      });
    });
  }

  if (window.YT && window.YT.Player) {
    onYoutubeReady();
  } else {
    window.onYouTubeIframeAPIReady = onYoutubeReady;
  }
});
