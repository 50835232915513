import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', function () {
  const now = new Date();
  const checkCount = 'bsCheckCount';
  const lastCheck = 'bsLastCheck';

  let bsLastCheck = Cookies(lastCheck);
  let bsCheckCount = Cookies(checkCount);
  const $surveyPopup = $('#survey-popup');
  const $skipSurveyPopup = $('#skip-survey-popup');

  // Normalize values, defaults to yesterday and zero
  bsLastCheck = bsLastCheck ? new Date(bsCheckCount) : new Date().setDate(new Date().getDate() - 1);
  bsCheckCount = bsCheckCount ? parseInt(bsCheckCount) : 0;

  // Bind skip button
  $skipSurveyPopup.on('click', function () {
    $surveyPopup.modal('hide');
    Cookies(checkCount, 4);
  });

  // User already skipped banner three times
  if (bsCheckCount >= 3) return false;

  // Check if survey popup was skipped past 12 hours
  if (now - bsLastCheck < 1000 * 12 * 60 * 60) return false;

  setTimeout(function () {
    // Show survey popup and update cookie values
    $surveyPopup.modal('show');
    Cookies(lastCheck, now.toString());
    Cookies(checkCount, bsCheckCount + 1);
  }, 100);
});
