import {
  toggleButtonLoading,
  getError
} from '../utils';
import {handleDropinToken} from '../payment/subscription.utils';

$(document).ready(function () {
  const $successSection = $('[data-selector="change-card-success-section"]');
  const $paymentSection = $('[data-selector="change-card-payment-section"]');
  const $cardErrors = $('[data-selector="card-errors"]');
  const $submitBtn = $('[data-selector="change-card-submit-btn"]');
  const braintreeDropin = $('#charge-card-dropin-container');

  if (typeof braintree !== 'undefined' && $(braintreeDropin).length) {
    handleDropinToken(() => {
      braintree.dropin.create({
      authorization: window.braintree_token,
      container: '#charge-card-dropin-container',
      card: {
        cardholderName: {
          required: true
        }
      },
      paypal: {
        flow: 'vault',
        buttonStyle: {
          size: 'medium'
        }
      },
      paypalCredit: {
        flow: 'vault',
        buttonStyle: {
          size: 'medium'
        }
      },
      venmo: {},
      applePay: {
        displayName: 'Kids Academy Company',
        paymentRequest: {
          total: {
            label: 'Kids Academy Talented & Gifted',
            amount: 0
          },
          // We recommend collecting billing address information, at minimum
          // billing postal code, and passing that billing postal code with all
          // Google Pay transactions as a best practice.
          requiredBillingContactFields: []
        }
      },
    }, (createErr, instance) => {
        if (createErr) {
          // An error in the create call is likely due to
          // incorrect configuration values or network issues.
          // An appropriate error will be shown in the UI.
          console.error(createErr); // eslint-disable-line no-console
          return;
        }

        $submitBtn.click(function () {
          toggleButtonLoading($submitBtn, true);

          instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
            if (requestPaymentMethodErr) {
              $cardErrors.text(requestPaymentMethodErr.message);
              $cardErrors.show();
              toggleButtonLoading($submitBtn, false);
              return;
            }

            // Submit payload.nonce to your server
            $.post('/api/braintree/payment-method/', {
                payment_method_nonce: payload.nonce,
              }
            ).then(function () {
                $paymentSection.hide();
                $successSection.show();
              }, function (errorResp) {
                const errorText = getError(errorResp);
                $cardErrors.text(errorText);
                $cardErrors.show();

                toggleButtonLoading($submitBtn, false);
              });
          });
        });
      });
    }, () => {});
  }
});
