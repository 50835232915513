import {
  ENDPOINT,
  SUBSCRIPTION_TYPES,
  ACCOUNT_TYPES_STRING,
  GET_PARAMS,
  LOCAL_STORAGE_KEYS,
  URLS
} from '../config';
import {
  toggleButtonLoading,
  getError
} from '../utils';
import toastr from 'toastr';

$(document).ready(function () {
  const $partnerPromoPage = $('[data-selector="distributor-code-activate-page"]');
  if (!$partnerPromoPage.length) {
    return;
  }

  const $submitBtn = $('[data-selector="distributor-code-submit-btn"]');
  const couponForm = '[data-selector="distributor-code-form"]';
  const $couponInput = $('[data-selector="distributor-code-input"]');
  const $couponForm = $(couponForm);
  const $errorBlock = $('[data-selector="distributor-code-error"]');

  const manageRedirect = (response) => {
    switch((response.product_kind).toString()) {
      case SUBSCRIPTION_TYPES.PARENT:
        redirectToSignUp(ACCOUNT_TYPES_STRING.PARENT);
        break;

      case SUBSCRIPTION_TYPES.SCHOOL:
        redirectToSignUp(ACCOUNT_TYPES_STRING.TEACHER);
        break;

      // SUBSCRIPTION_TYPES.DISTRICT >>> ACCOUNT_TYPES_STRING.TEACHER !!!
      case SUBSCRIPTION_TYPES.DISTRICT:
        redirectToSignUp(ACCOUNT_TYPES_STRING.TEACHER);
        break;

      default:
        redirectToSignUp();
        break;
    }

    toggleButtonLoading($submitBtn, false);
  };

  const redirectToSignUp = (accountType) => {
    if (!accountType) {
      window.location = URLS.SIGN_UP;
      return;
    }

    const key = GET_PARAMS.FROM_DISTRIBUTOR_SIGN_UP_FLOW;
    const link = `${URLS.SIGN_UP}?type=${accountType}&${key}=true`;
    window.location = link;
  };

  const onRequestResolved = ({response, couponCode}) => {
    if (window.isAuthenticated) {
      $.post(ENDPOINT.GIFT_CODE_REDEEM, {
        code: couponCode
      }).then(() => {
        toggleButtonLoading($submitBtn, false);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.PARTNER_COUPON_CODE);
        window.location = URLS.DISTRIBUTOR_CODE_SUCCESS;
      }, (error) => {
        toggleButtonLoading($submitBtn, false);
        const errorText = getError(error);
        toastr.warning(errorText);
      });
      return;
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.PARTNER_COUPON_CODE, couponCode);
    manageRedirect(response);
  };

  $(document)
    .on('submit', couponForm, function(e) {
      e.preventDefault();

      $errorBlock.hide();
      $errorBlock.html('');

      const couponFormParsley = $couponForm.parsley();
      couponFormParsley.validate();

      if (!couponFormParsley.isValid()) {
        return;
      }

      const couponCode = $couponInput.val();

      toggleButtonLoading($submitBtn, true);
      $.ajax({
        url: ENDPOINT.GIFT_CODE_VALIDATE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          code: couponCode
        })
      }).then((response) => {
        onRequestResolved({response, couponCode});
      }, (error) => {
        toggleButtonLoading($submitBtn, false);
        const errorText = getError(error);

        $errorBlock.html(errorText);
        $errorBlock.show();
      });
    });
});
