import {getYTVideoConfig} from '../utils';

$(document).ready(() => {
  const $chessLanding = $('[data-selector="chess-landing"]');
  if (!$chessLanding.length) return;

  let player;
  const $singleVideo = $('[data-selector="chess-landing-single-video"]');
  const gifSelector = '[data-selector="chess-landing-gif"]';
  const $gifOverlay = $('[data-selector="chess-landing-gif-overlay"]');
  const gifContainerSelector = '[data-selector="chess-landing-gif-container"]';
  const $videoModal = $('#videoModal');
  const videoId = 'IaWEeB3ISv4';

  const onPlayerReady = () => {
    $videoModal.on('shown.bs.modal', () => player.playVideo());
  };

  window.onYouTubeIframeAPIReady = () => {
    player = new window.YT.Player(
      'youtube-modal',
      getYTVideoConfig(videoId, onPlayerReady, $videoModal)
    );
    $videoModal.on('shown.bs.modal', () => window.adjustVideo());
    $videoModal.on('hidden.bs.modal', () => player.stopVideo());
  };

  if ($singleVideo.length) {
    $singleVideo[0].addEventListener('click', (e) => {
      e.preventDefault();
      $videoModal.modal('show');
    }, true);
  }

  function getGifRelatedLayers(e) {
    const $gifContainer = $(e.target).closest(gifContainerSelector);
    return $gifContainer.find(gifSelector);
  }

  function showRelatedGifImage(e) {
    const $gifSelector = getGifRelatedLayers(e);
    $gifSelector.show();
  }

  function hideRelatedGifImage(e) {
    const $gifSelector = getGifRelatedLayers(e);
    $gifSelector.hide();
  }

  $gifOverlay.on('mouseenter', showRelatedGifImage)
    .on('mouseleave', hideRelatedGifImage);
});
