import {getYTVideoConfig} from '../utils';

$(document).ready(() => {
  const $videoBtn = $('#e-blox-video');
  const $videoModal = $('#videoModal');
  const videoId = 'm6xf0O2PZsE';
  const $eBloxLandingVideoSection = $('[data-selector="e-blox-landing-video"]');
  let eBloxPlayer;

  if (!$eBloxLandingVideoSection.length) return;

  const onPlayerReady = () => {
    $videoBtn.on('click', () => eBloxPlayer.loadVideoById(videoId));
  };

  function onYoutubeReady() {
    eBloxPlayer = new window.YT.Player(
      'youtube-modal',
      getYTVideoConfig(videoId, onPlayerReady, $videoModal)
    );
    $videoBtn.show();
    $videoModal.on('shown.bs.modal', () => window.adjustVideo());
    $videoModal.on('hidden.bs.modal', () => eBloxPlayer.stopVideo());
  }

  if (window.YT && window.YT.Player) {
    onYoutubeReady();
  } else {
    window.onYouTubeIframeAPIReady = onYoutubeReady;
  }
});
