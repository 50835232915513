import {ACCOUNT_TYPES, ENDPOINT} from '../config';
import Qs from 'qs';
import {
  getError,
  getUserSources,
  toggleButtonLoading
} from '../utils';
import toastr from 'toastr';
import { URLS } from '../config';

$(function () {
  const $redeemModal = $('#redeemModal');
  const $giftExistingMemberBtn = $('[data-selector="gift-redeem-existing"]');
  const $giftPurchaseBtn = $('[data-selector="gift-purchase"]');
  const $giftNewMemberBtn = $('[data-selector="gift-redeem-new"]');
  const loginExistingRedirect = '/login/?next=/gift/?modal=redeemModal';

  // code modal
  const $codeForm = $('[data-selector="gift-code-form"]');
  const $giftCodeInput = $('[data-selector="gift-code-input"]');
  const $giftCodeError = $('[data-selector="gift-code-error"]');
  const $giftCodeSubmitBtn = $('[data-selector="gift-page-submit-btn"]');

  const signUpParent = () => {
    return $.ajax({
      url: ENDPOINT.USER_PROFILE,
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({
        account_type: ACCOUNT_TYPES.PARENT,
        email: null,
        need_to_login: true,
        source: getUserSources('source'),
        medium: getUserSources('medium'),
        campaign: getUserSources('campaign'),
        placement: getUserSources('placement')
      })
    });
  };

  $giftExistingMemberBtn.click(function (e) {
    e.preventDefault();

    if (window.isLoggedIn) {
      $redeemModal.modal('show');
    } else {
      window.location.href = loginExistingRedirect;
    }
  });

  $giftNewMemberBtn.click(function (e) {
    e.preventDefault();
    $redeemModal.modal('show');
  });

  $giftPurchaseBtn.click(function () {
    ga('send', 'event', 'Button', 'ClickHere', 'Gift');
  });

  $codeForm.submit((e) => {
    e.preventDefault();

    // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
    const hiddenInputVal = $('[data-selector="gift-code-special-input"]').val();
    if (hiddenInputVal) return;

    const codeParsley = $codeForm.parsley();

    if (!codeParsley.isValid()) {
      codeParsley.validate();
      return;
    }

    toggleButtonLoading($giftCodeSubmitBtn, true);

    $.post(ENDPOINT.GIFT_CODE_REDEEM, {
      code: $giftCodeInput.val()
    }).then(() => {
      if (window.hasActiveSubscription) {
        window.location.href = '/gift/redeem/success/';
        return;
      }

      if (window.isLoggedIn) {
        window.location.href = `/${URLS.PARENT_SUBSCRIPTION_SUCCESS}`;
        return;
      }

      signUpParent().then(() => {
        window.location.href = `/${URLS.SIGN_UP_PARENT_COMPLETE}`;
      }, (error) => {
        const errors = getError(error, false);
        toastr.error(errors.error, '', {timeOut: 3000});
      });
    }, (errorResp) => {
      toggleButtonLoading($giftCodeSubmitBtn, false);
      const error = getError(errorResp);
      $giftCodeError.html(error);
      $giftCodeError.show();
    });
  });

  // Show modal
  const queryObject = Qs.parse(location.search.substr(1));
  if (queryObject.modal === 'redeemModal') {
    $redeemModal.modal('show');
  }
});
