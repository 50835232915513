import {getYTVideoConfig} from '../utils';

(function () {
  const $resourceCenterPage = $('[data-selector="resource-center"]');
  if(!$resourceCenterPage.length) return;

  const $welcomeYoutubeVideoTarget = $('[data-selector="kids-academy-account-yt-video"]');
  const $videoModal = $('#videoModal');
  const videoId = 'LdD6SFfXSt4';

  const loginLinkSelector = $('[data-selector="login-link"]');
  const $loginLink = $(loginLinkSelector);
  const loginModalSelector = '#logInModal';
  const $loginModal = $(loginModalSelector);
  let welcomePlayer;

  const onPlayerReady = () => {
    $welcomeYoutubeVideoTarget.on('click', () => welcomePlayer.loadVideoById(videoId));
  };

  $loginLink.click(function (e) {
    e.preventDefault();
    $loginModal.modal('show');
  });

  if ($welcomeYoutubeVideoTarget.length) {
    window.onYouTubeIframeAPIReady = () => {
      welcomePlayer = new window.YT.Player(
        'youtube-modal',
        getYTVideoConfig(videoId, onPlayerReady, $videoModal)
      );
      $videoModal.on('shown.bs.modal', () => window.adjustVideo());
      $videoModal.on('hidden.bs.modal', () => welcomePlayer.stopVideo());
    };
  }
})();
