import Chart from 'chart.js';
import {
  ENDPOINT,
  SUBJECTS_CATEGORIES_MAPPING,
  SUBJECTS_GRADES_MAPPING,
  ACCOUNT_SETTINGS_NAV_ITEM,
  MONTH_NAMES
} from '../config';
import {getError} from '../utils';
import toastr from 'toastr';
import moment from 'moment';


$(document).ready(() => {
  const $parentReports = $('[data-selector="parent-reports"]');
  if (!$parentReports.length) return;

  const goalPerDayTotal = 15;   // get it from server in future
  const goalPerWeekTotal = 105; // get it from server in future?

  const activeClassName = '_active';
  const reportMainNavBtn = '[data-selector="account-settings-report-nav-btn"]';

  const $parentReportsLoader = $('[data-selector="parent-reports-loader"]');
  const $parentReportsEmptyText = $('[data-selector="parent-reports-empty-text"]');

  // kid data
  const $kidName = $('[data-selector="kid-first-name"]');
  const $kidAvatar = $('[data-selector="kid-avatar-img"]');
  const kidGrade = '[data-selector="kid-grade"]';
  const $kidGrade = $(kidGrade);
  const kidGradeIdAttrName = 'data-kid-grade-id';

  const $textKidName = $('[data-selector="parent-report-text-kid-name"]');

  // account settings nav btn
  const goToChildDataBtn = '[data-selector="go-to-child-report-button"]';

  /** PROGRESS BLOCK **/
  const $progressBlock = $('[data-selector="parent-reports-progress-block"]');

  // target goal per day chart
  const $targetGoalPerDaySpent = $('[data-selector="goal-per-day-spent"]');
  const $targetGoalPerDayTarget = $('[data-selector="goal-per-day-target"]');

  // target goal per week chart
  const $targetGoalPerWeekSpent = $('[data-selector="goal-per-week-spent"]');
  const $targetGoalPerWeekTarget = $('[data-selector="goal-per-week-target"]');

  /** SUBJECTS BLOCK **/
  const subjectCard = '[data-selector="parent-report-subject-card"]';
  const subjectTitleAttrName = 'data-subject-title';

  /** GRADES BLOCK **/
  const $gradesBlock = $('[data-selector="parent-reports-grades-block"]');
  const gradeCard = 'data-selector="parent-report-grade-card"';
  const gradeName = '[data-selector="parent-report-grade-name"]';
  const $gradeCards = $(`[${gradeCard}]`);
  const gradeIdAttrName = 'data-grade-id';
  const gradeSelectedClassName = '_any-grade-was-selected';

  /** STARS BLOCK **/
  const $starsBlock = $('[data-selector="parent-reports-stars"]');
  const $starsDay = $('[data-selector="parent-reports-stars-day"]');
  const $starsWeek = $('[data-selector="parent-reports-stars-week"]');
  const $starsTotal = $('[data-selector="parent-reports-stars-total"]');

  /** OVERALL BLOCK **/
  const $overallBlock = $('[data-selector="parent-report-overall-block"]');
  const $overallBlockTitle = $('[data-selector="parent-report-overall-progress-title"]');

  const completedCountChartText = $('[data-selector="completed-lessons-count-chart-text"]');
  const totalCountChartText = $('[data-selector="total-lessons-count-chart-text"]');

  const $OverallChartCompleted = $('[data-selector="overall-progress-chart-finished"]');
  const $OverallChartNotStarted = $('[data-selector="overall-progress-chart-not-started"]');

  /** BREADCRUMBS BLOCK **/
  const $breadcrumbsBlock = $('[data-selector="parent-report-breadcrumbs"]');
  const $breadcrumbsItem = $('[data-selector="parent-report-breadcrumbs-item"]');
  const breadcrumbsBack = '[data-selector="parent-report-breadcrumbs-back"]';

  /** BOTTOM BLOCK **/
  const $bottomBlock = $('[data-selector="parent-report-bottom-block"]');
  const bottomBlockModifierClassName = '_grade-selected';
  const $parentReportsBottomLoader = $('[data-selector="parent-reports-bottom-loader"]');

  /** OVERVIEW CHART BLOCK **/
  const $overviewChart = $('[data-selector="parent-report-overview-chart-block"]');
  const weekOverviewBarChartLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  /** CURRENT LESSON BLOCK  **/
  const $currentLesson = $('[data-selector="parent-reports-current-lesson"]');
  const currentLessonIdAttrName = 'data-current-lesson-id';
  const currentLessonCourseIdAttrName = 'data-current-course-id';
  const currentLessonDetailsBtn = '[data-selector="parent-reports-current-lesson-details-btn"]';
  const $currentLessonDetailsBtn = $(currentLessonDetailsBtn);

  /** PREVIOUS LESSONS**/
  const $prevLessonsList = $('[data-selector="parent-report-prev-lessons-list"]');
  const prevLessonItem = '[data-selector="parent-report-prev-lesson-item"]';
  const prevLessonIdAttrName = 'data-prev-lesson-id';
  const prevLessonCourseIdAttrName = 'data-prev-lesson-course-id';

  /** NOT STARTED LESSONS **/
  const $notStartedLessonsBlock = $('[data-selector="parent-report-not-started-lessons-block"]');
  const $notStartedLessonsList = $('[data-selector="parent-report-not-started-lessons-list"]');
  const notStartedLessonItem = '[data-selector="parent-report-not-started-lesson-item"]';
  const notStartedLessonIdAttrName = 'data-not-started-lesson-id';
  const notStartedLessonCourseIdAttrName = 'data-not-started-lesson-course-id';

  /** DETAILED CHART **/
  const $detailedChartBlock = $('[data-selector="parent-report-detailed-chart-block"]');
  const $detailedChartGamePlayMin = $('[data-selector="parent-report-detailed-chart-game-play"]');
  const detailedChartPeriodBtn = '[data-selector="parent-report-detailed-chart-period"]';
  const detailedChartPeriodAttrName = 'data-period';

  /** LESSON DETAILS **/
  const $lessonDetailsBlock = $('[data-selector="parent-report-lesson-details"]');
  const lessonDetailsCloseBtn = '[data-selector="parent-report-hide-lesson-details-btn"]';
  const $lessonDetailsList = $('[data-selector="parent-report-lesson-details-list"]');
  const $lessonDetailsLessonName = $('[data-selector="parent-report-lesson-details-lesson-name"]');

  /** CHART PAGING **/
  const chartPagingPrevBtn = '[data-selector="parent-report-chart-paging-prev"]';
  const chartPagingNextBtn = '[data-selector="parent-report-chart-paging-next"]';
  const chartPagingDateElem = '[data-selector="parent-report-chart-paging-date"]';
  const chartTypeAttrName = 'data-chart-type';
  const currentPeriodStartAttrName = 'date-current-period-start';

  const OVERVIEW_CHART = 'overview-chart';
  const DETAILED_CHART = 'detailed-chart';
  const DETAILED_CHART_PERIOD_COUNT = 6;
  const OVERVIEW_CHART_PERIOD_COUNT = 7;

  const PERIODS = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month'
  };

  let reportData = [];
  let kidData = {};
  let course = {};

  const chartLoader = '[data-selector="chart-loader"]';

  let detailedBardChartInstance = null;
  let weekDonutChartInstance = null;
  let dayDonutChartInstance = null;
  let weekOverviewChartInstance = null;


  /**
   * @param seconds {number}
   * @returns minutes {number}
   */
  const roundSecondsInMinutes = (seconds) => {
    if (!seconds) return 0;
    if (typeof seconds !== 'number') {
      /* eslint-disable-next-line no-console */
      console.trace('method got not a number as argument');
      return 0;
    }

    return Math.floor(seconds / 60);
  };

  const getGradesIdsArrayBySubject = (categoriesIdsArray) => {
    const gradesArray = [];

    categoriesIdsArray.forEach((categoryId) => {
      kidData.overall_progress.forEach(item => {
        if (categoryId === item.category_id) {
          if (gradesArray.indexOf(item.grade_id) < 0) {
            gradesArray.push(item.grade_id);
          }
        }
      });
    });

    return gradesArray;
  };

  const getCategoriesIdArrayBySubjectTitle = (subjectTitle) => {
    const categoriesTitlesArray = SUBJECTS_CATEGORIES_MAPPING[subjectTitle];
    const categoriesIdsArray = [];

    categoriesTitlesArray.forEach((categoryTitle) => {
      const categoryObj = window.categories.find(category => category.name === categoryTitle);
      categoriesIdsArray.push(categoryObj.id);
    });

    return categoriesIdsArray;
  };

  const toggleSubjectsCardsVisibility = () => {
    const subjectsGradesArray = [];
    const $subjectsCards = $(subjectCard);

    $subjectsCards.each((i, item) => {
      const subjectTitle = $(item).attr(subjectTitleAttrName);
      const categoriesIdsArray = getCategoriesIdArrayBySubjectTitle(subjectTitle);
      const gradesArray = getGradesIdsArrayBySubject(categoriesIdsArray);

      subjectsGradesArray.push({
        $subjectCard: $(item),
        gradesArray: gradesArray
      });
    });

    subjectsGradesArray.forEach(item => {
      if (!item.gradesArray.length) item.$subjectCard.hide();
    });
  };

  const getReportDataFromServer = () => {
    // timeZoneOffset - in seconds,
    // with "-" sign if needed, for Minsk "tz_offset=10800", for Chicago "tz_offset=-21600"
    const timeZoneOffset = new Date().getTimezoneOffset() * 60 * -1;

    $.ajax({
      url: ENDPOINT.GET_KIDS_REPORT_DATA(timeZoneOffset),
      type: 'GET',
    }).then((response) => {
      reportData = response;

      const kidId = getSelectedKidId();
      const data = getKidDataById(kidId, reportData);

      if (data === null) {
        $parentReportsEmptyText.show();
        $parentReportsLoader.hide();
        return;
      }

      kidData = data;
      setKidDataToHTML(kidData);
      toggleSubjectsCardsVisibility();

      $parentReports.show();
      $parentReportsLoader.hide();

      // init charts
      initGoalPerDayDonutChart();
      initGoalPerWeekDonutChart();

      // init week overview bar chart
      initWeekOverviewBarChart(kidData.daily_studying_time_spent);
      handlePagingButtonsVisibility(kidData.daily_studying_time_spent, $overviewChart);
    }, (error) => {
      const errorText = getError(error);
      toastr.warning(errorText);
    });
  };

  /**
   * Returns selected on UI kid ID
   * @returns {*}
   */
  const getSelectedKidId = () => {
    const selectedKidNavBtn = $(`${goToChildDataBtn}.${activeClassName}`);
    const kidId = selectedKidNavBtn.attr('data-child-id');
    return kidId;
  };

  /**
   * Returns today date (example: 2020-02-30)
   * @returns {string}
   */
  const getTodayDateString = () => {
    const today = new Date();
    /* eslint-disable-next-line max-len */
    return `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + (today.getDate())).slice(-2)}`;
  };

  /**
   * Parse kidData variable and returns time spent in application (in minutes) today by kid
   * @returns {number}
   */
  const getTodayTimeSpentInApp = () => {
    if (!kidData.daily_studying_time_spent) {
      /* eslint-disable-next-line no-console */
      console.trace('Server response does not have daily_studying_time_spent property');
      return 0;
    }

    const todayDate = getTodayDateString();
    const todayDataObj = kidData.daily_studying_time_spent.find((item) => {
      return item.date === todayDate;
    });

    if (!todayDataObj) {
      /* eslint-disable-next-line no-console */
      console.trace('Server response does not have data for "target goal per day" chart');
      return 0;
    }

    if (todayDataObj.elapsed_time !== 0 && !todayDataObj.elapsed_time) {
      /* eslint-disable-next-line no-console */
      console.trace('Server response does not have "elapsed_time" property');
      return 0;
    }

    return roundSecondsInMinutes(todayDataObj.elapsed_time);
  };

  const setGoalPerDayChartDataToHTML = (timeToGoal, passedTime) => {
    $targetGoalPerDayTarget.html(timeToGoal);
    $targetGoalPerDaySpent.html(passedTime);
  };

  const setGoalPerWeekChartDataToHTML = (timeToGoal, passedTime) => {
    $targetGoalPerWeekTarget.html(timeToGoal);
    $targetGoalPerWeekSpent.html(passedTime);
  };

  /*** TARGET GOAL PER WEEK/DAY DONUT CHART SETTINGS ***/
  const donutChartColors = ['#73BA0C', '#DBE8EF'];
  const donutChartOptions = {
    tooltips: {
      enabled: false
    },
    cutoutPercentage: 78
  };

  /*** GOAL PER DAY DONUT CHART INIT ***/
  const initGoalPerDayDonutChart = () => {
    const minPassed = getTodayTimeSpentInApp();
    const minLeft = getLeftTimeValueForDonutCharts(goalPerDayTotal, minPassed);

    setGoalPerDayChartDataToHTML(goalPerDayTotal, minPassed);

    if (dayDonutChartInstance) dayDonutChartInstance.destroy();
    dayDonutChartInstance = new Chart($('#goal-per-day-donut-chart'), {
      type: 'doughnut',
      data: {
        labels: [],
        datasets: [{
          data: [minPassed, minLeft],
          backgroundColor: donutChartColors,
          borderWidth: 0,
        }]
      },
      options: donutChartOptions
    });
  };

  const getLeftTimeValueForDonutCharts = (toGoal, passed=0) => {
    const timeLeft = toGoal - passed;
    if (timeLeft < 0) return 0;

    return timeLeft;
  };

  /*** GOAL PER WEEK DONUT CHART INIT ***/
  const initGoalPerWeekDonutChart = () => {
    const minPassed = getWeekTimeSpentInApp();
    const minLeft = getLeftTimeValueForDonutCharts(goalPerWeekTotal, minPassed);

    setGoalPerWeekChartDataToHTML(goalPerWeekTotal, minPassed);

    if (weekDonutChartInstance) weekDonutChartInstance.destroy();
    weekDonutChartInstance = new Chart($('#goal-per-week-donut-chart'), {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [minPassed, minLeft],
          backgroundColor: donutChartColors,
          borderWidth: 0,
        }]
      },
      options: donutChartOptions
    });
  };

  /**
   * Returns array of minutes
   * Each element of array is time in minutes that kid spent in the app for one day
   * @returns {number[]|*}
   */
  const getWeekBarChartData = (dataArray) => {
    if (!dataArray) {
      /* eslint-disable-next-line no-console */
      console.warn('Server response does not have daily_studying_time_spent property');
      return [0, 0, 0, 0, 0, 0, 0];
    }

    const timeInSecondsArray = dataArray.map((item) => item.elapsed_time);
    const timeInMinutesArray = timeInSecondsArray.map(item => roundSecondsInMinutes(item));
    return timeInMinutesArray;
  };

  /**
   * Returns total time in minutes spent by kid in the app on current week
   * @returns {number|*}
   */
  const getWeekTimeSpentInApp = () => {
    if (!kidData.daily_studying_time_spent) {
      /* eslint-disable-next-line no-console */
      console.warn('Server response does not have daily_studying_time_spent property');
      return 0;
    }

    const timeIsSeconds = kidData.daily_studying_time_spent.reduce((acc, item) => {
      return acc + item.elapsed_time;
    }, 0);

    return roundSecondsInMinutes(timeIsSeconds);
  };

  const initDetailedBarChart = (chartData, chartLabelsArray) => {
    if (detailedBardChartInstance) detailedBardChartInstance.destroy();

    detailedBardChartInstance = new Chart($('#detailed-chart'), {
      type: 'bar',
      data: {
        labels: chartLabelsArray,
        datasets: [{
          data: chartData,
          backgroundColor: '#A287C7',
          borderWidth: 0,
          barPercentage: 0.4,
        }]
      },
      options: {
        scales: {
          xAxes: [{
            gridLines: {
              display:false
            },
            ticks: {
              fontFamily: 'Museo Sans, sans-serif',
              fontSize: 14,
              fontColor: '#595959',
              lineHeight: 1.15
            }
          }],
          yAxes: [{
            gridLines: {
              display:false
            },
            ticks: {
              stepSize: 5, // TODO count this value depends on the biggest value from data array
              fontFamily: 'Museo Sans, sans-serif',
              fontSize:  14,
              fontColor: '#595959',
              lineHeight: 1.15,
              beginAtZero: true,
              min: 0
            },
          }]
        },
        responsive: false,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false,
          labels: {
            fontColor: '#00f'
          }
        }
      }
    });
  };

  /*** WEEK OVERVIEW BAR CHART INIT ***/
  const initWeekOverviewBarChart = (dataArray) => {
    const chartData = getWeekBarChartData(dataArray);

    setDatePeriodToChartPaging(dataArray, $overviewChart);

    if (weekOverviewChartInstance) weekOverviewChartInstance.destroy();
    weekOverviewChartInstance = new Chart($('#week-overview-chart'), {
      type: 'bar',
      data: {
        labels: weekOverviewBarChartLabels,
        datasets: [{
          data: chartData,
          backgroundColor: '#A287C7',
          borderWidth: 0,
          barPercentage: 0.4,
        }]
      },
      options: {
        scales: {
          xAxes: [{
            gridLines: {
              display:false
            },
            ticks: {
              fontFamily: 'Museo Sans, sans-serif',
              fontSize: 14,
              fontColor: '#595959',
              lineHeight: 1.15
            }
          }],
          yAxes: [{
            gridLines: {
              display:false
            },
            ticks: {
              stepSize: 5, // TODO count this value depends on the biggest value from data array
              fontFamily: 'Museo Sans, sans-serif',
              fontSize:  14,
              fontColor: '#595959',
              lineHeight: 1.15,
              beginAtZero: true,
              min: 0
            },
          }]
        },
        responsive: false,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false,
          labels: {
            fontColor: '#00f'
          }
        }
      }
    });
  };

  /*** OVERALL PROGRESS BAR CHART INIT ***/
  const initOverallProgressBar = (completedCount, totalCount) => {
    if (completedCount !== 0 && !completedCount) {
      /* eslint-disable-next-line no-console */
      console.trace('completedCount argument needed!');
      return;
    }

    if (totalCount !== 0 && !totalCount) {
      /* eslint-disable-next-line no-console */
      console.trace('totalCount argument needed! ');
      return;
    }

    const notStartedWidth = (totalCount - completedCount) / totalCount;
    const completedWidth = completedCount / totalCount;

    $OverallChartCompleted.css('width', `${completedWidth * 100}%`);
    $OverallChartNotStarted.css('width', `${notStartedWidth * 100}%`);
  };

  const getKidDataById = (kidId) => {
    if (!kidId) return null;
    if (!reportData) return null;

    const result = reportData.find(kidItem => kidItem.user.id === parseInt(kidId));

    if (!result) return null;

    return result;
  };

  /**
   *
   * @param kidData {object}
   */
  const setKidDataToHTML = (kidData) => {
    $kidAvatar.attr('src', kidData.user.avatar_url);

    $kidGrade.html(kidData.user.grade_name);
    $kidGrade.attr(kidGradeIdAttrName, kidData.user.grade_id);
    $kidName.html(kidData.user.first_name);
    $textKidName.html(kidData.user.first_name);

    $starsDay.html(kidData.stars.stars_earned_today);
    $starsWeek.html(kidData.stars.stars_earned_this_week);
    $starsTotal.html(kidData.stars.stars_balance);
  };

  /***
   * * Returns categories id array based on SUBJECTS_CATEGORIES_MAPPING
   *
   * @param chartType {string}
   * @returns {[]|*[]}
   */
  const getCategoriesIdArrayByActiveSubjectTitle = (chartType='') => {
    if (!chartType) {
      /* eslint-disable-next-line no-console */
      console.trace('chartType as argument is required');
      return [];
    }

    const $selectedSubject = $(`${subjectCard}.${activeClassName}`);
    const subjectTitle = $selectedSubject.attr(subjectTitleAttrName);

    if (chartType === OVERVIEW_CHART) {
      return [];
    }

    const categoriesTitlesArray = SUBJECTS_CATEGORIES_MAPPING[subjectTitle];
    const categoriesIdsArray = [];

    categoriesTitlesArray.forEach((categoryTitle) => {
      const categoryObj = window.categories.find(category => category.name === categoryTitle);

      if (!categoryObj) {
        /* eslint-disable-next-line no-console */
        console.trace('Can\'t find category in window.categories variable');
        return [];
      }

      categoriesIdsArray.push(categoryObj.id);
    });

    return categoriesIdsArray;
  };

  /**
   *  TODO add description
   *
   * @param categoryIdsArray: array
   * @param gradeIdsArray: array
   * @returns {{completedCount: number, totalCount: number}|{}}
   */
  const getOverallProgressBarChartData = (categoryIdsArray, gradeIdsArray) => {
    if (!Array.isArray(categoryIdsArray)) {
      /* eslint-disable-next-line no-console */
      console.trace('categoryIdsArray as argument needed!');
      return {};
    }

    if (!Array.isArray(gradeIdsArray)) {
      /* eslint-disable-next-line no-console */
      console.trace('categoryIdsArray as argument needed!');
      return {};
    }

    const filteredLessons = [];
    kidData.overall_progress.forEach((lesson) => {
      gradeIdsArray.forEach((gradeId) => {
        if (lesson.grade_id === parseInt(gradeId)) {
          categoryIdsArray.forEach((categoryId) => {
            if (lesson.category_id === parseInt(categoryId)) {
              filteredLessons.push(lesson);
            }
          });
        }
      });
    });

    const completedLessons = filteredLessons.filter(lesson => lesson.is_completed);

    return {
      completedCount: completedLessons.length,
      totalCount: filteredLessons.length
    };
  };

  const initGradesDonutCharts = (gradesIdsArray, categoriesIdsArray) => {
    if (!Array.isArray(gradesIdsArray)) {
      /* eslint-disable-next-line no-console */
      console.trace('gradesIdsArray as argument needed!');
      return {};
    }

    if (!Array.isArray(categoriesIdsArray)) {
      /* eslint-disable-next-line no-console */
      /* eslint-disable-next-line no-console */
      console.trace('categoriesIdsArray as argument needed!');
      return {};
    }

    $gradesBlock.show();

    $(`[${gradeCard}]`).hide();
    $(`[${gradeIdAttrName}]`).find('canvas').remove();

    gradesIdsArray.forEach((gradeId) => {
      const donutChartData = getOverallProgressBarChartData(categoriesIdsArray, [gradeId]);

      const canvas = document.createElement('canvas'),
      chartId = `grade-donut-chart-${gradeId}`;
      canvas.id = chartId;

      const $currentGradeCard = $(`[${gradeIdAttrName}="${gradeId}"]`);

      if (donutChartData.totalCount !== 0) {
        $currentGradeCard.prepend(canvas);
        $currentGradeCard.show();
      }

      const chartElem = document.getElementById(chartId);
      if (!chartElem) return;

      const context = chartElem.getContext('2d');

      const lessonsPassed = donutChartData.completedCount;
      const lessonsLeft =  donutChartData.totalCount - donutChartData.completedCount;

      window[chartId] = new Chart(context, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [lessonsPassed, lessonsLeft],
            backgroundColor: donutChartColors,
            borderWidth: 0,
          }]
        },
        options: donutChartOptions
      });
    });
   };

  const getGradesIdArrayBySubjectTitle = (subjectName) => {
    if (!subjectName) return;

    const gradesIdsArray = SUBJECTS_GRADES_MAPPING[subjectName];

    if (!gradesIdsArray) {
      /* eslint-disable-next-line no-console */
      console.trace('Can\'t get gradesIdsArray from SUBJECTS_GRADES_MAPPING by subjectName');
      return;
    }

    return gradesIdsArray;
  };

  const toggleReportsNavBtns = ($currentBtn) => {
    $(goToChildDataBtn).removeClass(activeClassName);

    if (!$currentBtn) {
      $(goToChildDataBtn).first().addClass(activeClassName);
      $(goToChildDataBtn).first().click();
      return;
    }

    $currentBtn.addClass(activeClassName);
  };

  const handleUrlHashOnPageLoad = () => {
    const formattedHash = window.location.hash.substr(1).split('?')[0];
    if (formattedHash === ACCOUNT_SETTINGS_NAV_ITEM.REPORT) {
      const $elem = $(`[data-id="${formattedHash}"]`);
      if ($elem) {
        $elem.click();  // click on report nav item
        $(goToChildDataBtn).first().click(); // click on first kid nav item
      }
    }
  };

  const updateOverallProgressTitle = (title='') => {
    let defaultTitle = $overallBlockTitle.attr('data-default-title');

    if (!defaultTitle) {
      defaultTitle = $overallBlockTitle.html();
      $overallBlockTitle.attr('data-default-title', defaultTitle);
    }

    if (title === '') {
      $overallBlockTitle.html(defaultTitle);
      return;
    }

    $overallBlockTitle.html(title);
  };

  const getCurrentLesson = () => {
    let currentLesson  = null;
    const filteredLessons = [];
    let gradesIdsArray = [];
    const $selectedGrade = $(`[${gradeCard}].${activeClassName}`);
    const selectedGradeIdStr = $selectedGrade.attr(gradeIdAttrName);
    gradesIdsArray = [selectedGradeIdStr];
    const kidGradeIdStr = $kidGrade.attr(kidGradeIdAttrName);
    if (!selectedGradeIdStr) gradesIdsArray = [kidGradeIdStr];

    const categoriesIdsArray = getCategoriesIdArrayByActiveSubjectTitle(DETAILED_CHART);

    // find lessons for selected grade and categories
    kidData.overall_progress.forEach((lesson) => {
      gradesIdsArray.forEach((gradeId) => {
        if (lesson.grade_id === parseInt(gradeId)) {
          categoriesIdsArray.forEach((categoryId) => {
            if (lesson.category_id === parseInt(categoryId)) {
              filteredLessons.push(lesson);
            }
          });
        }
      });
    });

    const sortedLessons = filteredLessons.sort((lesson) => {
      return (lesson.lesson_order > lesson.lesson_order) ? 1 : -1;
    });

    const reversedLessons = sortedLessons.reverse();

    for (let i = 0; i < reversedLessons.length; i++) {
      if (reversedLessons[i].is_completed) {
        currentLesson = reversedLessons[i - 1];
        break;
      }
    }

    return currentLesson;
  };

  const getNotStartedLessons = () => {
    const filteredLessons = [];
    const $selectedGrade = $(`[${gradeCard}].${activeClassName}`);
    const selectedGradeIdStr = $selectedGrade.attr(gradeIdAttrName);

    const categoriesIdsArray = getCategoriesIdArrayByActiveSubjectTitle(DETAILED_CHART);

    const notStartedLessons = kidData.overall_progress.filter(lesson => !lesson.is_completed);

    // find lessons for selected grade and categories
    notStartedLessons.forEach((lesson) => {
      if (lesson.grade_id === parseInt(selectedGradeIdStr)) {
        categoriesIdsArray.forEach((categoryId) => {
          if (lesson.category_id === parseInt(categoryId)) filteredLessons.push(lesson);
        });
      }
    });

    const sortedLessons = filteredLessons.sort((lesson) => {
      return (lesson.lesson_order > lesson.lesson_order) ? 1 : -1;
    });

    // remove one first lesson if there is at least one lesson started
    if (getCurrentLesson()) sortedLessons.splice(0, 1);

    return sortedLessons;
  };

  /**
   * Returns lessons array.
   *
   * @returns {array}
   */
  const getPreviousLessons = () => {
    const filteredLessons = [];
    let previousLessonsArray  = [];
    let gradeId = null;

    const $selectedGrade = $(`[${gradeCard}].${activeClassName}`);
    gradeId = $selectedGrade.attr(gradeIdAttrName);
    if (!gradeId) gradeId = $kidGrade.attr(kidGradeIdAttrName);

    const categoriesIdsArray = getCategoriesIdArrayByActiveSubjectTitle(DETAILED_CHART);

    // find lessons for selected grade and categories
    kidData.overall_progress.forEach((lesson) => {
      if (lesson.grade_id === parseInt(gradeId)) {
        categoriesIdsArray.forEach((categoryId) => {
          if (lesson.category_id === parseInt(categoryId)) {
            filteredLessons.push(lesson);
          }
        });
      }
    });

    const completedLessons = filteredLessons.filter(lesson => lesson.is_completed);
    const sortedLessons = completedLessons.sort((lesson) => {
      return (lesson.lesson_order > lesson.lesson_order) ? 1 : -1;
    });

    // prev lessons for selected grade, sorted from last to first
    previousLessonsArray = sortedLessons.reverse();

    // if user select grade on UI
    if ($selectedGrade && !$selectedGrade.length) {
      const gradesArr = Object.assign([], window.grades);
      const index = gradesArr.findIndex((grade => grade.id === parseInt(gradeId)));
      if (index !== -1) gradesArr.splice(index, 1);
      const gradesIdsArray = gradesArr.map(grade => grade.id);
      const reversedGradesIdsArray = gradesIdsArray.reverse();

      const arrayOfCompletedLessonsArrays = [];
      const completedLessons = kidData.overall_progress.filter(lesson => lesson.is_completed);

      completedLessons.forEach((lesson) => {
        reversedGradesIdsArray.forEach((gradeId, index) => {
          if (!arrayOfCompletedLessonsArrays[index]) arrayOfCompletedLessonsArrays[index] = [];

          if (lesson.grade_id === parseInt(gradeId)) {
            categoriesIdsArray.forEach((categoryId) => {
              if (lesson.category_id === parseInt(categoryId)) {
                arrayOfCompletedLessonsArrays[index].push(lesson);
              }
            });
          }
        });
      });

      arrayOfCompletedLessonsArrays.forEach((lessonList) => {
        const sortedLessons = lessonList.sort((lesson) => {
          return (lesson.lesson_order > lesson.lesson_order) ? 1 : -1;
        });

        const reversedLessons = sortedLessons.reverse();
        previousLessonsArray = previousLessonsArray.concat(reversedLessons);
      });
    }

    return previousLessonsArray;
  };

  const setNotStartedLessonsToUI = (lessonsArray) => {
    if (!lessonsArray) {
      /* eslint-disable-next-line no-console */
      console.trace('lessonsArray as argument is required');
      return;
    }

    if (!Array.isArray(lessonsArray)) {
      /* eslint-disable-next-line no-console */
      console.trace('lessonsArray argument should be an array');
      return;
    }

    $notStartedLessonsList.html('');

    if (!lessonsArray.length) {
      $notStartedLessonsList.append(`
          <div class="parent-reports__not-started-lessons-list-item">
              All lessons finished
          </div>
      `);
      return;
    }

    // make reversed order for not started lessons
    const reversedArray = lessonsArray.reverse();

    reversedArray.forEach((lesson, index) => {
      const lessonHTML =
        `<div class="parent-reports__not-started-lessons-list-item"
               data-selector="parent-report-not-started-lesson-item"
               data-not-started-lesson-id="${lesson.lesson_id}"
               data-not-started-lesson-course-id="${lesson.course_id}"
          >
            ${index + 1}. ${lesson.lesson_name}
            <svg class="parent-reports__not-started-lessons-list-item-btn" width="28" height="10" viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.4243 5.42427C27.6586 5.18995 27.6586 4.81005 27.4243 4.57574L23.6059 0.757361C23.3716 0.523047 22.9917 0.523047 22.7574 0.757361C22.523 0.991676 22.523 1.37157 22.7574 1.60589L26.1515 5L22.7574 8.39411C22.523 8.62843 22.523 9.00833 22.7574 9.24264C22.9917 9.47696 23.3716 9.47696 23.6059 9.24264L27.4243 5.42427ZM-5.24537e-08 5.6L27 5.6L27 4.4L5.24537e-08 4.4L-5.24537e-08 5.6Z" fill="#3A6FB6"/></svg>
          </div>`;

      $notStartedLessonsList.append(lessonHTML);
    });
  };

  const setPreviousLessonsToUI = (lessonsArray) => {
    if (!lessonsArray) {
      /* eslint-disable-next-line no-console */
      console.trace('lessonsArray as argument is required');
      return;
    }

    if (!Array.isArray(lessonsArray)) {
      /* eslint-disable-next-line no-console */
      console.trace('lessonsArray argument should be an array');
      return;
    }

    $prevLessonsList.html('');

    if (!lessonsArray.length) {
      $prevLessonsList.append(`
          <div class="parent-reports__previous-lessons-list-item">
              No previous lessons
          </div>
      `);
    }

    lessonsArray.forEach((lesson, index) => {
      const lessonHTML =
        `<div class="parent-reports__previous-lessons-list-item"
               data-selector="parent-report-prev-lesson-item"
               data-prev-lesson-id="${lesson.lesson_id}"
               data-prev-lesson-course-id="${lesson.course_id}"
          >
            ${lessonsArray.length - index}. ${lesson.lesson_name}
            <svg class="parent-reports__previous-lessons-list-item-btn" width="28" height="10" viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.4243 5.42427C27.6586 5.18995 27.6586 4.81005 27.4243 4.57574L23.6059 0.757361C23.3716 0.523047 22.9917 0.523047 22.7574 0.757361C22.523 0.991676 22.523 1.37157 22.7574 1.60589L26.1515 5L22.7574 8.39411C22.523 8.62843 22.523 9.00833 22.7574 9.24264C22.9917 9.47696 23.3716 9.47696 23.6059 9.24264L27.4243 5.42427ZM-5.24537e-08 5.6L27 5.6L27 4.4L5.24537e-08 4.4L-5.24537e-08 5.6Z" fill="#3A6FB6"/></svg>
          </div>`;

      $prevLessonsList.append(lessonHTML);
    });
  };

  /**
   * Update UI with current lesson title and current lesson id
   * @param lesson: object
   */
  const setCurrentLessonToUI = (lesson) => {
    if (lesson === null) {
      $currentLesson.html(`No lessons started for ${kidData.user.grade_name}`);
      $currentLesson.attr(currentLessonIdAttrName, null);
      $currentLesson.attr(currentLessonCourseIdAttrName, null);
      $currentLessonDetailsBtn.hide();
    }

    if (!lesson) return;

    if (!lesson.lesson_name) {
      /* eslint-disable-next-line no-console */
      console.trace('object doesnt has lesson_name property');
      return;
    }

    if (!lesson.lesson_id) {
      /* eslint-disable-next-line no-console */
      console.trace('object doesnt has lesson_id property');
      return;
    }

    $currentLesson.html(lesson.lesson_name);
    $currentLesson.attr(currentLessonIdAttrName, lesson.lesson_id);
    $currentLesson.attr(currentLessonCourseIdAttrName, lesson.course_id);
    $currentLessonDetailsBtn.show();
  };

  const updateLessonDetailsOnUI = (lesson) => {
    if (!lesson) {
      $parentReportsBottomLoader.hide();
      /* eslint-disable-next-line no-console */
      console.trace('lesson argument is required');
      return;
    }

    if (!Array.isArray(lesson.tasks)) {
      $parentReportsBottomLoader.hide();
      /* eslint-disable-next-line no-console */
      console.trace('lesson.tasks should be an array ');
    }

    $lessonDetailsList.html('');
    $lessonDetailsLessonName.html(lesson.title);

    if (!lesson.tasks.length) {
      $lessonDetailsList.html('No Activities for this lesson. We are working on it.');
      $bottomBlock.hide();
      $lessonDetailsBlock.show();
      $parentReportsBottomLoader.hide();
      return;
    }

    lesson.tasks.forEach((task) => {
      $lessonDetailsList.append(
        `<div class="parent-reports__lesson-details-list-item">
          <div class="parent-reports__lesson-details-list-item-left">
            <img src="${task.image}"
                 class="parent-reports__lesson-details-list-item-img"
                 alt="${task.title}">
          </div>
          <div class="parent-reports__lesson-details-list-item-right">
            <div class="parent-reports__lesson-details-list-item-type">${task.type}</div>
            <div class="parent-reports__lesson-details-list-item-title">${task.title}</div>
            <div class="parent-reports__lesson-details-list-item-description">
              ${task.description}
            </div>
          </div>
        </div>`);
    });

    $lessonDetailsBlock.show();
    $bottomBlock.hide();
    $parentReportsBottomLoader.hide();
  };

  const setLessonDetailsToUI = (lessonId, courseId) => {
    if (lessonId === undefined) {
      /* eslint-disable-next-line no-console */
      console.trace('lessonId as argument is required');
      return;
    }

    if (courseId === undefined) {
      /* eslint-disable-next-line no-console */
      console.trace('courseId as argument is required');
      return;
    }

    $parentReportsBottomLoader.show();

    if (course && course.id === parseInt(courseId)) {
      const lesson = findLessonInCourse(course, lessonId);
      updateLessonDetailsOnUI(lesson);
      return;
    }

    $.ajax({
      url: ENDPOINT.GET_COURSE_DETAILS(courseId),
      type: 'GET',
    }).then((response) => {
        course = response;
        const lesson = findLessonInCourse(course, lessonId);
        if (!lesson) {
          /* eslint-disable-next-line no-console */
          console.trace(`Can\'t find lesson with ${lessonId} lesson id`);
          $parentReportsBottomLoader.hide();
          return;
        }

        updateLessonDetailsOnUI(lesson);
      }, (error) => {
        $parentReportsBottomLoader.hide();
        const errorText = getError(error);
        toastr.warning(errorText);
      });
  };

  const findLessonInCourse = (course, lessonId) => {
    let result = {};

    course.units.forEach((unit) => {
      unit.topics.forEach((topic) => {
        topic.lessons.forEach((lesson) => {
          if (lesson.id === parseInt(lessonId)) result = lesson;
        });
      });
    });

    return result;
  };

  const getDetailedBarChartData = (categoryIdsArray, periodName='week', rowData = []) => {
    let chartLabelsArray = [];

    if (!Array.isArray(categoryIdsArray)) {
      /* eslint-disable-next-line no-console */
      console.trace('categoryIdsArray as argument is required!');
      return {};
    }

    if (!rowData.length) {
      if (periodName === 'week') {
        rowData = kidData.weekly_studying_time_spent_by_category;
      }

      if (periodName === 'month') {
        rowData = kidData.monthly_studying_time_spent_by_category;
      }
    }

    const prepareData = (arr, periodName) => {
      const elapsedTimeArr = arr.map(item => {
        const roundedToMinutes = roundSecondsInMinutes(item.elapsed_time);
        totalMinutesSpent += roundedToMinutes;
        return roundedToMinutes;
      });

      chartLabelsArray = arr.map(item => {
        const date = new Date(item.date);
        if (periodName === 'month') return MONTH_NAMES[date.getMonth()];
        if (periodName === 'week')  return `${MONTH_NAMES[date.getMonth()]} ${date.getDate()}`;
      });


      return {
        rowData: arr,
        elapsedTimeArr,
        totalMinutesSpent,
        chartLabelsArray
      };
    };

    const filteredByCategories = [];
    let totalMinutesSpent = 0;

    rowData.forEach(item => {
      categoryIdsArray.forEach((categoryId) => {
        if (item.category_id === parseInt(categoryId)) filteredByCategories.push(item);
      });
    });

    if (filteredByCategories.length === DETAILED_CHART_PERIOD_COUNT) {
      const result = prepareData(filteredByCategories, periodName);
      return result;
    }

    const combinedByDatesList = [];
    filteredByCategories.forEach((item, index) => {
      if (index === filteredByCategories.length - 1) return;
      if (item.date === filteredByCategories[index + 1].date) {
        const combinedItem = {
          date: item.date,
          elapsed_time: filteredByCategories[index + 1].elapsed_time + item.elapsed_time
        };
        combinedByDatesList.push(combinedItem);
      }
    });

    const result = prepareData(combinedByDatesList, periodName);
    return result;
  };

  const resetDetailedChartView = () => {
    $(detailedChartPeriodBtn).removeClass(activeClassName);
    $(`[${detailedChartPeriodAttrName}="${PERIODS.WEEK}"]`).addClass(activeClassName);
  };

  const generateQueryParamsString = (params) => {
    const esc = encodeURIComponent;
    const query = Object.keys(params).map(key => esc(key) + '=' + esc(params[key])).join('&');

    return `?${query}`;
  };

  const getPeriodNameByChartType = (chartType) => {
    if (!chartType) {
      /* eslint-disable-next-line no-console */
      console.trace('chartType argument is required');
      return;
    }

    if (chartType === OVERVIEW_CHART) return PERIODS.DAY;

    if (chartType === DETAILED_CHART) {
      const selectedChartNavBtn = $(`[${detailedChartPeriodAttrName}].${activeClassName}`);
      const detailedChartType = selectedChartNavBtn.attr(detailedChartPeriodAttrName);

      if (detailedChartType === PERIODS.WEEK) return PERIODS.WEEK;
      if (detailedChartType === PERIODS.MONTH) return PERIODS.MONTH;

      /* eslint-disable-next-line no-console */
      console.trace('detailedChartType value is invalid');
    }

    /* eslint-disable-next-line no-console */
    console.trace('chartType argument value is invalid');
  };

  const getDateQueryParam = (chartType, pagingDirection, $chartWrapper) => {
    const $chartPagingDataElem = $chartWrapper.find(chartPagingDateElem);
    const currentPeriodStart = $chartPagingDataElem.attr(currentPeriodStartAttrName);
    const date = new Date(currentPeriodStart);
    let newDate = moment();
    let method = '';

    if (pagingDirection === 'prev') {
      method = 'subtract';
    } else if (pagingDirection === 'next') {
      method = 'add';
    }

    if (chartType === OVERVIEW_CHART) {
      newDate = moment(date)[method](7, 'days');
    }

    if (chartType === DETAILED_CHART) {
      const selectedChartNavBtn = $(`[${detailedChartPeriodAttrName}].${activeClassName}`);
      const detailedChartType = selectedChartNavBtn.attr(detailedChartPeriodAttrName);

      if (detailedChartType === PERIODS.WEEK) {
        newDate = moment(date)[method](DETAILED_CHART_PERIOD_COUNT, 'week');
      }

      if (detailedChartType === PERIODS.MONTH) {
        newDate = moment(date)[method](DETAILED_CHART_PERIOD_COUNT, 'months');
      }
    }

    return newDate.format('YYYY-MM-DD');
  };

  const setDatePeriodToChartPaging = (timeArray, $chartWrapper) => {
    if (!Array.isArray(timeArray)) {
      /* eslint-disable-next-line no-console */
      console.trace('timeArray as argument is required!');
      return moment(new Date()).format('MMM DD YYYY');
    }

    if (!timeArray.length) {
      /* eslint-disable-next-line no-console */
      console.trace('timeArray should have elements');
      return moment(new Date()).format('MMM DD YYYY');
    }

    const startDate = moment(new Date(timeArray[0].date)).format('MMM DD YYYY');
    // eslint-disable-next-line max-len
    const endDate = moment(new Date(timeArray[timeArray.length - 1].date)).format('MMM DD YYYY');
    const datePeriodStr = `${startDate} - ${endDate}`;

    const $chartPagingDateElem = $chartWrapper.find(chartPagingDateElem);
    $chartPagingDateElem.html(datePeriodStr);
    $chartPagingDateElem.attr(currentPeriodStartAttrName, startDate);
  };

  const getPeriodTypeByChartType = (periodType) => {
    if (periodType === OVERVIEW_CHART) {
      return OVERVIEW_CHART_PERIOD_COUNT;
    }

    return DETAILED_CHART_PERIOD_COUNT;
  };

  const handlePagingButtonsVisibility = (timeArray, $chartWrapper) => {
    const $prevBtn = $chartWrapper.find(chartPagingPrevBtn);
    const $nextBtn = $chartWrapper.find(chartPagingNextBtn);

    const dateJoined = moment(new Date(kidData.user.date_joined));
    const firstDate = moment(new Date(timeArray[0].date));
    const lastDate = moment(new Date(timeArray[timeArray.length - 1].date));

    if (moment(firstDate).isBefore(dateJoined) || moment(firstDate).isSame(dateJoined)) {
      $prevBtn.hide();
    } else {
      $prevBtn.show();
    }

    if (moment(lastDate).isAfter(moment(new Date()))) {
      $nextBtn.hide();
    } else {
      $nextBtn.show();
    }
  };

  const handleChartPagingChange = (pagingDirection, $chartWrapper) => {
    const chartType = $chartWrapper.attr(chartTypeAttrName);

    const $chartLoader = $chartWrapper.find(chartLoader);
    $chartLoader.show();

    const categoriesIdsArray = getCategoriesIdArrayByActiveSubjectTitle(chartType);
    const periodName = getPeriodNameByChartType(chartType);
    const period = getPeriodTypeByChartType(chartType);

    const currentDate = getDateQueryParam(chartType, pagingDirection, $chartWrapper);

    const queryParams = {
      from_date: currentDate,
      period: periodName,
      period_count: period,
      category_ids: categoriesIdsArray.length ? categoriesIdsArray.join(',') : '',
      tz_offset: new Date().getTimezoneOffset() * 60
    };

    const params = generateQueryParamsString(queryParams);
    const kidId = getSelectedKidId();

    $.ajax({
      url: ENDPOINT.GET_KID_REPORT_DATA(params, kidId),
      type: 'GET',
    }).then((response) => {
      $chartLoader.hide();

      // overview chart update
      if (chartType === OVERVIEW_CHART) {
        initWeekOverviewBarChart(response.studying_time_spent);
        handlePagingButtonsVisibility(response.studying_time_spent, $chartWrapper);
        return;
      }

      // detailed chart update
      const {
        elapsedTimeArr,
        totalMinutesSpent,
        chartLabelsArray
      } = getDetailedBarChartData(categoriesIdsArray, periodName, response.studying_time_spent);
      initDetailedBarChart(elapsedTimeArr, chartLabelsArray);
      $detailedChartGamePlayMin.html(`${totalMinutesSpent} min`);
      setDatePeriodToChartPaging(response.studying_time_spent, $chartWrapper);
      handlePagingButtonsVisibility(response.studying_time_spent, $chartWrapper);
    }, (error) => {
      const errorText = getError(error);
      toastr.warning(errorText);
      $chartLoader.hide();
    });
  };


  $(document)
    // click on subject card
    .on('click', subjectCard, (e) => {
      const $currentSubject = $(e.currentTarget);
      const subjectName = $currentSubject.attr(subjectTitleAttrName);

      $(subjectCard).addClass('_grades-expanded');
      $(subjectCard).removeClass(activeClassName);

      $currentSubject.addClass(activeClassName);
      $currentSubject.removeClass('_grades-expanded');

      $gradeCards.removeClass(activeClassName);
      $gradeCards.removeClass(gradeSelectedClassName);

      const categoriesIdsArray = getCategoriesIdArrayByActiveSubjectTitle(DETAILED_CHART);
      const gradesIdsArray = getGradesIdArrayBySubjectTitle(subjectName);

      // reset overall progress block title
      updateOverallProgressTitle();

      // toggle report blocks visibility
      $progressBlock.hide();
      $starsBlock.hide();
      $overallBlock.show();
      $breadcrumbsBlock.show();
      $breadcrumbsItem.html(subjectName);

      // grades donuts charts init
      initGradesDonutCharts(gradesIdsArray, categoriesIdsArray);

      // overall progress bar init
      const chartData = getOverallProgressBarChartData(categoriesIdsArray, gradesIdsArray);
      initOverallProgressBar(chartData.completedCount, chartData.totalCount);

      // update overall progress block
      completedCountChartText.html(chartData.completedCount);
      totalCountChartText.html(chartData.totalCount);

      // init detailed bar chart
      const {
        elapsedTimeArr,
        totalMinutesSpent,
        chartLabelsArray,
        rowData
      } = getDetailedBarChartData(categoriesIdsArray);

      initDetailedBarChart(elapsedTimeArr, chartLabelsArray);
      handlePagingButtonsVisibility(rowData, $detailedChartBlock);
      $detailedChartGamePlayMin.html(`${totalMinutesSpent} min`);

      // set paging initial period date
      setDatePeriodToChartPaging(rowData, $detailedChartBlock);

      // set current lesson to UI
      const currentLessonObj = getCurrentLesson();
      setCurrentLessonToUI(currentLessonObj);

      // set prev lessons to UI
      const previousLessonsArray = getPreviousLessons();
      setPreviousLessonsToUI(previousLessonsArray);

      $detailedChartBlock.show();
      $notStartedLessonsBlock.hide();
      $bottomBlock.removeClass(bottomBlockModifierClassName);

      resetDetailedChartView();

      $bottomBlock.show();
      $lessonDetailsBlock.hide();
    })
    // account settings report nav btn click
    .on('click', reportMainNavBtn, () => {
      toggleReportsNavBtns();

      // reset kid report data view to default
      $(breadcrumbsBack).click();
    })
    // account settings nav, kid nav btn click
    .on('click', goToChildDataBtn, (e) => {
      const $currentBtn = $(e.target);

      getReportDataFromServer();
      toggleReportsNavBtns($currentBtn);

      // reset kid report data view to default
      $(breadcrumbsBack).click();
    })
    // go to parent report overview
    .on('click', breadcrumbsBack, () => {
      $progressBlock.show();
      $overallBlock.hide();
      $gradesBlock.hide();
      $breadcrumbsBlock.hide();
      $breadcrumbsItem.html('');
      $starsBlock.show();

      // reset subject selection
      $(subjectCard).removeClass('_grades-expanded');
      $(subjectCard).removeClass(activeClassName);

      // reset overall progress block title
      updateOverallProgressTitle();

      resetDetailedChartView();
    })
    // detailed chart period click
    .on('click', detailedChartPeriodBtn, (e) => {
      const $currentPeriodBtn = $(e.currentTarget);
      const period = $currentPeriodBtn.attr(detailedChartPeriodAttrName);

      $(detailedChartPeriodBtn).removeClass(activeClassName);
      $currentPeriodBtn.addClass(activeClassName);

      const categoriesIdsArray = getCategoriesIdArrayByActiveSubjectTitle(DETAILED_CHART);

      const {
        elapsedTimeArr,
        totalMinutesSpent,
        chartLabelsArray,
        rowData
      } = getDetailedBarChartData(categoriesIdsArray, period);

      initDetailedBarChart(elapsedTimeArr, chartLabelsArray);
      $detailedChartGamePlayMin.html(`${totalMinutesSpent} min`);

      // set paging initial period date
      setDatePeriodToChartPaging(rowData, $detailedChartBlock);
    })
    // show current lesson details
    .on('click', currentLessonDetailsBtn, () => {
      const lessonId = $currentLesson.attr(currentLessonIdAttrName);
      const courseId = $currentLesson.attr(currentLessonCourseIdAttrName);
      setLessonDetailsToUI(lessonId, courseId);
    })
    // show prev lesson details
    .on('click', prevLessonItem, (e) => {
      const $currentLesson = $(e.currentTarget);
      const lessonId = $currentLesson.attr(prevLessonIdAttrName);
      const courseId = $currentLesson.attr(prevLessonCourseIdAttrName);
      setLessonDetailsToUI(lessonId, courseId);
    })
    // show not started lesson details
    .on('click', notStartedLessonItem, (e) => {
      const $currentLesson = $(e.currentTarget);
      const lessonId = $currentLesson.attr(notStartedLessonIdAttrName);
      const courseId = $currentLesson.attr(notStartedLessonCourseIdAttrName);
      setLessonDetailsToUI(lessonId, courseId);
    })
    // hide lesson details block
    .on('click', lessonDetailsCloseBtn, () => {
      $lessonDetailsBlock.hide();
      $bottomBlock.show();
    })
    // click on grade card
    .on('click', `[${gradeCard}]`, (e) => {
      const $currentGrade = $(e.currentTarget);
      const currentGradeName = $(e.currentTarget).find(gradeName).html();

      updateOverallProgressTitle(`${currentGradeName} Progress`);

      $gradeCards.removeClass(activeClassName);
      $gradeCards.addClass(gradeSelectedClassName);
      $currentGrade.addClass(activeClassName);
      $currentGrade.removeClass(gradeSelectedClassName);

      const gradeId = $currentGrade.attr(gradeIdAttrName);

      const categoriesIdsArray = getCategoriesIdArrayByActiveSubjectTitle(DETAILED_CHART);
      const gradesIdsArray = [gradeId];

      const chartData = getOverallProgressBarChartData(categoriesIdsArray, gradesIdsArray);
      initOverallProgressBar(chartData.completedCount, chartData.totalCount);

      completedCountChartText.html(chartData.completedCount);
      totalCountChartText.html(chartData.totalCount);

      const currentLessonObj = getCurrentLesson();
      setCurrentLessonToUI(currentLessonObj);

      const previousLessonsArray = getPreviousLessons();
      setPreviousLessonsToUI(previousLessonsArray);

      const notStartedLessons = getNotStartedLessons();
      setNotStartedLessonsToUI(notStartedLessons);

      $detailedChartBlock.hide();
      $notStartedLessonsBlock.show();
      $bottomBlock.addClass(bottomBlockModifierClassName);

      $lessonDetailsBlock.hide();
      $bottomBlock.show();
    })
    // chart paging prev btn click
    .on('click', chartPagingPrevBtn, (e) => {
      const $chartWrapper = $(e.currentTarget).closest(`[${chartTypeAttrName}]`);
      handleChartPagingChange('prev', $chartWrapper);
    })
    // chart paging next btn click
    .on('click', chartPagingNextBtn, (e) => {
      const $chartWrapper = $(e.currentTarget).closest(`[${chartTypeAttrName}]`);
      handleChartPagingChange('next', $chartWrapper);
    });

  handleUrlHashOnPageLoad();
});
