import { createClassroom } from '../utils';

$(document).ready(function () {
  const $teachersPage = $('[data-selector="teachers-page"]');
  if (!$teachersPage.length) return;

  const openClassroom = '[data-selector="open-classroom-btn"]';

  $(document)
    .on('click', openClassroom, (e) => {
      const utm = $(e.target).attr('data-utm');
      createClassroom({utm});
    });

  const $teachersCarousel = $('.teachers-carousel');

  if ($teachersCarousel && $teachersCarousel.length) {
    $teachersCarousel.not('.slick-initialized').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      swipeToSlide: true,
      adaptiveHeight: true,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },{
        breakpoint: 550,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }

  const $reviewsCarousel = $('[data-selector="teacher-reviews-slider"]');

  if ($reviewsCarousel && $reviewsCarousel.length) {
    $reviewsCarousel.not('.slick-initialized').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      swipeToSlide: true,
      adaptiveHeight: true,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 550,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }
});
