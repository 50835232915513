import {
  toggleButtonLoading,
  braintreeDropinConfig,
  threeDSecureConfig,
  isThreeDSecureCanceled
} from '../utils';
import {
  CUSTOM_MESSAGE,
  ENDPOINT,
} from '../config';
import {handleDropinToken} from '../payment/subscription.utils';

$(document).ready(() => {
  const $invoicePayment = $('[data-selector="invoice-payment"]');
  if (!$invoicePayment.length) return;

  const invoiceDropinContainer = '[data-selector="invoice-dropin-container"]';
  const $invoiceDropinContainer = $(invoiceDropinContainer);
  const $paymentError = $('[data-selector="invoice-payment-error"]');
  const $paymentSuccess = $('[data-selector="invoice-payment-success"]');
  const $invoiceDetails = $('[data-selector="invoice-details"]');
  const $purchaseBtn = $('[data-selector="invoice-payment-purchase-btn"]');
  const $invoiceLink = $('[data-selector="invoice-link"]');


  const braintreeDropinInit = () => {
    const invoicePrice = window.invoice_amount;
    const braintreeConf = braintreeDropinConfig(invoicePrice, invoiceDropinContainer);

    $invoiceDropinContainer.innerHTML = '';

    braintree.dropin.create(braintreeConf, (createErr, instance) => {
      if (createErr) {
        /*
        An error in the create call is likely due to incorrect configuration
        values or network issues. An appropriate error will be shown in the UI.
        */
        /* eslint-disable no-console */
        console.error(createErr);
        return;
      }

      if (instance.isPaymentMethodRequestable()) {
        // This will be true if you generated the client token
        // with a customer ID and there is a saved payment method
        // available to tokenize with that customer.
        $paymentError.hide();
        $purchaseBtn.show();
      }

      instance.on('paymentMethodRequestable', () => {
        $purchaseBtn.show();
        $paymentError.hide();
      });
      instance.on('noPaymentMethodRequestable', () => {
        $purchaseBtn.hide();
        $paymentError.hide();
      });
      instance.on('paymentOptionSelected', (event) => {
        event.paymentOption === 'card' ? $purchaseBtn.show() : $purchaseBtn.hide();
        $paymentError.hide();
      });

      $purchaseBtn.click(() => {
        const priceFor3ds = (parseInt(invoicePrice) / 100).toFixed(2);
        const threeDSecureConf = threeDSecureConfig(priceFor3ds);

        toggleButtonLoading($purchaseBtn, true);

        instance.requestPaymentMethod({
          threeDSecure: threeDSecureConf
        }, (requestPaymentMethodErr, payload) => {
          if (requestPaymentMethodErr) {
            $paymentError.text(requestPaymentMethodErr.message);
            $paymentError.show();

            toggleButtonLoading($purchaseBtn, false);
            return;
          }

          if (isThreeDSecureCanceled(payload)) {
            instance.clearSelectedPaymentMethod();
            $paymentError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
            $paymentError.show();
            toggleButtonLoading($purchaseBtn, false);
            return;
          }

          $.ajax({
            url: ENDPOINT.INVOICE_PAYMENT,
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              invoice_slug: window.invoice_slug,
              payment_method_nonce: payload.nonce,
            })
          }).then(() => {
            toggleButtonLoading($purchaseBtn, false);

            $paymentSuccess.show();
            $invoiceDropinContainer.hide();
            $invoiceDetails.hide();
            $purchaseBtn.hide();
            $invoiceLink.hide();
          }, (errorResponse) => {
            instance.clearSelectedPaymentMethod();
            toggleButtonLoading($purchaseBtn, false);

            const error = JSON.parse(errorResponse.responseText);
            $paymentError.html(error.error);
            $paymentError.show();
          });
        });
      });
    });
  };

  handleDropinToken(() => {
    braintreeDropinInit();
  }, () => {});
});
