import {
  toggleButtonLoading,
  braintreeDropinConfig,
  threeDSecureConfig,
  scrollToTop,
  isThreeDSecureCanceled,
} from '../utils';
import {
  CURRENCY,
  CUSTOM_MESSAGE,
  ENDPOINT,
  FEEDBACK_TYPES,
} from '../config';
import {handleDropinToken} from '../payment/subscription.utils';


$(document).ready(() => {
  const $schoolPlansForm = $('[data-selector="school-plans-form"]');
  if (!$schoolPlansForm.length) return;

  const dropInContainer = '#dropin-container';
  const dropInContainerSelector = '#subscription-plans-dropin-container';

  const $pageTitle = $('[data-selector="page-title"]');

  // quote modal form items
  const schoolQuoteForm = '[data-selector="school-quote-request-form"]';
  const quoteTypeSelect = '[data-selector="quote-type-select"]';
  const schoolCountInput = '[data-selector="school-count-input"]';
  const schoolCountItem = '[data-selector="school-count-item"]';
  const $quoteForm = $(schoolQuoteForm);

  const plansStep = '[data-selector="plans-step"]';
  const paymentStep = '[data-selector="payment-step"]';
  const $plansStep = $(plansStep);
  const $paymentStep = $(paymentStep);

  // coupon code
  let activeCoupon = null;
  let amountOff = null;
  let excludedSubscriptions = [];

  let isSpecialCouponApplied = false;
  let specialCouponTrialDuration = null;
  const $couponDoneContainer = $('[data-selector="school-plans-coupon-row"]');
  const $couponDone = $('[data-selector="coupon-value"]');

  const couponError = '[data-selector="coupon-error"]';
  const couponMessage = '[data-selector="coupon-message"]';
  const $couponMessage = $(couponMessage);
  const $couponError = $(couponError);
  const $couponBlock = $('[data-selector="coupon-block"]');
  const couponForm = '[data-selector="coupon-form"]';
  const $couponForm = $(couponForm);
  const couponInput = '[data-selector="coupon-input"]';
  const $couponInput = $(couponInput);
  const enterCouponBtn = '[data-selector="enter-coupon-btn"]';
  const $enterCouponBtn = $(enterCouponBtn);
  const removeCouponBtn = '[data-selector="remove-coupon-btn"]';
  const $removeCouponBtn = $(removeCouponBtn);
  const applyCouponBtn = '[data-selector="apply-coupon-btn"]';
  const $applyCouponBtn = $(applyCouponBtn);

  const $licenseActivationCodeModal = $('#licenseActivationCode');

  // modals
  const quoteModal = '#getQuoteModal';
  const $quoteModal = $(quoteModal);
  const $quoteSuccessModal = $('#quoteModalSuccess');
  const $quoteErrorModal = $('#quoteModalError');
  const schoolQuoteSubmitBtn = '[data-selector="school-quote-request-submit"]';

  //buttons
  const goToPaymentBtn = '[data-selector="go-to-payment-btn"]';
  const $goToPaymentBtn = $(goToPaymentBtn);
  const buySubscriptionBtn = '[data-selector="buy-subscription-btn"]';
  const backToPlansBtn = '[data-selector="back-to-plans-btn"]';
  const $buySubscriptionBtn = $(buySubscriptionBtn);
  const $backToPlansBtn = $(backToPlansBtn);

  //subscription plans
  const planInfo = '[data-selector="plan-info"]';
  const planPrice = '[data-selector="plan-price"]';

  const studentsAttr = 'data-students';
  const priceAttr = 'data-price';
  const planInput = '[data-selector="subscription-plan"]';
  const selectedPlanInput = '[data-selector="subscription-plan"]:checked';

  const $error = $('#error');

  // email sent block
  const $emailSentBlock = $('[data-selector="email-sent-block"]');
  const gotItBtn = '[data-selector="got-it-btn"]';

  const isQuoteFormValid = () => {
    const fields = $quoteForm.find('input:not(:disabled)');
    const $error = $quoteForm.find('#error');
    let isFormValid = true;

    fields.each((index, input) => {
      if (input.value.length < 1) {
        $error.show();
        isFormValid = false;
        return isFormValid;
      } else {
        $error.hide();
      }
    });

    return isFormValid;
  };

  const showEmailSentBlock = () => {
    $pageTitle.html('Almost there...');
    $schoolPlansForm.hide();
    $emailSentBlock.show();
  };

  const checkCoupon = () => {
    if (!activeCoupon) return;

    const planSlug = $(selectedPlanInput).attr('data-slug');
    const itemIndex = excludedSubscriptions.indexOf(planSlug);
    const isCouponNotAllowed = excludedSubscriptions[itemIndex];

    if (isCouponNotAllowed) {
      $goToPaymentBtn.prop('disabled', true);
      $couponError.html('The coupon is not applicable to this plan. ');
      $couponError.show();
      $couponMessage.hide();
      return;
    }

    $goToPaymentBtn.prop('disabled', false);
    $couponError.html('');
    $couponError.hide();
    $couponMessage.show();
  };

  const applyCouponFromUrl = () => {
    const url = new URL(window.location.href);
    const couponValue = url.searchParams.get('discount');

    if (couponValue) {
      $couponBlock.show();
      $couponInput.val(couponValue);

      $enterCouponBtn.text('Disable coupon');
      $couponBlock.addClass('active');
      $couponBlock.show();
    }
  };

  const disableCoupon = () => {
    $couponInput.val('');
    $couponInput.prop('disabled', false);
    activeCoupon = null;
    $removeCouponBtn.hide();
    $goToPaymentBtn.prop('disabled', false);
    $applyCouponBtn.css('display', 'inline-block');
    $couponMessage.hide();
    $couponError.hide();
    amountOff = null;
    excludedSubscriptions = [];
  };

  const setSelectedPlanInfo = () => {
    const $selectedPlan = $(selectedPlanInput);
    const selectedPlanStudents = parseInt($selectedPlan.attr(studentsAttr));  // 10 - number
    const selectedPlanPrice = parseInt($selectedPlan.attr(priceAttr));  // 10.00 - number

    const $planInfo = $(planInfo);
    const $planPrice = $(planPrice);

    const newPrice = (selectedPlanPrice - amountOff * 100) / 100;

    const formattedSelectedPlanPrice = (selectedPlanPrice / 100).toFixed(2);
    const formattedNewPrice = newPrice.toFixed(2);

    if (isSpecialCouponApplied) {
      $couponDoneContainer.show();
      $couponDone.html(activeCoupon);
    } else $couponDoneContainer.hide();

    /* eslint-disable max-len */
    const selectedPlan = `<span style="text-decoration: line-through">${formattedSelectedPlanPrice}</span>`;

    if (window.isTrial) {
      if (activeCoupon) {
        /* eslint-disable max-len */
        $planPrice.html(`${CURRENCY}0 (` +
          (formattedSelectedPlanPrice === formattedNewPrice ? '' : `${CURRENCY}${selectedPlan} `)
          + `${CURRENCY}${formattedNewPrice} on the first year after free trial)`);
      } else {
        $planPrice.html(`${CURRENCY}0 (${CURRENCY}${formattedSelectedPlanPrice}/year after free trial)`);
      }
    } else {
      if (activeCoupon) {
        /* eslint-disable max-len */
        $planPrice.html(`${CURRENCY}0 (` +
          (formattedSelectedPlanPrice === formattedNewPrice ? '' : `${CURRENCY}${selectedPlan} `)
          + `${CURRENCY}${formattedNewPrice} on the first year)`);
      } else {
        $planPrice.html(`${CURRENCY}${formattedSelectedPlanPrice}/year`);
      }
    }

    if (selectedPlanStudents > 1) {
      $planInfo.html('Classroom license: ' + selectedPlanStudents + ' students');
      return;
    }

    $planInfo.html('Classroom license: ' + selectedPlanStudents + ' student');
  };

  const handleError = (errorResponse) => {
    const error = JSON.parse(errorResponse.responseText);

    $error.text(error.error);
    $error.show();
  };

  const braintreeDropinInit = () => {
    toggleButtonLoading($goToPaymentBtn, false);

    scrollToTop();
    setSelectedPlanInfo();

    $plansStep.hide();
    $paymentStep.show();

    const $selectedPlan = $(selectedPlanInput);
    const text = $selectedPlan.attr('data-students') + ' students';
    ga('send', 'event', 'Subscription', 'Initiated', text);

    let planPrice = $selectedPlan.attr(priceAttr);
    const braintreeConf = braintreeDropinConfig(planPrice, dropInContainerSelector);

    /* eslint-disable max-len */
    if (activeCoupon) planPrice = ((parseInt(planPrice) / 100).toFixed(2) - amountOff).toFixed(2);

    $(dropInContainer).innerHTML = '';

    braintree.dropin.create(braintreeConf, function (createErr, instance) {
      if (createErr) {
        /*
        An error in the create call is likely due to incorrect configuration
        values or network issues. An appropriate error will be shown in the UI.
        */
        /* eslint-disable no-console */
        console.error(createErr);
        return;
      }

      if (instance.isPaymentMethodRequestable()) {
        // This will be true if you generated the client token
        // with a customer ID and there is a saved payment method
        // available to tokenize with that customer.
        $buySubscriptionBtn.show();
      }
      instance.on('paymentMethodRequestable', () => {
        $buySubscriptionBtn.show();
      });
      instance.on('noPaymentMethodRequestable', () => {
        $buySubscriptionBtn.hide();
      });
      instance.on('paymentOptionSelected', (event) => {
        event.paymentOption === 'card' ? $buySubscriptionBtn.show() : $buySubscriptionBtn.hide();
      });

      $buySubscriptionBtn.click((e) => {
        const hiddenInputVal = $('[data-selector="buy-teacher-subscription-special-input"]').val();
        if (hiddenInputVal) return;

        const $button = $(e.currentTarget);
        const threeDSecureConf = threeDSecureConfig((parseInt(planPrice) / 100).toFixed(2).toString());

        $backToPlansBtn.hide();

        toggleButtonLoading($button, true);

        instance.requestPaymentMethod({
          threeDSecure: threeDSecureConf
        }, (requestPaymentMethodErr, payload) => {
          if (requestPaymentMethodErr) {
            $error.text(requestPaymentMethodErr.message);
            $error.show();

            toggleButtonLoading($button, false);
            return;
          }

          if (isThreeDSecureCanceled(payload)) {
            instance.clearSelectedPaymentMethod();
            $error.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
            $error.show();
            toggleButtonLoading($button, false);
            $backToPlansBtn.show();
            return;
          }

          const data = {
            platform: 4, // braintree
            plan_id: $(selectedPlanInput).val(),
            payment_method: 1,  // pay by card
            quantity: 1,
            discount_id: activeCoupon,
            platform_params: {
              payment_method_nonce: payload.nonce
            },
          };

          $.ajax({
            url: ENDPOINT.LMS_SUBSCRIPTION,
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(data)
          }).then(() => {
            ga('send', 'event', 'Trial', 'Started', 'Teacher');
            showEmailSentBlock();

            // TODO detect is android or iOS >>> redirect to apps page
          }, (errorResponse) => {
            instance.clearSelectedPaymentMethod();
            handleError(errorResponse);
            toggleButtonLoading($button, false);
          });
        });
      });
    });
  };

  const clearFields = () => {
    const $form = $(schoolQuoteForm);

    const $inputs = $form.find('input');
    const $selects = $form.find('select');

    $selects.val('');
    $inputs.val('');
  };

  const hideModal = () => {
    $quoteModal.modal('hide');
    clearFields();
  };

  const showSuccessModal = () => {
    $quoteSuccessModal.modal('show');
  };

  const showErrorModal = () => {
    $quoteErrorModal.modal('show');
  };

  $quoteModal.on('hidden.bs.modal', () => {
    $quoteForm.find('#error').hide();
  });

  const initReviewsSlider = () => {
    const $teacherReviewsSlider = $('[data-selector="teacher-reviews-slider"]');

    if ($teacherReviewsSlider && $teacherReviewsSlider.length) {
      $teacherReviewsSlider.not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        swipeToSlide: true,
        adaptiveHeight: true,
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 2
          }
        }, {
          breakpoint: 550,
          settings: {
            slidesToShow: 1
          }
        }]
      });
    }
  };

  const toggleApplyCouponBtn = () => {
    const emptyCouponClass = '_empty';

    if ($couponInput.val()) {
      $applyCouponBtn.removeClass(emptyCouponClass);
      return;
    }

    $applyCouponBtn.addClass(emptyCouponClass);
  };

  const setTrialNotesText = (trialDuration) => {
    const isTrial = window.isTrial;
    const user = window.user.first_name;
    if (isSpecialCouponApplied) {
      $goToPaymentBtn.html(`Try free for ${trialDuration} days`);
      $pageTitle.html(`Start your ${trialDuration}-days trial, ${user}!`);
      $buySubscriptionBtn.html(`Try free for ${trialDuration} days`);
    } else if (isTrial) {
      $goToPaymentBtn.html('Try free for 30 days');
      $pageTitle.html(`Start your 30-day free trial, ${user}!`);
      $buySubscriptionBtn.html('Try free for 30 days');
    } else {
      $goToPaymentBtn.html('Next');
      $pageTitle.html(`Start your membership, ${user}!`);
      $buySubscriptionBtn.html('Subscribe');
    }
  };

  $(document)
    // submit get quote form
    .on('click', schoolQuoteSubmitBtn, (e) => {
      e.preventDefault();

      if (!isQuoteFormValid()) return;

      const $submitBtn = $(e.currentTarget);
      const type = 'Type: ' + $quoteForm.find(quoteTypeSelect).val() + '\n';
      const schoolCount = $quoteForm.find('#schools_count').val();
      const schoolCountString = schoolCount ? 'School count: ' + schoolCount + '\n' : '';
      const teachersCountString = `Teachers count: ${$quoteForm.find('#teachers_count').val()}\n`;
      const studentsCountString = `Students count: ${$quoteForm.find('#students_count').val()}\n`;
      const userPhoneNumber = `Phone: ${$quoteForm.attr('data-phone')}\n`;

      const data = {};
      data.subject = 'Teacher quote request';
      data.message = type
                   + schoolCountString
                   + teachersCountString
                   + studentsCountString
                   + userPhoneNumber + '';

      data.feedback_type = FEEDBACK_TYPES.QUOTE_REQUEST;
      data.time = new Date();

      toggleButtonLoading($(e.currentTarget), true);

      $.ajax({
        url: ENDPOINT.FEEDBACK,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data)
      })
        .then(() => {
          hideModal();
          toggleButtonLoading($submitBtn, false);
          showSuccessModal();
        }, () => {
          hideModal();
          toggleButtonLoading($submitBtn, false);
          showErrorModal();
        });
    })
    //change quote type district/school
    .on('change', quoteTypeSelect, (e) => {
      const value = e.currentTarget.value;
      const $schoolCountItem = $(schoolCountItem);
      const $schoolCountInput = $(schoolCountInput);

      if (value === 'district') {
        $schoolCountItem.show();
        $schoolCountInput.prop('disabled', false);
        return;
      }

      $schoolCountItem.hide();
      $schoolCountInput.val('');
      $schoolCountInput.prop('disabled', true);
    })
    // go to payment view
    .on('click', goToPaymentBtn, () => {
      toggleButtonLoading($goToPaymentBtn, true);

      handleDropinToken(braintreeDropinInit);
    })
    //back to select subscription plans view
    .on('click', backToPlansBtn, () => {
      $(dropInContainer).innerHTML = '';
      $paymentStep.hide();
      $plansStep.show();
    })
    // show/hide coupon block
    .on('click', enterCouponBtn, () => {
      if ($couponBlock.hasClass('active')) {
        $couponBlock.removeClass('active');
        $couponBlock.hide();
        $enterCouponBtn.text('Enter coupon code');
        disableCoupon();
        return;
      }

      $enterCouponBtn.text('Disable coupon');
      $couponBlock.addClass('active');
      $couponBlock.show();
    })
    // remove applied coupon
    .on('click', removeCouponBtn, (e) => {
      e.preventDefault();

      isSpecialCouponApplied = false;
      specialCouponTrialDuration = null;

      disableCoupon();
      setTrialNotesText();
    })
    // event fires on plan change to check is coupon allowed
    .on('click', planInput, () => {
      checkCoupon();
    })
    // email sent block got it btn click
    .on('click', gotItBtn, () => {
      window.location = '/p'; // go to lms
    })
    .on('input', couponInput, () => {
      toggleApplyCouponBtn();
    })
    // check is coupon valid, apply coupon
    .on('click', applyCouponBtn, (e) => {
      e.preventDefault();

      // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
      const hiddenInputVal = $('[data-selector="teacher-coupon-check-special-input"]').val();
      if (hiddenInputVal) return;

      const couponFormParsley = $couponForm.parsley();
      if (!couponFormParsley.isValid()) {
        couponFormParsley.validate();
        return;
      }

      const coupon = $couponInput.val().trim();
      const $applyBtn = $(e.currentTarget);

      if (coupon) {
        $couponError.hide();
        toggleButtonLoading($applyCouponBtn, true);

        // check is coupon a distributor code
        $.ajax({
          url: ENDPOINT.GIFT_CODE_VALIDATE,
          contentType: 'application/json',
          type: 'POST',
          data: JSON.stringify({
            code: coupon
          })
        }).then(() => {
          toggleButtonLoading($applyCouponBtn, false);
          $licenseActivationCodeModal.modal('show');
        }, () => {
          toggleButtonLoading($applyCouponBtn, false);
        });

        // validate coupon
        $.post(ENDPOINT.COUPON_VALIDATE, {
          coupon: coupon
        }).then((response) => {
          toggleButtonLoading($applyCouponBtn, false);
          $couponInput.parsley().removeError('coupon');

          activeCoupon = coupon;
          amountOff = response.amount_off;
          excludedSubscriptions = response.exclude_subscriptions;

          $couponError.hide();
          $applyCouponBtn.hide();
          $removeCouponBtn.css('display', 'inline-block');
          $applyBtn.hide();
          $couponInput.prop('disabled', true);
          $couponMessage.html(response.description);
          $couponMessage.css('display', 'inline-block');

          isSpecialCouponApplied = response.has_trial;
          specialCouponTrialDuration = response.trial_duration;

          setTrialNotesText(specialCouponTrialDuration);

        }, (response) => {
          toggleButtonLoading($applyCouponBtn, false);

          activeCoupon = null;
          $removeCouponBtn.hide();
          $couponMessage.empty();
          $couponMessage.hide();

          const errorObject = JSON.parse(response.responseText);
          if (errorObject.error) {
            $couponInput.parsley().removeError('coupon');
            $couponInput.parsley().addError('coupon', {
              message: errorObject.error
            });
          }
        });
      }
    });



  // init
  initReviewsSlider();
  applyCouponFromUrl();
  toggleApplyCouponBtn();
});
