import {
  dispatchCustomEvent,
  downloadWorksheetHandler,
  handlePdfDownload,
  initWorksheetDownload,
  storage,
  toggleMarkWorksheetAsDone
} from '../utils';
import {
  LOCAL_STORAGE_KEYS,
  MODALS,
  WEBSITE_CUSTOM_EVENTS,
  WORKSHEET_ACTION_TYPES
} from '../config';

$(document).ready(function () {

  // const IS_LOGGED_IN = window.isLoggedIn;

  const dropdown = '[data-selector="learning-essentials-dropdown"]';
  const dropdownSelected = '[data-selector="learning-essentials-dropdown-selected"]';
  const dropdownList = '[data-selector="learning-essentials-dropdown-list"]';
  const learningEssentialsBlock = '[data-selector="learning-essentials-details-block"]';

  const $videoContainer = $('[data-selector="youtube-container"]');

  const downloadWorksheetBtn = '[data-selector="download-worksheet"]';
  const onlineWorksheetBtn = '[data-selector="worksheet-online-btn"]';

  const activeClass = '_active';
  const activityItem = '[data-selector="activity-slider-item"]';
  const $activitySlider = $('[data-selector="activity-slider"]');
  const activityCounter = '[data-selector="activity-counter"]';
  const activityTitle = '[data-selector="activity-title"]';

  // worksheet popup
  const worksheetImg = '[data-selector="worksheet-img"]';
  const worksheetPopupImg = '[data-selector="worksheet-image-popup-img"]';
  const $worksheetPopupImg = $(worksheetPopupImg);
  const $worksheetPopupTitle = $('[data-selector="worksheet-image-popup-title"]');

   const $slidersLoader = $('[data-selector="sliders-loader"]');
   const activitySliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerPadding: '2%',
    adaptiveHeight: true,
    dots: true,
  };

  $activitySlider.on('init', (e) => {
    const $learningEssentialsBlock = $(e.currentTarget).closest(learningEssentialsBlock);
    $slidersLoader.hide();
    $learningEssentialsBlock.show();
    $activitySlider.show();
  });

  $activitySlider.on('afterChange', function(_, currentSlide){
    const $carousel = currentSlide.$slider.closest(learningEssentialsBlock);
    const $currentSlide = $carousel.find(activityItem).eq(currentSlide.currentSlide + 1);

    const title = $currentSlide.attr('data-name');
    $carousel.find(activityTitle).html(title);

    $carousel.find(activityCounter).html(currentSlide.currentSlide + 1);
  });

  $activitySlider.not('.slick-initialized').slick(activitySliderSettings);

  // videos
  function onYoutubeReady() {
    $videoContainer.each((index, container) => {
      const videoId = $(container).attr('data-id');
      new window.YT.Player(`youtube-video-${videoId}`, {
        width: '100%',
        height: '300',
        videoId: videoId,
        events: {
          onReady: () => {
            // event.target.playVideo();  // to play video uncomment
          }
        }
      });
    });
  }

  if (window.YT && window.YT.Player) {
    onYoutubeReady();
  } else {
    window.onYouTubeIframeAPIReady = onYoutubeReady;
  }

  // on page load we need to check is user allowed to download worksheet pdf
  const isCheckPdfStatus = storage.get(LOCAL_STORAGE_KEYS.CHECK_AVAILABILITY_OF_PDF, 'true');

  if (isCheckPdfStatus === 'true') {
    const worksheetId = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ID, true);
    const doDownload = () => {
      toggleMarkWorksheetAsDone(worksheetId, true);
      dispatchCustomEvent(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, {worksheetId});
    };

    handlePdfDownload(doDownload);
  }

  $(document)
    .on('click', (e) => {
      e.stopPropagation();
      if ($(e.target).closest(dropdown).length) return;

      $(dropdownSelected).removeClass(activeClass);
      $(dropdownList).removeClass(activeClass);
    })
    .on('click', dropdownSelected, () => {
      $(dropdownSelected).toggleClass(activeClass);
      $(dropdownList).toggleClass(activeClass);
    })
    .on('click', '[data-selector="add-free-modal-btn"]', () => {
      $('.modal').modal('hide');  // hide all modals
      $(MODALS.WEBSITE_PLANS_MODAL).modal('show');
    })
    // click on worksheet img
    .on('click', worksheetImg, (e) => {
      const $itemClicked = $(e.target);
      const worksheetId = $itemClicked.closest(activityItem).attr('data-id');
      const worksheetItemsList = $itemClicked.closest(learningEssentialsBlock).find(activityItem);
      const $currentWorksheetItem = worksheetItemsList.filter((index, item) => {
        return $(item).attr('data-id') === worksheetId;
      });

      const title = $currentWorksheetItem.attr('data-name');
      const imgSrc = $currentWorksheetItem.find(worksheetImg).attr('src');

      if (imgSrc) $worksheetPopupImg.attr('src', imgSrc);
      if (title) $worksheetPopupTitle.html(title);

      if (!imgSrc) return;
      $(MODALS.WORKSHEET_IMAGE_POPUP_ID(worksheetId)).modal('show');
    })
    .on('click', onlineWorksheetBtn, (e) => {
      e.preventDefault();
      downloadWorksheetHandler(e, WORKSHEET_ACTION_TYPES.COMPLETE_ONLINE);

      // const $item = $(e.currentTarget).closest(activityItem);
      // const slug = $item.attr('data-slug');
      // window.location = URLS.WORKSHEET_CHECK(slug);
    })
    .on('click', downloadWorksheetBtn, (e) => {
      e.preventDefault();
      downloadWorksheetHandler(e, WORKSHEET_ACTION_TYPES.DOWNLOAD);

      // const worksheetId = $(e.currentTarget).attr('data-id');
      // storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ID, worksheetId);
      // storage.set(LOCAL_STORAGE_KEYS.PLACEMENT, PLACEMENTS.WORKSHEET_LIST_PAGE);
      //
      // if (IS_LOGGED_IN) {
   //   storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION, WORKSHEET_ACTIONS.INIT_WORKSHEET_DOWNLOAD);
      //
      //   handlePdfDownload(() => {
      //     const worksheetId = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ID);
      //     dispatchCustomEvent(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, {worksheetId});
      //   });
      // }
      //
      // $('.modal').modal('hide');  // hide all modals
      //
      // const $websiteAccTypeModal = $(MODALS.ACCOUNT_TYPE_MODAL);
      // $websiteAccTypeModal.modal('show');
    });

  document
    .addEventListener(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, (e) => {
      const worksheetId = e.detail.worksheetId;
      const $worksheetItem = $(`${downloadWorksheetBtn}[data-id="${worksheetId}"]`);
      const pdfLink = $worksheetItem.attr('data-download-href');
      initWorksheetDownload(pdfLink);
    }, false);
});
