import {
  getError,
  toggleButtonLoading,
  handleCAPTCHAError,
  isBase64,
  fixPlusSymbolInProvidedEmail,
  getUrlParam,
  getUserSources
} from '../utils';
import {
  ENDPOINT,
  ACCOUNT_TYPES_STRING,
  ACCOUNT_TYPES,
  GET_PARAMS,
  LOCAL_STORAGE_KEYS,
  URLS
} from '../config';
import toastr from 'toastr';
import Qs from 'qs';

$(document).ready(function () {
  const $signUpPage = $('[data-selector="sign-up-page"]');
  if (!$signUpPage.length) return;

  let isDistributorCodeActivated = false;

  const teacherEmailLabel = 'Enter your work (school) email address:';
  const teacherEmailPlaceholder = 'e.g. lauren@school.edu';

  let selectedAccountType = ACCOUNT_TYPES.PARENT;
  let isGift = false;
  const hidden = '_hidden';

  // sign up tab content blocks
  const $signUpParentContent = $('[data-selector="sign-up-tab-parent"]');
  const $signUpTeacherContent = $('[data-selector="sign-up-tab-teacher"]');
  const $signUpDistrictContent = $('[data-selector="sign-up-tab-district"]');

  // sign up main selectors
  const $tabControlWrapper = $('[data-selector="tab-control-wrapper"]');
  const tabControl = '[data-selector="tab-control"]';
  const $tabControl = $signUpPage.find(tabControl);

  const emailForm = '[data-selector="sign-up-email-form"]';
  const emailInput = '[data-selector="sign-up-email-input"]';

  const $emailInput = $signUpPage.find(emailInput);
  const $teacherSocialLoginBtns = $signUpPage.find('[data-selector="teacher-social-login-btns"]');
  const $socialBtns = $signUpPage.find('[data-selector="social-btn-login"]');
  const $signUpMethodsSeparator = $signUpPage.find('[data-selector="sign-up-methods-separator"]');
  const $signUpLoginLink = $signUpPage.find('[data-selector="sign-up-login-link"]');

  // sign up parent block
  const signUpParentBtn = '[data-selector="sign-up-parent-btn"]';
  const $signUpParentBtn = $signUpPage.find(signUpParentBtn);
  const $signUpParentTitle = $('[data-selector="sign-up-parent-title"]');

  // sign up teacher email form elements
  const termsCheckbox = '[data-selector="terms-checkbox"]';
  const $signupParentError = $signUpPage.find('[data-selector="signup-parent-error"]');
  const $signupTeacherError = $signUpPage.find('[data-selector="signup-teacher-error"]');
  const $nextBtn = $signUpPage.find('[data-selector="sign-up-next-btn"]');
  const $emailLabel = $('[data-selector="sign-up-email-label"]');


  const onPageLoad = () => {
    if (!$signUpPage.length) return;
    const queryObject = Qs.parse(location.search.substr(1));

    // set parent tab title depends on get param
    const title = getUrlParam(GET_PARAMS.TITLE);
    if (title) $signUpParentTitle.html(title);

    // detect is current page was opened by link with gift in url params
    isGift = queryObject.isGift === '1';

    // set email from url email
    const providedEmail = getUrlParam(GET_PARAMS.EMAIL);

    if (providedEmail) {
      if (isBase64(providedEmail)) {
        const decryptedEmail = atob(queryObject[GET_PARAMS.EMAIL]);
        setEmailValue(decryptedEmail);
      } else {
        setEmailValue(fixPlusSymbolInProvidedEmail(providedEmail));
      }
    }

    if (queryObject[GET_PARAMS.FROM_DISTRIBUTOR_SIGN_UP_FLOW] === 'true') {
      hideAccTypeChangeControls();

      // TODO Check the ability to sign up through social networks, clever, login link
      // hide: social signup buttons, clever sign up button, login link
      $teacherSocialLoginBtns.hide();
      $socialBtns.hide();
      $signUpMethodsSeparator.hide();
      $signUpLoginLink.hide();
    }

    // set parent tab active
    if (queryObject.type === ACCOUNT_TYPES_STRING.PARENT) {
      setSelectedTab(ACCOUNT_TYPES.PARENT);
      return;
    }

    // set teacher tab active
    if (queryObject.type === ACCOUNT_TYPES_STRING.TEACHER) {
      setSelectedTab(ACCOUNT_TYPES.TEACHER);
      return;
    }

    // set district tab active
    if (queryObject.type === ACCOUNT_TYPES_STRING.DISTRICT) {
      setSelectedTab(ACCOUNT_TYPES.DISTRICT);
      return;
    }

    // set default tab active
    setSelectedTab();
  };

  /**
   * Hide account type change controls
   */
  const hideAccTypeChangeControls = () => {
    $tabControlWrapper.hide();
  };

  const setEmailValue = function (email) {
    if (!email) return;

    $emailInput.val(email);
  };

  const setEmailInputAttrs = function (type) {
    switch (type) {
      case ACCOUNT_TYPES.PARENT:
        // $emailLabel.html(parentEmailLabel);
        // $emailInput.attr('placeholder', parentEmailPlaceholder);
        break;

      case ACCOUNT_TYPES.TEACHER:
        $emailLabel.html(teacherEmailLabel);
        $emailInput.attr('placeholder', teacherEmailPlaceholder);
        break;
    }
  };

  const addTabParamToUrl = function (param) {
    const url = new URL(window.location);
    const search_params = url.searchParams;

    search_params.set('type', param);
    url.search = search_params.toString();
    const newUrl = url.toString();

    history.pushState('sign-up', document.title, newUrl);
  };

  const handleTabChange = function (type) {
    // hide all blocks
    $signUpParentContent.addClass(hidden);
    $signUpTeacherContent.addClass(hidden);
    $signUpDistrictContent.addClass(hidden);

    // shows specified blocks, set selected account type
    switch (type) {
      case ACCOUNT_TYPES.PARENT:
        $signUpParentContent.removeClass(hidden);
        addTabParamToUrl(ACCOUNT_TYPES_STRING.PARENT);
        break;

      case ACCOUNT_TYPES.TEACHER:
        $signUpTeacherContent.removeClass(hidden);
        setEmailInputAttrs(ACCOUNT_TYPES.TEACHER);
        addTabParamToUrl(ACCOUNT_TYPES_STRING.TEACHER);
        break;

      case ACCOUNT_TYPES.DISTRICT:
        $signUpDistrictContent.removeClass(hidden);
        addTabParamToUrl(ACCOUNT_TYPES_STRING.DISTRICT);
        break;

      default:
        setEmailInputAttrs(ACCOUNT_TYPES.PARENT);
        break;
    }
  };

  const setSelectedTab = function (type, email) {
    if (!type) type = selectedAccountType;

    $tabControl.filter('[value=' + type + ']').prop('checked', true);
    selectedAccountType = type;

    handleTabChange(type, email);
  };

  const goToNextPage = function () {
    if (isDistributorCodeActivated) {
      window.location = URLS.DISTRIBUTOR_CODE_SUCCESS;
      return;
    }
    const providedCoupon = getUrlParam(GET_PARAMS.COUPON);
    const getParam = providedCoupon ? `?${GET_PARAMS.COUPON}=${providedCoupon}` : '';
    if (isGift) {
      window.location.href = URLS.SIGN_UP_PARENT_SUCCESS + getParam;
      return;
    }

    window.location.href = URLS.KID_PROFILE_CREATE + getParam;
  };

  const onSignUpSuccess = function ($submitBtn) {
    ga('send', 'event', 'Registration', 'CompleteStep2', 'Parent');
    const partnerCouponCode = localStorage.getItem(LOCAL_STORAGE_KEYS.PARTNER_COUPON_CODE);

    if (partnerCouponCode) {
      $.post(ENDPOINT.GIFT_CODE_REDEEM, {
        code: partnerCouponCode
      }).then(function () {
        isDistributorCodeActivated = true;

        toggleButtonLoading($submitBtn, false);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.PARTNER_COUPON_CODE);
        isDistributorCodeActivated = true;

        goToNextPage();
      }, function (error) {
        toggleButtonLoading($submitBtn, false);
        const errorText = getError(error);
        toastr.warning(errorText);
      });
      return;
    }

    goToNextPage();
  };

  const handleTeacherEmailFormSubmit = () => {
    // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
    const hiddenInputVal = $('[data-selector="sign-up-email-special-input"]').val();
    if (hiddenInputVal) return;

    $signupTeacherError.hide();
    const parsleyEmail = $emailInput.parsley();
    parsleyEmail.validate();

    if (parsleyEmail.isValid()) {
      const email = $emailInput.val();
      toggleButtonLoading($nextBtn, true);

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          email: email,
          account_type: ACCOUNT_TYPES.TEACHER,
          source: getUserSources('source'),
          medium: getUserSources('medium'),
          campaign: getUserSources('campaign'),
          placement: getUserSources('placement')
        })
      }).then(() => {
        // add query param if a user sign up with distributor flow coupons
        const queryObject = Qs.parse(location.search.substr(1));
        const key = GET_PARAMS.FROM_DISTRIBUTOR_SIGN_UP_FLOW;
        if (queryObject[key] === 'true') {
          window.location.href = `${URLS.SIGN_UP_TEACHER}?${key}=true`;
          return;
        }

        ga('send', 'event', 'Email', 'Captured', 'Teacher');
        // go to teacher sign up page
        window.location.href = URLS.SIGN_UP_TEACHER;
      }, (error) => {
        toggleButtonLoading($nextBtn, false);
        handleCAPTCHAError(error);

        const errorText = getError(error);
        $signupTeacherError.text(errorText);
        $signupTeacherError.show();
      });
    }
  };

  // event listeners
  $(document)
    // on terms checkbox change
    .on('change', termsCheckbox, function (e) {
      $nextBtn.attr('disabled', !e.target.checked);
    })
    // sign up teacher first step submit email form
    .on('submit', emailForm, function (e) {
      e.preventDefault();
      handleTeacherEmailFormSubmit();
    })
    .on('click', signUpParentBtn, function (e) {
      ga('send', 'event', 'Registration', 'Go', 'Parent');

      e.preventDefault();
      $signupParentError.hide();

      toggleButtonLoading($signUpParentBtn, true);
      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          account_type: ACCOUNT_TYPES.PARENT,
          email: null,
          need_to_login: true,
          source: getUserSources('source'),
          medium: getUserSources('medium'),
          campaign: getUserSources('campaign'),
          placement: getUserSources('placement')
        })
      }).then(() => {
        onSignUpSuccess($signUpParentBtn);
      }, (error) => {
        toggleButtonLoading($signUpParentBtn, false);
        const errors = JSON.parse(error.responseText);
        toastr.error(errors.error, '', {timeOut: 3000});
      });

    })
    // handle tabs change, shows correct content depends on selected tab
    .on('click', tabControl, function (e) {
      setSelectedTab(e.target.value);
    });

  onPageLoad();
});
