import {URLS, WEBSITE_CUSTOM_EVENTS} from '../config';

const MOBILE_WIDTH = 710;

$(document).ready(function () {
  const activeClassName = '_active';
  const openClassName = '_open';
  const dataSlugAttr = 'data-slug';

  // spinner
  const spinnerSelector = '[data-selector="spinner"]';

  // title
  const metaTitleAttr = 'data-meta-title';
  const mainTitleAttr = 'data-title';
  const mainTitleElem = '[data-selector="main-title"]';

  // categories
  const categoryItemBtn = '[data-selector="category-toggle"]';
  const categoryItem = '[data-selector="categories-list-item"]';
  const articleContainer = '[data-selector="article-container"]';
  const relatedLinksContainer = '[data-selector="related-links"]';
  const breadcrumbs = '[data-selector="material-details-breadcrumbs"]';

  const buildUrl = ($currentCategoryItem) => {
    let url = URLS.LEARNING_MATERIALS;
    const subSubjectSlug = $currentCategoryItem.attr(dataSlugAttr);
    url += subSubjectSlug + '/';
    return url;
  };

  const updateArticle = (url, updatePageUrl=true) => {
    $(spinnerSelector).show();

    $.ajax({
      type: 'GET',
      url: url,
      contentType: 'text/partial_html',
      data: {
        // The `partial` parameter helps to build a unique URL for a partial page.
        // The server will use the URL to fetch a page from the cache.
        partial: true,
      },
      cache: true,
      success: (data) => {
        const $newPage = $('<div>').html(data);

        const newBreadcrumbs = $newPage.find(breadcrumbs).html();
        const $breadcrumbs = $(breadcrumbs);
        $breadcrumbs.html(newBreadcrumbs);

        const newArticle = $newPage.find(articleContainer).html();
        const $article = $(articleContainer);
        $article.html(newArticle);

        const $relatedLinks = $(relatedLinksContainer);
        const newRelatedLinks = $newPage.find(relatedLinksContainer);
        if ($relatedLinks.length) {
          $relatedLinks.html(newRelatedLinks.html());
          $relatedLinks.show();
        } else {
          $relatedLinks.hide();
        }

        // update main and page titles
        const newTitle = $newPage.find(articleContainer).attr(mainTitleAttr);
        const newMetaTitle = $newPage.find(articleContainer).attr(metaTitleAttr);

        $(mainTitleElem).html(newTitle); // update title
        document.title = newMetaTitle; // update page title

        document.dispatchEvent(new CustomEvent(WEBSITE_CUSTOM_EVENTS.RELOAD_HTMX_ADS_BANNERS));

        $(spinnerSelector).hide();
      },
      error: () => $(spinnerSelector).hide()
    });

    if (updatePageUrl && window.history && typeof window.history.pushState === 'function') {
      window.history.pushState(null, null, url);
    }
  };

  // Bind events
  $(document)
    // arrow click
    .on('click', categoryItemBtn, (e) => {
      e.stopPropagation();

      const deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      if (deviceWidth > MOBILE_WIDTH) return;

      const $currentCategoryItem = $(e.currentTarget).closest(categoryItem);

      if ($currentCategoryItem.hasClass(openClassName)) {
        const $innerItems = $currentCategoryItem.find(categoryItem);
        $innerItems.removeClass(openClassName);

        $currentCategoryItem.children(categoryItem).removeClass(openClassName);
        $currentCategoryItem.removeClass(openClassName);
        return;
      }

      $currentCategoryItem.children(categoryItem).addClass(openClassName);
      $currentCategoryItem.addClass(openClassName);
    })
    // category title click
    .on('click', categoryItem, (e) => {
      e.stopPropagation();

      const $currentCategoryItem = $(e.currentTarget);
      $(categoryItem).removeClass(activeClassName);

      $currentCategoryItem.addClass(activeClassName);
      $currentCategoryItem.parents(categoryItem).addClass(activeClassName);

      const url = buildUrl($currentCategoryItem);
      updateArticle(url);
    });
});
