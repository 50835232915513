import toastr from 'toastr';
import Qs from 'qs';

import {
  toggleButtonLoading,
  braintreeDropinConfig,
  threeDSecureConfig,
  getHumanReadablePrice,
  getError,
  scrollToHashedElement,
  isThreeDSecureCanceled
} from '../utils';
import {
  ACCOUNT_SETTINGS_NAV_ITEM,
  CUSTOM_MESSAGE,
  ENDPOINT,
  GET_PARAMS,
  SUBSCRIPTION_CANCEL_REASON_IDS,
} from '../config';
import {handleDropinToken} from '../payment/subscription.utils';

const $document = $(document);

$document.ready(function () {
  const $accountSettingsPage = $('[data-selector="account-settings-page"]');
  if (!$accountSettingsPage.length) return;

  const activeClassName = '_active';
  const hiddenClassName = '_hidden';
  const fieldNameAttribute = 'data-field';
  const accountSettingsPageHash = 'account-settings-page';

  const $accountNavigation = $('#account-navigation');

  const $cancelSubscriptionSubmitModal = $('#cancelSubscriptionSubmitModal');
  const $confirmationStep = $('#confirmation-step');
  const $reasonStep = $('#reason-step');
  const $thankYouStep = $('#thank-you-step');

  const accountFormSelector = '[data-selector="account-form"]';
  const collapsedControlsSelector = '[data-selector="controls-collapsed"]';
  const controlParagraphSelector = '[data-selector="control-paragraph"]';
  const controlInputSelector = '[data-selector="control-input"]';
  const submitChangesSelector = '[data-selector="submit-changes"]';
  const cancelChangesSelector = '[data-selector="cancel-changes"]';
  const toggleControlSelector = '[data-selector="toggle-control"]';

  const submitPasswordChangesSelector = '[data-selector="submit-changes-password"]';

  const parsleyFields = {
    first_name: null,
    last_name: null,
    password_form: null,
    passcode: null
  };

  const passwordInputSelector = '#password';
  const $passwordInput = $(passwordInputSelector);
  const newPasswordInputSelector = '#new-password';
  const $newPasswordInput = $(newPasswordInputSelector);
  const confirmNewPasswordInputSelector = '#confirm-new-password';
  const $confirmNewPasswordInput = $(confirmNewPasswordInputSelector);
  const $passwordErrors = $('#password-errors');

  const $otherReasonInput = $('#other-reason-text');
  const $reasonSubmit = $('#reason-submit');
  const reasonSelector = 'input[name=unsubscribe-reason]';
  const pickedReasonSelector = reasonSelector + ':checked';
  const reasonParsley = $otherReasonInput.parsley();

  const $navContents = $('.nav-content');
  let shouldReload = false;
  let subscriptionId;

  // my subscriptions (current subscriptions details)
  const $braintreeSubscriptionBlock = $('[data-selector="braintree-subscription-block"]');
  const currentPlanPriceAttr = 'data-current-plan-price';

  // change payment
  const $changePaymentBtn = $('[data-selector="change-payment-submit"]');
  const $changePaymentError = $('[data-selector="change-payment-error"]');
  const $currentPlanItem = $('[data-selector="current-plan-item"]');
  const $paymentMethodTabLink = $('[data-id="payment-method"]');
  const $paymentSection = $('[data-selector="payment-section"]');
  const currentPaymentMethodTitleSelector = '[data-braintree-id="methods-label"]';

  // buy eblox
  const $ebloxAddonContainer = $('[data-selector="eblox-addon-container"]');
  const $ebloxAddonBgImage = $('[data-selector="account-settings-eblox-bg"]');
  const goToAddonPaymentBtn = '[data-selector="account-settings-go-to-addon-payment-btn"]';
  const $goToAddonPaymentBtn = $(goToAddonPaymentBtn);
  const $shippingAddonForm = $('[data-selector="shipping-addon-form"]');
  const $shippingAddonFormItem = $('[data-selector="shipping-addon-form-item"]');

  const addonBraintreeDropinContainer = '#account-settings-addon-dropin';
  const buyAddonBtn = '[data-selector="account-settings-buy-addon"]';
  const $buyAddonBtn = $(buyAddonBtn);
  const $buyAddonError = $('[data-selector="account-settings-buy-addon-error"]');

  // eblox and shipping prices
  const $addonPricesContainer = $('[data-selector="account-settings-addon-prices"]');
  const $addonPricesEblox = $('[data-selector="acc-settings-addon-eblox-price"]');
  const $addonPricesShipping = $('[data-selector="acc-settings-addon-shipping-price"]');
  const $addonPricesTotal = $('[data-selector="acc-settings-addon-total-price"]');

  // shipping form
  const shippingCountriesSelect = '[data-selector="shipping-countries-select"]';
  const $shippingCountriesSelect = $(shippingCountriesSelect);

  // parent reports nav
  const addNewChildButton = '[data-selector="add-new-child"]';

  // upgrade subscription
  const upgradePlanBtn = '[data-selector="upgrade-plan-btn"]';
  const planOfferPriceAttr = 'data-offer-price';
  const planOfferSlugAttr = 'data-offer-slug';
  const $subscriptionUpgradeBlock = $('[data-selector="subscription-upgrade-block"]');
  const subscriptionUpgradeDropin = '#subscription-upgrade-dropin-container';
  const $purchaseSubscriptionBtn = $('[data-selector="subscription-upgrade-btn"]');
  const $purchaseSubscriptionError = $('[data-selector="subscription-upgrade-error"]');
  const $subscriptionTotalPriceElem = $('[data-selector="upgrade-subscription-total-price"]');

  $cancelSubscriptionSubmitModal.on('hidden.bs.modal', function () {
    if (shouldReload === true) {
      window.location.reload();
    }
  });

  $('.cancel-membership').click(function (e) {
    e.preventDefault();

    const $target = $(e.currentTarget);
    subscriptionId = $target.data('id');

    $('#cancelSubscriptionSubmitModal').modal();
  });

  $('#confirm-unsubscribe').click(function (e) {
    const $button = $(e.target);
    toggleButtonLoading($button, true);

    $.ajax({
      url: ENDPOINT.CANCEL_SUBSCRIPTION(window.merchant, subscriptionId),
      type: 'DELETE'
    }).then(function () {
      shouldReload = true;
      toggleButtonLoading($button, false);

      $confirmationStep.hide();
      $reasonStep.show();
    });
  });

  $(reasonSelector).change(function () {
    reasonParsley.reset();
  });

  $reasonSubmit.click(function () {
    const reasonValue = $(pickedReasonSelector).val();
    const otherReasonText = $otherReasonInput.val();
    const isOtherReason = parseInt(reasonValue) === SUBSCRIPTION_CANCEL_REASON_IDS.OTHER;

    if (isOtherReason && !reasonParsley.isValid()) {
      reasonParsley.validate();
      return;
    }

    toggleButtonLoading($reasonSubmit, true);

    $.ajax({
      url: ENDPOINT.CANCEL_SUBSCRIPTION(window.merchant, subscriptionId),
      type: 'PATCH',
      contentType: 'application/json',
      data: JSON.stringify({
        cancel_reason: reasonValue,
        cancel_comment: otherReasonText
      })
    }).then(() => {
      toggleButtonLoading($reasonSubmit, false);

      $reasonStep.hide();
      $thankYouStep.show();
    });
  });

  // nav buttons click
  $accountNavigation.find('li a').click((e) => {
    e.preventDefault();
    const id = $(e.currentTarget).attr('data-id');
    parent.location.hash = id;

    const $currentTab = $accountNavigation.find(`li.${activeClassName}`);
    const $nextTab = $(e.currentTarget).closest('li');

    $accountNavigation.find('li').removeClass(activeClassName);
    $nextTab.addClass(activeClassName);

    $accountSettingsPage.removeClass(`_${$currentTab.find('a').attr('data-id')}`);
    $accountSettingsPage.addClass(`_${id}`);

    $navContents.hide();
    $navContents.filter('#' + id).show();

    scrollToHashedElement(
      `#${accountSettingsPageHash}`,
      {scrollIntoViewDelay: 0, scrollTimeout: 0}
    );
  });

  const openChangeForm = (inputElement, pElement, changeElement, collapseElement) => {
    const isPasswordForm = collapseElement.is('#password-row');
    if (!isPasswordForm) {
      inputElement.val($.trim(pElement.text()));
      pElement.addClass(hiddenClassName);
    }

    inputElement.each((index, input) => $(input).removeClass(hiddenClassName));
    changeElement.addClass(hiddenClassName);
    collapseElement.slideDown();
  };

  const closeChangeForm = (inputElement, pElement, changeElement, collapseElement, fieldName) => {
    fieldName === 'password_form'
      ? inputElement.each((index, input) => $(input).val(''))
      : inputElement.each((index, input) => $(input).addClass(hiddenClassName));

    pElement.removeClass(hiddenClassName);
    changeElement.removeClass(hiddenClassName);
    collapseElement.slideUp();

    const formParsley = parsleyFields[fieldName];
    if (formParsley) {
      formParsley.destroy();
    }
  };

  const submitFieldForm = (
    submitBtn,
    inputElement,
    pElement,
    changeElement,
    formElement,
    collapseElement,
    fieldName
  ) => {
    const parsleyForm = formElement.parsley();
    const inputValue = inputElement.val();
    if (parsleyForm.isValid()) {
      const data = {
        [fieldName]: inputValue,
      };

      toggleButtonLoading(submitBtn, true);

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        type: 'PATCH',
        data: data,
        success: () => {
          toggleButtonLoading(submitBtn, false);
          pElement.text(inputValue);
          closeChangeForm(inputElement, pElement, changeElement, collapseElement);
        }
      });
    } else {
      parsleyForm.validate();
      parsleyFields[fieldName] = parsleyForm;
    }
  };

  const submitPasswordForm = (button, formElement, fieldName) => {
    const parsleyForm = formElement.parsley();
    const isFormValid = parsleyForm.isValid();

    if (isFormValid) {
      const data = {
        password: $newPasswordInput.val(),
        repeat_password: $confirmNewPasswordInput.val(),
        old_password: $passwordInput.val()
      };

      toggleButtonLoading(button, true);

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        type: 'PATCH',
        data: data,
        error: (xhr) => {
          toggleButtonLoading(button, false);

          const errorText = JSON.parse(xhr.responseText)['non_field_errors'][0];
          $passwordErrors.empty();
          const errorHtml = `<div id="errorText" class="alert alert-danger">${errorText}</div>`;
          $passwordErrors.append(errorHtml);
        },
        success: () => {
          toggleButtonLoading(button, false);
          button.closest(accountFormSelector).find(cancelChangesSelector).click();
          $passwordErrors.empty();

          // TODO add support for spanish lang
          toastr.success('Password was changed successfully');
        }
      }).then(() => {
        $newPasswordInput.val('');
        $confirmNewPasswordInput.val('');
        $passwordInput.val('');
      });
    } else {
      parsleyForm.validate();
      parsleyFields[fieldName] = parsleyForm;
    }
  };

  const triggerValidatePasswords = () => {
    if (parsleyFields.password_form) parsleyFields.password_form.validate();
  };

  $document
    .on('click', toggleControlSelector, (e) => {
      const $target = $(e.currentTarget);
      const $relatedForm = $target.closest(accountFormSelector);
      const $relatedControlInput = $relatedForm.find(controlInputSelector);
      const $relatedControlParagraph = $relatedForm.find(controlParagraphSelector);
      const $relatedControls = $relatedForm.find(collapsedControlsSelector);

      openChangeForm(
        $relatedControlInput,
        $relatedControlParagraph,
        $target,
        $relatedControls
      );
    })
    .on('click', cancelChangesSelector, (e) => {
      const $target = $(e.currentTarget);
      const $relatedForm = $target.closest(accountFormSelector);
      const $relatedControlInput = $relatedForm.find(controlInputSelector);
      const $relatedControlParagraph = $relatedForm.find(controlParagraphSelector);
      const $relatedToggleControl = $relatedForm.find(toggleControlSelector);
      const $relatedControls = $target.closest(collapsedControlsSelector);
      const fieldName = $relatedForm.attr(fieldNameAttribute);

      closeChangeForm(
        $relatedControlInput,
        $relatedControlParagraph,
        $relatedToggleControl,
        $relatedControls,
        fieldName
      );
    })
    .on('click', submitChangesSelector, (e) => {
      const $target = $(e.currentTarget);
      const $relatedForm = $target.closest(accountFormSelector);
      const $relatedControlInput = $relatedForm.find(controlInputSelector);
      const $relatedControlParagraph = $relatedForm.find(controlParagraphSelector);
      const $relatedToggleControl = $relatedForm.find(toggleControlSelector);
      const $relatedCollapseElement = $relatedForm.find(collapsedControlsSelector);
      const fieldName = $relatedForm.attr(fieldNameAttribute);

      submitFieldForm(
        $target,
        $relatedControlInput,
        $relatedControlParagraph,
        $relatedToggleControl,
        $relatedForm,
        $relatedCollapseElement,
        fieldName
      );
    })
    .on('click', submitPasswordChangesSelector, (e) => {
      const $target = $(e.currentTarget);
      const $relatedForm = $target.closest(accountFormSelector);
      const fieldName = $relatedForm.attr(fieldNameAttribute);

      submitPasswordForm($target, $relatedForm, fieldName);
    })
    .on('change', newPasswordInputSelector, triggerValidatePasswords)
    .on('change', confirmNewPasswordInputSelector, triggerValidatePasswords);

  $paymentMethodTabLink.on('click', () => {
    braintreeDropinInit();
    $paymentSection.show();
  });

  const braintreeDropinInit = function () {
    const $dropinContainer = $accountSettingsPage.find('#dropin-container');

    if ($dropinContainer.html() !== '') {
      $dropinContainer.html('');
      $changePaymentBtn.unbind();
    }

    handleDropinToken(() => {
      const braintreeConf = braintreeDropinConfig();
      braintree.dropin.create(braintreeConf,(createErr, instance) => {
        $(currentPaymentMethodTitleSelector).text('Current payment method');
        if (createErr) {
          /* eslint-disable no-console */
          console.error('dropin createErr - ', createErr);
          return;
        }

        if (instance.isPaymentMethodRequestable()) {
          // This will be true if you generated the client token
          // with a customer ID and there is a saved payment method
          // available to tokenize with that customer.
          $changePaymentBtn.show();
        }

        instance.on('paymentMethodRequestable', function () {
          $changePaymentBtn.show();
          $changePaymentError.hide();
        });

        instance.on('noPaymentMethodRequestable', function () {
          $changePaymentBtn.hide();
        });

        $changePaymentBtn.click(function () {
          $changePaymentError.hide();
          toggleButtonLoading($changePaymentBtn, true);

          const priceFor3dSecure = $currentPlanItem.attr('data-plan-price');
          const threeDSecureConf = threeDSecureConfig(priceFor3dSecure);

          instance.requestPaymentMethod({
            threeDSecure: threeDSecureConf
          }, function (requestPaymentMethodErr, payload) {
            if (requestPaymentMethodErr) {
              /* eslint-disable no-console */
              console.log('requestPaymentMethod - ', requestPaymentMethodErr);

              $changePaymentError.text(requestPaymentMethodErr.message);
              $changePaymentError.show();
              toggleButtonLoading($changePaymentBtn, false);
              return;
            }

            if (isThreeDSecureCanceled(payload)) {
              instance.clearSelectedPaymentMethod();
              $changePaymentError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
              $changePaymentError.show();
              toggleButtonLoading($changePaymentBtn, false);
              return;
            }

            $.post(ENDPOINT.UPDATE_PAYMENT_METHOD, {
              payment_method_nonce: payload.nonce,
            })
              .then(function () {
                toggleButtonLoading($changePaymentBtn, false);
                toastr.success('Your payment method has been changed successfully!',
                  '',
                  {timeOut: 3000});
              }, function (errorResp) {
                instance.clearSelectedPaymentMethod();
                const errorObject = JSON.parse(errorResp.responseText);
                toggleButtonLoading($changePaymentBtn, false);
                $changePaymentError.text(errorObject.error);
                $changePaymentError.show();
              });
          });
        });
      });
    }, () => {});
  };

  const getShippingPriceInCents = () => {
    const result = parseInt($shippingCountriesSelect.find('option:selected').attr('data-price'));
    return result;
  };

  const getEbloxPriceInCents = () => {
    if (!ebloxProductPrice) return;

    const result = parseInt(ebloxProductPrice);
    return result;
  };

  const getTotalEbloxAndShippingPrice = () => {
    const result = getEbloxPriceInCents() + getShippingPriceInCents();
    return result;
  };

  const addAddonPricesToUI = () => {
    const addonPrice = getHumanReadablePrice(getEbloxPriceInCents());
    $addonPricesEblox.html(addonPrice);

    const shippingPrice = getHumanReadablePrice(getShippingPriceInCents());
    $addonPricesShipping.html(shippingPrice);

    const addonPricesTotal = getHumanReadablePrice(getTotalEbloxAndShippingPrice());
    $addonPricesTotal.html(addonPricesTotal);
  };

  const getBuyProductRequestData = (paymentMethodNonce) => {
    const result = {
      payment_method_nonce: paymentMethodNonce,
      product_id: ebloxProductId,
    };

    // add shipping data
    const formFields = {};
    const shippingFormDataArray = $shippingAddonFormItem.serializeArray();

    shippingFormDataArray.forEach((item) => {
      formFields[item.name] = item.value;
    });

    result.shipping_address = formFields;

    return result;
  };

  const buyAddonBraintreeDropinInit = () => {
    const $dropinContainer = $accountSettingsPage.find(addonBraintreeDropinContainer);
    if ($dropinContainer.html() !== '') $dropinContainer.html('');

    const totalPriceInCents = getTotalEbloxAndShippingPrice();
    const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2).toString();
    const braintreeConf = braintreeDropinConfig(totalPriceInDollars, addonBraintreeDropinContainer);

    braintree.dropin.create(braintreeConf,
      function (createErr, instance) {
        $(currentPaymentMethodTitleSelector).text('Current payment method');
        if (createErr) {
          /* eslint-disable no-console */
          console.error('dropin createErr - ', createErr);
          return;
        }

        if (instance.isPaymentMethodRequestable()) {
          // This will be true if you generated the client token
          // with a customer ID and there is a saved payment method
          // available to tokenize with that customer.
          $buyAddonBtn.show();
        }

        instance.on('paymentMethodRequestable', function () {
          $buyAddonBtn.show();
          $buyAddonError.hide();
        });

        instance.on('noPaymentMethodRequestable', function () {
          $buyAddonError.hide();
        });

        $buyAddonBtn.click(function () {
          $buyAddonError.hide();

          const shippingAddonFormItemParsley = $shippingAddonFormItem.parsley();
          if (!shippingAddonFormItemParsley.isValid()) {
            shippingAddonFormItemParsley.validate();
            return;
          }

          toggleButtonLoading($buyAddonBtn, true);

          const priceFor3dSecure = (totalPriceInCents / 100).toFixed(2).toString();
          const threeDSecureConf = threeDSecureConfig(priceFor3dSecure);

          instance.requestPaymentMethod({
            threeDSecure: threeDSecureConf
          }, function (requestPaymentMethodErr, payload) {
            if (requestPaymentMethodErr) {
              /* eslint-disable no-console */
              console.log('requestPaymentMethod - ', requestPaymentMethodErr);
              $buyAddonError.text(requestPaymentMethodErr.message);
              $buyAddonError.show();
              toggleButtonLoading($buyAddonBtn, false);
              return;
            }

            if (isThreeDSecureCanceled(payload)) {
              instance.clearSelectedPaymentMethod();
              $buyAddonError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
              $buyAddonError.show();
              toggleButtonLoading($buyAddonBtn, false);
              return;
            }

            const requestData = getBuyProductRequestData(payload.nonce);

            $.ajax({
              url: ENDPOINT.BUY_PRODUCT,
              type: 'POST',
              contentType: 'application/json',
              data: JSON.stringify(requestData)
            })
              .then(() => {
                $dropinContainer.html('');
                $shippingAddonForm.hide();
                $addonPricesContainer.hide();
                $buyAddonBtn.hide();
                ga('send', 'event', 'Eblox', 'Started', 'Parent');

                window.location.reload();
              }, (errorResp) => {
                instance.clearSelectedPaymentMethod();
                const errorObject = JSON.parse(errorResp.responseText);
                toggleButtonLoading($buyAddonBtn, false);
                $buyAddonError.text(errorObject.error);
                $buyAddonError.show();
              });
          });
        });
      });
  };

  const selectTabByHash = () => {
    const tabToOpenName = window.location.hash.substr(1).split('?')[0];
    const $openTabBtn = $(`[data-id="${tabToOpenName}"]`);

    // open tab specific (open "Reports" tab handled in parent-reports.js)
    if (tabToOpenName !== ACCOUNT_SETTINGS_NAV_ITEM.REPORT && $openTabBtn) $openTabBtn.click();

    // init upgrade subscription dropin for specified plan slug in "plan details" tab
    const queryObject = Qs.parse(location.search.substr(1));
    const planToUpgradeSlug = queryObject[GET_PARAMS.PLAN_TO_UPGRADE_SLUG];
    if (tabToOpenName === 'plan-details' && planToUpgradeSlug) {
      /* eslint-disable max-len */
      const $upgradeSubscriptionBtn = $(`${upgradePlanBtn}[${planOfferSlugAttr}="${planToUpgradeSlug}"]`);
      // scroll to dropin
      $('html, body').animate({
        scrollTop: $subscriptionUpgradeBlock.offset().top - 20
      }, 2000);

      // click to init dropin
      $upgradeSubscriptionBtn.click();
    }
  };

  const updateSubscriptionBraintreeDropinInit = function ($planItem) {
    $purchaseSubscriptionBtn.hide();

    // clear dropin container
    const $dropinContainer = $accountSettingsPage.find(subscriptionUpgradeDropin);
    if ($dropinContainer.html() !== '') $dropinContainer.html('');

    $subscriptionUpgradeBlock.show();

    // hide eblox addon blocks
    $ebloxAddonContainer.hide();
    $ebloxAddonBgImage.hide();

    const newPlanPriceInCents = $planItem.attr(planOfferPriceAttr);
    const newPlanSlug = $planItem.attr(planOfferSlugAttr);
    const currentPlanPriceInCents = $braintreeSubscriptionBlock.attr(currentPlanPriceAttr);

    const totalPriceInCents = newPlanPriceInCents - currentPlanPriceInCents;
    const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2).toString();

    $subscriptionTotalPriceElem.text(getHumanReadablePrice(totalPriceInCents));

    handleDropinToken(() => {
      const braintreeConf = braintreeDropinConfig(totalPriceInDollars, subscriptionUpgradeDropin);

      braintree.dropin.create(braintreeConf, (createErr, instance) => {
        $(currentPaymentMethodTitleSelector).text('Current payment method');
        if (createErr) {
          /* eslint-disable no-console */
          console.error('dropin createErr - ', createErr);
          return;
        }

        if (instance.isPaymentMethodRequestable()) {
          // This will be true if you generated the client token
          // with a customer ID and there is a saved payment method
          // available to tokenize with that customer.
          $purchaseSubscriptionBtn.show();
        }

        instance.on('paymentMethodRequestable', () => {
          $purchaseSubscriptionBtn.show();
          $purchaseSubscriptionError.hide();
        });

        instance.on('noPaymentMethodRequestable', () => {
          $purchaseSubscriptionError.hide();
        });

        $purchaseSubscriptionBtn.click(() => {
          $purchaseSubscriptionError.hide();
          toggleButtonLoading($purchaseSubscriptionBtn, true);

          const priceFor3dSecure = (totalPriceInCents / 100).toFixed(2).toString();
          const threeDSecureConf = threeDSecureConfig(priceFor3dSecure);

          ga('send', 'event', 'UpgradeMade', 'SubscriptionUpgrade', 'Parent');

          instance.requestPaymentMethod({
            threeDSecure: threeDSecureConf
          }, (requestPaymentMethodErr, payload) => {
            if (requestPaymentMethodErr) {
              /* eslint-disable no-console */
              console.log('requestPaymentMethod - ', requestPaymentMethodErr);
              $purchaseSubscriptionError.text(requestPaymentMethodErr.message);
              $purchaseSubscriptionError.show();
              toggleButtonLoading($purchaseSubscriptionBtn, false);
              return;
            }

            if (isThreeDSecureCanceled(payload)) {
              instance.clearSelectedPaymentMethod();
              $purchaseSubscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
              $purchaseSubscriptionError.show();
              toggleButtonLoading($purchaseSubscriptionBtn, false);
              return;
            }

            const requestData = {
              payment_method_nonce: payload.nonce,
              plan_slug: newPlanSlug,
              source: 'upgraded-offer',
            };

            $.ajax({
              url: ENDPOINT.UPGRADE_SUBSCRIPTION,
              type: 'POST',
              contentType: 'application/json',
              data: JSON.stringify(requestData)
            }).then(() => {
                window.location.reload();
              }, (error) => {
                instance.clearSelectedPaymentMethod();
                $purchaseSubscriptionError.text('');
                $purchaseSubscriptionError.html(getError(error));
                $purchaseSubscriptionError.show();
                toggleButtonLoading($purchaseSubscriptionBtn, false);
              });
          });
        });
      });
    }, () => {});
  };

  const handleAddonBraintreeDropinInit = (callback) => {
    handleDropinToken(() => {
      toggleButtonLoading($(goToAddonPaymentBtn), false);
      callback();
    }, () => {
      toggleButtonLoading($(goToAddonPaymentBtn), false);
    });
  };

  $(document)
    // buy addon btn click
    .on('click', goToAddonPaymentBtn, () => {
      toggleButtonLoading($(goToAddonPaymentBtn), true);

      handleAddonBraintreeDropinInit(() => {
        buyAddonBraintreeDropinInit();
        addAddonPricesToUI();
        $goToAddonPaymentBtn.hide();
        $shippingAddonForm.show();
        $addonPricesContainer.show();
        $buyAddonBtn.show();
      });
    })
    .on('change', shippingCountriesSelect, () => {
      $(addonBraintreeDropinContainer).html('');
      handleAddonBraintreeDropinInit(() => {
        buyAddonBraintreeDropinInit();
        addAddonPricesToUI();
      });
    })
    .on('click', addNewChildButton, () => {
      $('#addNewChildModal').modal('show');
    })
    .on('click', upgradePlanBtn, (e) => {
      updateSubscriptionBraintreeDropinInit($(e.currentTarget));
    });

  selectTabByHash();
});
