import {
  braintreeDropinConfig,
  capitalize,
  getError,
  getUserSources,
  isFormValid,
  isThreeDSecureCanceled,
  threeDSecureConfig,
  toggleButtonLoading
} from '../utils';
import {
  ACCOUNT_TYPES,
  CURRENCY,
  CUSTOM_MESSAGE,
  DROPIN_SCRIPT_URL,
  ENDPOINT,
  PURCHASE_STEP_TRIGGERED_ATTRIBUTE,
  SUBSCRIPTION_PERIOD_MAPPING,
  SUBSCRIPTION_PERIODS,
  WORKSHEET_DOWNLOAD_DELAY
} from '../config';
import {
  doCheckIsCouponDistributorCode,
  getCurrencyPrice,
  getSelectedOfferDetails,
  getTotalPricesInCents,
  handleDropinToken,
  setCheckoutBtnTitle,
  toggleApplyCouponBtn,
  toggleCouponBlock
} from '../payment/subscription.utils';

$(document).ready(() => {
  const monetizationPopupsSelector = '[data-selector="monetization-popup"]';
  const $monetizationPopups = $(monetizationPopupsSelector);
  const $downloadBtn = $('[data-selector="download-worksheet-link"]');
  const isWorksheetDownloadAvailable = !!$downloadBtn.length;

  const closePopupTextBtn = '[data-selector=monetization-popup-text-close]';
  const downloadText = '[data-selector="monetization-popup-download-text"]';
  const downloadLink = '[data-selector="monetization-popup-download-link"]';
  const autoplayAttr = 'data-is-autoplay';
  const isPopupAutoplay = $monetizationPopups.is(`[${autoplayAttr}]`);

  // subscription details elements
  const $planPeriod = $('[data-selector="monetization-popup-plan-period-value"]');
  const $planFullPrice = $('[data-selector="monetization-popup-plan-full-price-value"]');
  const $planTrialPeriod = $('[data-selector="monetization-popup-plan-trial-period-value"]');
  const $planDiscountRow = $('[data-selector="monetization-popup-plan-discount-row"]');
  const $planTrialRow = $('[data-selector="monetization-popup-plan-trial-row"]');
  const $planDiscount = $('[data-selector="monetization-popup-plan-discount-value"]');
  const $planTotalPrice = $('[data-selector="monetization-popup-plan-total-price-value"]');
  const $planRenewCondition = $('[data-selector="monetization-popup-plan-renew-condition"]');

  const section = {
    first: 0,
    second: 1
  };

  // plan attributes
  const planAttr = {
    isTrialRadioEnabled: 'data-is-trial-radio-enabled',
    slug: 'data-slug',
    trialDuration: 'data-trial-duration',
    offerPeriod: 'data-offer-period',
    price: 'data-price',
    backUrl: 'data-offer-back-url',
    offerPeriodDisplay: 'data-offer-period-display',
    submitText: 'data-offer-submit',
    isCouponEnabled: 'data-is-coupon-enabled'
  };

  // popup second kind
  const $subscriptionStep = $('[data-selector="monetization-popup-subscription-step"]');
  const monetizationPopupContentSelector = '[data-selector="monetization-popup-content"]';
  const $monetizationPopupContent = $(monetizationPopupContentSelector);
  const subscriptionStepClass = '_subscription-step';
  const isSubscriptionKind = !!$subscriptionStep.length;

  const braintreeDropinSelector = '[data-selector="dropin-container"]';
  const shippingCountriesSelect = '[data-selector="shipping-countries-select"]';
  const shippingFormItemSelect = '[data-selector="shipping-addon-form-item"]';
  const hiddenInputSelect = '[data-selector="monetization-popup-special-input"]';

  const $subscriptionError = $('[data-selector="monetization-popup-subscription-error"]');
  const $submitPaymentBtn = $('[data-selector="monetization-popup-submit-payment-btn"]');

  const popupHideHandler = () => {
    $(closePopupTextBtn).hide();
    $(downloadText).show();
    $(downloadLink).hide();
  };

  const popupShownHandler = () => {
    ga('send', 'event', 'TG_Banner', 'PopUp Shown', 'Click');

    // do not start video if popup is of the 2nd type
    // and was triggered with subscription step as opener
    const isSubscriptionStep = $monetizationPopupContent.hasClass(subscriptionStepClass);

    const $actionElementToShow =
      isPopupAutoplay || !isWorksheetDownloadAvailable
      ? $(closePopupTextBtn)
      : $(downloadLink);

    // show close popup btn
    setTimeout(() => {
      $(downloadText).hide();
      $actionElementToShow.show();
    }, isSubscriptionStep ? 0 : WORKSHEET_DOWNLOAD_DELAY);
  };

  $monetizationPopups.on('show.bs.modal', popupShownHandler);
  $monetizationPopups.on('hide.bs.modal', popupHideHandler);

  const defineFirstKind = () => {
    //popup first kind
    const $firstKindVideoStep = $('[data-selector="first-kind-video-step"]');
    const $firstKindPurchaseStep = $('[data-selector="first-kind-purchase-step"]');
    const firstKindPlan = '[data-selector="first-kind-plan"]';
    const firstKindGoToPaymentStepBtn = '[data-selector="first-kind-go-to-checkout-btn"]';
    const $firstKindGoToPaymentStepBtn = $(firstKindGoToPaymentStepBtn);
    const $popupTransitionLoader = $('[data-selector="monetization-popup-transition-loader"]');

    const prevSectionButton = '[data-selector="first-kind-back-to-video-section-btn"]';
    const $prevSectionButton = $(prevSectionButton);

    let $openModal = null;

    const activeCoupon = null;
    const amountOff = 0;

    const isSpecialCouponApplied = false;
    const specialCouponTrialDuration = null;
    const isEbloxAdded = false;

    const isTrialActive = () => {
      if (!window.ableToGetTrial) return false;

      const trialDuration = $openModal.find(firstKindPlan).attr(planAttr.trialDuration);
      const isTrialDisabled = parseInt(trialDuration) === 0;
      if (isTrialDisabled) return false;

      return true;
    };

    const doSignUp = () => {
      ga('send', 'event', 'Registration', 'Go', 'Parent');

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          account_type: ACCOUNT_TYPES.PARENT,
          email: null,
          need_to_login: true,
          source: getUserSources('source'),
          medium: getUserSources('medium'),
          campaign: getUserSources('campaign'),
          placement: getUserSources('placement')
        })
      }).then(() => {
        window.isLoggedIn = true;
        $.getScript(DROPIN_SCRIPT_URL, handleDropinToken(dropinTokenCallback));
      }, (error) => {
        toggleButtonLoading($firstKindGoToPaymentStepBtn, false);
        toastr.error(getError(error), '', {timeOut: 3000});
      });
    };

    const dropinTokenCallback = () => {
      toggleButtonLoading($firstKindGoToPaymentStepBtn, false);
      if (!$openModal) return;

      preparePuschaseStep();
    };

    const selectSection = (sectionId) => {
      if (sectionId === section.first) {
        prepareFirstSectionUI();
        // Remove an event listener set by braintree-creating function so as
        // to prevent creating multiple event handlers in case of function call repeat
        $submitPaymentBtn.off('click');
        return;
      }

      if (sectionId === section.second) {
        prepareSecondSectionUI();
      }
    };

    const prepareFirstSectionUI = () => {
      if ($openModal) {
        $openModal.find(monetizationPopupContentSelector).removeClass(subscriptionStepClass);
      }
      $firstKindPurchaseStep.attr(PURCHASE_STEP_TRIGGERED_ATTRIBUTE, '');
      $firstKindPurchaseStep.hide();
      $firstKindVideoStep.show();
      $subscriptionError.hide();
      $submitPaymentBtn.hide();
      $popupTransitionLoader.hide();
      $(braintreeDropinSelector).html('');
      $planRenewCondition.text('');
    };

    const prepareSecondSectionUI = () => {
      $openModal.find(monetizationPopupContentSelector).addClass(subscriptionStepClass);
      $firstKindPurchaseStep.attr(PURCHASE_STEP_TRIGGERED_ATTRIBUTE, 'true');
      toggleButtonLoading($prevSectionButton, true);
      $popupTransitionLoader.hide();
      $firstKindVideoStep.hide();
      $planDiscountRow.hide();
      $planTrialRow.hide();
      $planRenewCondition.hide();
      $firstKindPurchaseStep.show();
    };

    const preparePuschaseStep = () => {
      $submitPaymentBtn.hide();
      selectSection(section.second);

      const offer = $openModal.find(firstKindPlan);
      const {
        planPeriod,
        planFullPrice,
        planFormattedNewPrice,
        planSlug,
        planBackUrl,
        planPeriodDisplay
      } = getSelectedOfferDetails(offer, amountOff, planAttr);
      const planTrialPeriod = (isSpecialCouponApplied && specialCouponTrialDuration)
          ? specialCouponTrialDuration
          : offer.attr(planAttr.trialDuration);
      const isTrialEnabled = isTrialActive();

      renderSelectedOfferDetails(
        planPeriod,
        planFullPrice,
        planTrialPeriod,
        planFormattedNewPrice,
        planPeriodDisplay
      );

      const { totalPriceInCents, priceFor3DSecure } = getTotalPrices(isTrialEnabled);

      setTotalPriceToUI(totalPriceInCents);

      const $dropinContainer = $openModal.find(braintreeDropinSelector);
      const dropinContainerId = '#' + $dropinContainer.attr('id');

      initBraintreeDropin(
        totalPriceInCents,
        priceFor3DSecure,
        planSlug,
        planBackUrl,
        isTrialEnabled,
        dropinContainerId
      );
    };

    const renderSelectedOfferDetails = (
      planPeriod,
      planFullPrice,
      planTrialPeriod,
      planFormattedNewPrice,
      planPeriodDisplay
    ) => {
      $planPeriod.text(capitalize(planPeriodDisplay));
      $planFullPrice.text(`${CURRENCY}${planFullPrice}`);

      if (isTrialActive()) {
        $planTrialPeriod.text(`${planTrialPeriod} days`);
        $planTrialRow.show();
        $planTotalPrice.text(`${CURRENCY}0.00`);

        if (planPeriod !== SUBSCRIPTION_PERIODS.LIFETIME) {
          const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriod](planFullPrice);
          $planRenewCondition.html(text);
        }

        $planRenewCondition.show();
      } else $planTotalPrice.text(`${CURRENCY}${planFormattedNewPrice}`);
    };

    const getTotalPrices = (isTrialEnabled) => {
      const planFullPriceInCents = $openModal.find(firstKindPlan).attr(planAttr.price);
      const totalPrices = getTotalPricesInCents(
        planFullPriceInCents,
        isTrialEnabled,
        isEbloxAdded,
        activeCoupon,
        amountOff,
        shippingCountriesSelect
      );

      return totalPrices;
    };

    const setTotalPriceToUI = (totalPriceInCents) => {
      const currencyPrice = getCurrencyPrice(totalPriceInCents);
      $planTotalPrice.html(currencyPrice);
    };

    const initBraintreeDropin = (
      totalPriceInCents,
      priceFor3DSecure,
      slug,
      backUrl,
      isTrialEnabled,
      dropinContainerId
    ) => {
      if (window.isBraintreeInitilazing) return;

      if (braintree && $(dropinContainerId).length) {
        const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
        const braintreeConf = braintreeDropinConfig(totalPriceInDollars, dropinContainerId);

        const $dropinContainer = $(dropinContainerId);
        const isDropinInited = !$dropinContainer.is(':empty');
        if (isDropinInited) return;

        window.isBraintreeInitilazing = true;

        braintree.dropin.create(braintreeConf,
          function (createErr, instance) {
            if (createErr) {
              // An error in the create call is likely due to
              // incorrect configuration values or network issues.
              // An appropriate error will be shown in the UI.

              // eslint-disable-next-line no-console
              console.error('dropin create error: ', createErr);
              return;
            }

            if (instance.isPaymentMethodRequestable()) {
              // This will be true if you generated the client token
              // with a customer ID and there is a saved payment method
              // available to tokenize with that customer.
              $submitPaymentBtn.show();
            }

            $(prevSectionButton).hide();

            instance.on('paymentMethodRequestable', () => {
              $submitPaymentBtn.show();
              $subscriptionError.hide();
            });

            instance.on('noPaymentMethodRequestable', () => {
              $submitPaymentBtn.hide();
              $subscriptionError.hide();
            });

            instance.on('paymentOptionSelected', () => {
              $subscriptionError.hide();
              if (instance.isPaymentMethodRequestable()) {
                $submitPaymentBtn.show();
                return;
              }
              $submitPaymentBtn.hide();
            });

            toggleButtonLoading($prevSectionButton, false);
            toggleButtonLoading($submitPaymentBtn, false);

            $submitPaymentBtn.click(() => {
              // To prevent bot spamming we use solution from
              // https://www.thryv.com/blog/honeypot-technique/
              const $hiddenInput = $(hiddenInputSelect);
              const hiddenInputVal = $hiddenInput.val();
              if (hiddenInputVal) return;

              if (isEbloxAdded && !isFormValid($(shippingFormItemSelect))) return;

              $subscriptionError.hide();
              $prevSectionButton.hide();
              toggleButtonLoading($submitPaymentBtn, true);

              const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
              const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

              instance.requestPaymentMethod({
                threeDSecure: threeDSecureConf
              }, function (requestPaymentMethodErr, payload) {
                if (requestPaymentMethodErr) {
                  // eslint-disable-next-line no-console
                  console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);
                  $subscriptionError.text(requestPaymentMethodErr.message);
                  $subscriptionError.show();
                  $prevSectionButton.show();
                  toggleButtonLoading($submitPaymentBtn, false);
                  return;
                }

                if (isThreeDSecureCanceled(payload)) {
                  instance.clearSelectedPaymentMethod();
                  $subscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                  $subscriptionError.show();
                  toggleButtonLoading($submitPaymentBtn, false);
                  $prevSectionButton.show();
                  return;
                }

                // Submit payload.nonce to your server
                $.post(ENDPOINT.BUY_SUBSCRIPTION, {
                  payment_method_nonce: payload.nonce,
                  plan_slug: slug,
                  discount_id: activeCoupon,
                  source: 'main-flow-offer',
                  trial_enabled: isTrialEnabled // always make trial if it's available
                })
                  .then((response) => {
                    triggerGAEventOnCheckout();
                    triggerAnalyticsOnCheckout(response, slug, backUrl);
                  }, (errorResponse) => {
                    instance.clearSelectedPaymentMethod();
                    $subscriptionError.text('');
                    $subscriptionError.append(getError(errorResponse));
                    $subscriptionError.show();

                    toggleButtonLoading($submitPaymentBtn, false);
                  });
              });
            });
          });
      }
    };

    const triggerGAEventOnCheckout = () => {
      if (isTrialActive()) {
        ga('send', 'event', 'Trial', 'Started', 'Parent');
      } else {
        ga('send', 'event', 'Subscription', 'Started', 'Parent');
      }
    };

    const triggerAnalyticsOnCheckout = (response, slug, backUrl) => {
      window.location = backUrl;
    };

    $(document)
      .on('click', firstKindGoToPaymentStepBtn, (e) => {
        ga('send', 'event', 'TG_Banner', 'Plan Link', 'Click');

        $openModal = $(e.target).closest(monetizationPopupsSelector);
        toggleButtonLoading($firstKindGoToPaymentStepBtn, true);
        if (!window.isLoggedIn) {
          doSignUp();
        } else {
          $.getScript(DROPIN_SCRIPT_URL, handleDropinToken(dropinTokenCallback));
        }
      })
      .on('click', prevSectionButton, () => {
        selectSection(section.first);
      });

    $monetizationPopups.on('hide.bs.modal', () => {
      prepareFirstSectionUI();
      $openModal = null;
    });
  };

  const defineSubscriptionStep = () => {
    if (!isSubscriptionKind) return;

    let $openModal = null;

    const popupDialogSelector = '[data-selector="monetization-popup-dialog"]';
    const subscriptionFirstStepClass = '_subscription-first-step';
    const $videoStep = $('[data-selector="monetization-popup-video-step"]');
    const goToSubscriptionStepSelector = '[data-selector="go-to-subscription-step-btn"]';

    const checkoutTextAttr = {
      trial: 'data-trial-text',
      noTrial: 'data-no-trial-text'
    };
    const $firstSection = $('[data-selector="1-subscription-step-monetization-popup"]');
    const $secondSection = $('[data-selector="2-subscription-step-monetization-popup"]');

    // subscription block (first step)
    const planInput = '[data-selector="monetization-popup-subscription-plan-input"]';
    const selectedPlanInput = `${planInput}:checked`;
    const goToPaymentStepBtn = '[data-selector="monetization-popup-go-to-checkout-btn"]';
    const $goToPaymentStepBtn = $(goToPaymentStepBtn);
    const prevSectionButton = '[data-selector="monetization-popup-back-to-first-section-btn"]';
    const $prevSectionButton = $(prevSectionButton);

    // coupon
    const toggleCouponBlockBtn = '[data-selector="monetization-popup-toggle-coupon-block-btn"]';
    const $toggleCouponBlockBtn = $(toggleCouponBlockBtn);
    const $couponBlock = $('[data-selector="monetization-popup-coupon-block"]');
    const $couponInputSection = $('[data-selector="monetization-popup-coupon-input-section"]');
    const couponForm = '[data-selector="monetization-popup-coupon-form"]';
    const $couponForm = $(couponForm);
    const couponInput = '[data-selector="monetization-popup-enter-coupon-input"]';
    const $couponInput = $couponForm.find(couponInput);
    const applyCoupon = '[data-selector="monetization-popup-apply-coupon-btn"]';
    const $applyCoupon = $couponForm.find(applyCoupon);
    const $couponError = $('[data-selector="monetization-popup-coupon-error"]');
    const removeCoupon = '[data-selector="monetization-popup-remove-coupon-btn"]';
    const $removeCoupon = $couponForm.find(removeCoupon);
    const $couponMessage = $couponForm.find('[data-selector="monetization-popup-coupon-message"]');
    const $licenseActivationCodeModal = $('#licenseActivationCode');
    const couponBlockParams = {
      activeClass: '_active',
      emptyCouponClass: '_empty',
      showCouponText: 'Enter coupon code',
      hideCouponText: 'Hide coupon code'
    };
    let activeCoupon = null;
    let amountOff = 0;

    let isSpecialCouponApplied = false;
    let specialCouponTrialDuration = null;
    let removedSubscriptions = [];
    const isEbloxAdded = false;


    const doCheckForPreselectedPlan = () => {
      $monetizationPopups.each((index, element) => {
        const $popupPlanInput = $(element).find(planInput);
        if ($popupPlanInput.is(':checked')) return;

        $popupPlanInput.first().prop('checked', true);
      });
    };

    const toggleIsCouponInputSection = () => {
      const isCouponInputAvailable = $(selectedPlanInput).attr(planAttr.isCouponEnabled) === 'True';
      isCouponInputAvailable ? $couponInputSection.show() : $couponInputSection.hide();
    };

    const setCheckoutBtnTitleNew = () => {
      const checkoutBtnTitle = $(selectedPlanInput).attr(planAttr.submitText);
      $goToPaymentStepBtn.text(checkoutBtnTitle);
    };

    const initFirstSectionUI = () => {
      toggleIsCouponInputSection();
      toggleApplyCouponBtn($couponInput, $applyCoupon, couponBlockParams);
      setCheckoutBtnTitleNew();
    };

    const onSubscriptionStepLoad = () => {
      doCheckForPreselectedPlan();
      initFirstSectionUI();
    };

    const isTrialActive = () => {
      if (!window.ableToGetTrial) return false;

      if (isSpecialCouponApplied) return true;

      const trialDuration = $openModal.find(selectedPlanInput).attr(planAttr.trialDuration);
      const isTrialDisabled = parseInt(trialDuration) === 0;
      if (isTrialDisabled) return false;

      if (activeCoupon) return false;

      return true;
    };

    const checkCouponApplicability = () => {
      const planSlug = $(selectedPlanInput).attr(planAttr.slug);
      const itemIndex = removedSubscriptions.indexOf(planSlug);
      const isInvalid = removedSubscriptions[itemIndex];

      if (isInvalid) {
        $goToPaymentStepBtn.prop('disabled', true);
        $couponError.show();
      } else {
        $goToPaymentStepBtn.prop('disabled', false);
        $couponError.hide();
      }

      return !isInvalid;
    };

    const clearComponentVariables = () => {
      activeCoupon = null;
      amountOff = 0;
      isSpecialCouponApplied = false;
      specialCouponTrialDuration = null;
      removedSubscriptions = [];
    };

    const prepareFirstSectionUI = () => {
      const isSecondKindSubscriptionStep = $subscriptionStep.is(':visible');

      if (isSecondKindSubscriptionStep) {
        $openModal.find(popupDialogSelector).addClass(subscriptionFirstStepClass);
      }

      $secondSection.attr(PURCHASE_STEP_TRIGGERED_ATTRIBUTE, '');
      $secondSection.hide();
      $firstSection.show();
      $subscriptionError.hide();
      $submitPaymentBtn.hide();
      $(braintreeDropinSelector).html('');
      $planRenewCondition.text('');
    };

    const prepareSecondSectionUI = () => {
      $openModal.find(popupDialogSelector).removeClass(subscriptionFirstStepClass);
      $secondSection.attr(PURCHASE_STEP_TRIGGERED_ATTRIBUTE, 'true');
      toggleButtonLoading($prevSectionButton, true);
      $firstSection.hide();
      $planDiscountRow.hide();
      $planTrialRow.hide();
      $planRenewCondition.hide();
      $secondSection.show();
    };

    const selectSection = (sectionId) => {
      if (sectionId === section.first) {
        prepareFirstSectionUI();
        // Remove an event listener set by braintree-creating function so as
        // to prevent creating multiple event handlers in case of function call repeat
        $submitPaymentBtn.off('click');
        return;
      }

      if (sectionId === section.second) {
        prepareSecondSectionUI();
      }
    };

    const doSignUp = () => {
      ga('send', 'event', 'Registration', 'Go', 'Parent');

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          account_type: ACCOUNT_TYPES.PARENT,
          email: null,
          need_to_login: true,
          source: getUserSources('source'),
          medium: getUserSources('medium'),
          campaign: getUserSources('campaign'),
          placement: getUserSources('placement')
        })
      }).then(() => {
        window.isLoggedIn = true;
        $.getScript(DROPIN_SCRIPT_URL, handleDropinToken(dropinTokenCallback));
      }, (error) => {
        toggleButtonLoading($goToPaymentStepBtn, false);
        toastr.error(getError(error), '', {timeOut: 3000});
      });
    };

    const dropinTokenCallback = () => {
      toggleButtonLoading($goToPaymentStepBtn, false);
      if (!$openModal) return;

      prepareSecondStep();
    };

    const prepareSecondStep = () => {
      $submitPaymentBtn.hide();
      selectSection(section.second);

      const offer = $openModal.find(selectedPlanInput);
      const {
        planPeriod,
        planFullPrice,
        planFormattedNewPrice,
        planSlug,
        planBackUrl,
        planPeriodDisplay
      } = getSelectedOfferDetails(offer, amountOff, planAttr);
      const planTrialPeriod = (isSpecialCouponApplied && specialCouponTrialDuration)
          ? specialCouponTrialDuration
          : offer.attr(planAttr.trialDuration);
      const isTrialEnabled = isTrialActive();

      renderSelectedOfferDetails(
        planPeriod,
        planFullPrice,
        planTrialPeriod,
        planFormattedNewPrice,
        planPeriodDisplay
      );

      const { totalPriceInCents, priceFor3DSecure } = getTotalPrices(isTrialEnabled);

      setTotalPriceToUI(totalPriceInCents);

      const $dropinContainer = $openModal.find(braintreeDropinSelector);
      const dropinContainerId = '#' + $dropinContainer.attr('id');

      initBraintreeDropin(
        totalPriceInCents,
        priceFor3DSecure,
        planSlug,
        planBackUrl,
        isTrialEnabled,
        dropinContainerId
      );
    };

    const renderSelectedOfferDetails = (
      planPeriod,
      planFullPrice,
      planTrialPeriod,
      planFormattedNewPrice,
      planPeriodDisplay
    ) => {
      $planPeriod.text(capitalize(planPeriodDisplay));
      $planFullPrice.text(`${CURRENCY}${planFullPrice}`);

      if (activeCoupon && amountOff > 0) {
        $planDiscount.text(`${CURRENCY}${amountOff}`);
        $planDiscountRow.show();
      }

      if (isTrialActive()) {
        $planTrialPeriod.text(`${planTrialPeriod} days`);
        $planTrialRow.show();
        $planTotalPrice.text(`${CURRENCY}0.00`);

        if (planPeriod !== SUBSCRIPTION_PERIODS.LIFETIME) {
          const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriod](planFullPrice);
          $planRenewCondition.html(text);
        }

        $planRenewCondition.show();
      } else $planTotalPrice.text(`${CURRENCY}${planFormattedNewPrice}`);
    };

    const getTotalPrices = (isTrialEnabled) => {
      const planFullPriceInCents = $openModal.find(selectedPlanInput).attr(planAttr.price);
      const totalPrices = getTotalPricesInCents(
        planFullPriceInCents,
        isTrialEnabled,
        isEbloxAdded,
        activeCoupon,
        amountOff,
        shippingCountriesSelect
      );

      return totalPrices;
    };

    const setTotalPriceToUI = (totalPriceInCents) => {
      const currencyPrice = getCurrencyPrice(totalPriceInCents);
      $planTotalPrice.html(currencyPrice);
    };

    const initBraintreeDropin = (
      totalPriceInCents,
      priceFor3DSecure,
      slug,
      backUrl,
      isTrialEnabled,
      dropinContainerId
    ) => {
      if (braintree && $(dropinContainerId).length) {
        const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
        const braintreeConf = braintreeDropinConfig(totalPriceInDollars, dropinContainerId);

        const $dropinContainer = $(dropinContainerId);
        const isDropinInited = !$dropinContainer.is(':empty');
        if (isDropinInited) return;

        braintree.dropin.create(braintreeConf,
          function (createErr, instance) {
            if (createErr) {
              // An error in the create call is likely due to
              // incorrect configuration values or network issues.
              // An appropriate error will be shown in the UI.

              // eslint-disable-next-line no-console
              console.error('dropin create error: ', createErr);
              return;
            }

            if (instance.isPaymentMethodRequestable()) {
              // This will be true if you generated the client token
              // with a customer ID and there is a saved payment method
              // available to tokenize with that customer.
              $submitPaymentBtn.show();
            }

            instance.on('paymentMethodRequestable', () => {
              $submitPaymentBtn.show();
              $subscriptionError.hide();
            });

            instance.on('noPaymentMethodRequestable', () => {
              $submitPaymentBtn.hide();
              $subscriptionError.hide();
            });

            instance.on('paymentOptionSelected', () => {
              $subscriptionError.hide();
              if (instance.isPaymentMethodRequestable()) {
                $submitPaymentBtn.show();
                return;
              }
              $submitPaymentBtn.hide();
            });

            toggleButtonLoading($prevSectionButton, false);
            toggleButtonLoading($submitPaymentBtn, false);

            $submitPaymentBtn.click(() => {
              // To prevent bot spamming we use solution from
              // https://www.thryv.com/blog/honeypot-technique/
              const $hiddenInput = $(hiddenInputSelect);
              const hiddenInputVal = $hiddenInput.val();
              if (hiddenInputVal) return;

              if (isEbloxAdded && !isFormValid($(shippingFormItemSelect))) return;

              $subscriptionError.hide();
              $prevSectionButton.hide();
              toggleButtonLoading($submitPaymentBtn, true);
              triggerAnalyticsSubmit(slug, totalPriceInDollars);

              const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
              const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

              instance.requestPaymentMethod({
                threeDSecure: threeDSecureConf
              }, function (requestPaymentMethodErr, payload) {
                if (requestPaymentMethodErr) {
                  // eslint-disable-next-line no-console
                  console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);
                  $subscriptionError.text(requestPaymentMethodErr.message);
                  $subscriptionError.show();
                  $prevSectionButton.show();
                  toggleButtonLoading($submitPaymentBtn, false);
                  return;
                }

                if (isThreeDSecureCanceled(payload)) {
                  instance.clearSelectedPaymentMethod();
                  $subscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                  $subscriptionError.show();
                  toggleButtonLoading($submitPaymentBtn, false);
                  $prevSectionButton.show();
                  return;
                }

                // Submit payload.nonce to your server
                $.post(ENDPOINT.BUY_SUBSCRIPTION, {
                  payment_method_nonce: payload.nonce,
                  plan_slug: slug,
                  discount_id: activeCoupon,
                  source: 'main-flow-offer',
                  trial_enabled: isTrialEnabled // always make trial if it's available
                })
                  .then((response) => {
                    triggerGAEventOnCheckout();
                    triggerAnalyticsOnCheckout(response, slug, backUrl);
                  }, (errorResponse) => {
                    instance.clearSelectedPaymentMethod();
                    $subscriptionError.text('');
                    $subscriptionError.append(getError(errorResponse));
                    $subscriptionError.show();

                    toggleButtonLoading($submitPaymentBtn, false);
                  });
              });
            });
          });
      }
    };

    const triggerAnalyticsSubmit = () => {
      ga('send', 'event', 'Registration_flow', 'SubmitPayment', 'step7');
    };

    const triggerGAEventOnCheckout = () => {
      if (isTrialActive()) {
        ga('send', 'event', 'Trial', 'Started', 'Parent');
      } else {
        ga('send', 'event', 'Subscription', 'Started', 'Parent');
      }
    };

    const triggerAnalyticsOnCheckout = (response, slug, backUrl) => {
      window.location = backUrl;
    };

    $(document)
      .on('click', goToSubscriptionStepSelector, () => {
        $openModal.find(popupDialogSelector).addClass(subscriptionFirstStepClass);
        $videoStep.hide();
        $openModal.find(monetizationPopupContentSelector).addClass(subscriptionStepClass);
        $subscriptionStep.show();
      })
      // on change plan
      .on('change', planInput, () => {
        toggleIsCouponInputSection();
        setCheckoutBtnTitleNew();
        checkCouponApplicability();
      })
      // on change coupon input value
      .on('input', couponInput, () => {
        $couponInput.parsley().reset();
        toggleApplyCouponBtn($couponInput, $applyCoupon, couponBlockParams);
      })
      // apply coupon
      .on('click', applyCoupon, (e) => {
        e.preventDefault();
        // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
        const hiddenInputVal =
          $('[data-selector="monetization-popup-coupon-check-special-input"]').val();
        if (hiddenInputVal) return;

        if (!isFormValid($couponForm)) return;

        const coupon = $couponInput.val().trim();
        $couponError.hide();

        if (coupon) {
          toggleButtonLoading($applyCoupon, true);

          // check is coupon a distributor code
          doCheckIsCouponDistributorCode(coupon)
            .then(() => {
              toggleButtonLoading($applyCoupon, false);
              $licenseActivationCodeModal.modal('show');
            }, () => {
              toggleButtonLoading($applyCoupon, false);
            });

          $.post(ENDPOINT.COUPON_VALIDATE, {
            coupon: coupon
          }).then((response) => {
            toggleButtonLoading($applyCoupon, false);

            activeCoupon = coupon;
            amountOff = response.amount_off;
            removedSubscriptions = response.exclude_subscriptions;
            isSpecialCouponApplied = response.has_trial;
            specialCouponTrialDuration = response.trial_duration;

            const isCouponValid = checkCouponApplicability();
            $applyCoupon.prop('disabled', false);
            $couponInput.parsley().removeError('coupon');
            $couponInput.prop('disabled', true);
            $applyCoupon.hide();
            $removeCoupon.show();
            $couponMessage.html(response.description);
            $couponMessage.show();

            if (isCouponValid) ga('send', 'event', 'Button', 'CouponApplied', 'Parent');

            setCheckoutBtnTitle($goToPaymentStepBtn, isTrialActive(), checkoutTextAttr);
          }, (error) => {
            toggleButtonLoading($applyCoupon, false);

            activeCoupon = null;
            $couponMessage.empty();
            $couponMessage.hide();
            const errorObject = JSON.parse(error.responseText);

            if (errorObject.error) {
              $couponInput.parsley().removeError('coupon');
              $couponInput.parsley().addError('coupon', {
                message: errorObject.error
              });
            }
          });
        }
      })
      // remove coupon applied to the selected plan
      .on('click', removeCoupon, (e) => {
        e.preventDefault();

        clearComponentVariables();
        $couponInput.parsley().reset();
        $couponInput.prop('disabled', false);
        $couponInput.val('');
        $applyCoupon.show();
        $removeCoupon.hide();
        $couponMessage.empty();
        $couponMessage.hide();
        checkCouponApplicability();
      })
      // show/hide apply coupon form
      .on('click', toggleCouponBlockBtn, (e) => {
        e.preventDefault();
        toggleCouponBlock($couponBlock, $toggleCouponBlockBtn, couponBlockParams);
      })
      // go to first step (select plan)
      .on('click', prevSectionButton, () => {
        selectSection(section.first);
      })
      // go to second step (checkout)
      .on('click', goToPaymentStepBtn, (e) => {
        e.preventDefault();
        toggleButtonLoading($goToPaymentStepBtn, true);
        if (!window.isLoggedIn) {
          doSignUp();
        } else {
          $.getScript(DROPIN_SCRIPT_URL, handleDropinToken(dropinTokenCallback));
        }
      });

    $monetizationPopups.on('show.bs.modal', (e) => {
      $openModal = $(e.target).closest(monetizationPopupsSelector);
    });
    $monetizationPopups.on('hide.bs.modal', () => {
      prepareFirstSectionUI();
      $openModal = null;
    });

    onSubscriptionStepLoad();
  };

  defineFirstKind();
  defineSubscriptionStep();
});
