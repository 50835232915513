import {
  LOCAL_STORAGE_KEYS,
  MODALS
} from '../config';
import {storage} from '../utils';


$(document).ready( () => {
  const $websiteAccountTypeModal = $(MODALS.ACCOUNT_TYPE_MODAL);
  if (!$websiteAccountTypeModal.length) return;

  const websiteAccountTypeRadio = '[data-selector="website-signup-radio"]';

  const resetWebsiteAccountTypeModal = () => {
    $websiteAccountTypeModal.find('input').each((index, item) => {
      $(item).prop('checked', false);
    });
  };

  $(document)
    .on('change', websiteAccountTypeRadio, (e) => {
      $websiteAccountTypeModal.modal('hide');
      resetWebsiteAccountTypeModal();

      const accountType = e.target.value;
      switch(accountType) {
        case 'parent':
          ga('send', 'event', 'Monetization3_PopUp_Init', 'Parent', 'Click');
          break;
        case 'teacher':
          ga('send', 'event', 'Monetization3_PopUp_Init', 'Teacher', 'Click');
          break;
        case 'student':
          ga('send', 'event', 'Monetization3_PopUp_Init', 'Student', 'Click');
          break;
      }

      storage.set(LOCAL_STORAGE_KEYS.WEBSITE_ACCOUNT_TYPE, accountType);

      $(MODALS.WEBSITE_SIGN_UP_MODAL).modal('show');
    });

  $(MODALS.ACCOUNT_TYPE_MODAL).on('shown.bs.modal', () => {
    ga('send', 'event', 'Monetization3_PopUp_Init', 'PopUp_Shown', 'Load');
  });
});
