import {
  SUBJECTS_CONFIG,
  ENDPOINT,
  GET_PARAMS,
  ACCOUNT_TYPES
} from '../config';
import {
  scrollToTop,
  getError,
  getUrlParam,
  toggleButtonLoading,
  isAnySelected
} from '../utils';
import toastr from 'toastr';


$(document).ready(function () {
  const $kidProfileSection = $('[data-selector="kid-profile-section"]');
  if (!$kidProfileSection.length) return;

  const parentSubscriptionPlansUrl = '/subscription-plans/';

  const childData = {
    gradeName: '',
    subjectsList:'',
  };

  const $kidCreatePageTitle = $('[data-selector="kid-profile-page-title"]');
  const kidProfilePageTitle = 'Creating your personal plan...';

  // 2nd step (grade/subject select)
  const $secondStepBlock = $('[data-selector="kid-profile-second-step"]');

  const gradeRadioBtn = '[data-selector="grade-radio"]';
  const gradeLabel = '[data-selector="grade-label"]';

  const $subjectsBlock = $('[data-selector="kid-profile-subjects-block"]');
  const $subjectItem = $('[data-selector="kid-profile-subject"]');
  const subjectCheckbox = '[data-selector="subject-checkbox"]';
  const $subjectCheckbox = $(subjectCheckbox);
  const subjectLabel = '[data-selector="subject-checkbox-label"]';
  const subjectTitle = '[data-selector="subject-checkbox-title"]';

  const secondStepNextBtn = '[data-selector="kid-profile-second-step-next-btn"]';
  const $secondStepNextBtn = $(secondStepNextBtn);

  // 3rd step (profile data)
  const $thirdStepBlock = $('[data-selector="kid-profile-third-step"]');

  const $progressBar = $('[data-selector="progress-bar"]');
  const $progressBarElem = $('[data-selector="progress-bar-elem"]');
  const $cardGrade = $('[data-selector="kid-profile-card-grade"]');
  const $cardSubject = $('[data-selector="kid-profile-card-subject"]');
  const $gradeTitle = $('[data-selector="kid-profile-details-grade"]');

  const thirdStepButtons = '[data-selector="kid-profile-third-step-buttons"]';
  const $thirdStepButtons = $(thirdStepButtons);
  const thirdStepNextBtn = '[data-selector="kid-profile-third-step-next-btn"]';
  const $thirdStepNextBtn = $(thirdStepNextBtn);


  const getSelectedGrade = () => {
    const selectedGradeId = $(gradeRadioBtn+ ':checked').attr('id');
    const selectedGradeLabel = $(gradeLabel + '[for=' + selectedGradeId+ ']').attr('data-value');

    return {label: selectedGradeLabel, id: selectedGradeId};
  };

  const getSelectedSubjects = (key) => {
    const $checkedSubjects = $(subjectCheckbox + ':checked');
    const checkedSubjectsArray = [];

    $checkedSubjects.each(function (index, subject) {
      if (subject.checked) {
        const $subjectLabel = $(subjectLabel + '[for='+ $(subject).attr('id') +']');
        const label = $subjectLabel.find(subjectTitle).html();
        const value = subject.value;
        checkedSubjectsArray.push({label: label, value: value});
      }
    });

    if (key) {
      return checkedSubjectsArray.map(function (subject) {
        return subject[key];
      });
    }

    return checkedSubjectsArray;
  };

  const getKidDataForRequest = () => {
    return {
      grade: $(gradeRadioBtn + ':checked').val(),
      subjects: getSelectedSubjects('label'),
      avatar_id: 0
    };
  };

  const startProgressBar = () => {
    const delay = $progressBarElem.data('delay');

    $progressBarElem
      .data('origWidth', $progressBarElem.width())
      .width(0)
      .animate({width: $progressBarElem.data('origWidth')}, delay);
  };

  const filterSubjectsToShow = (grade) => {
    const subjectsArray = SUBJECTS_CONFIG[grade];

    $subjectItem.each((index, subjectItem) => {
      const $subjectItem = $(subjectItem);
      $subjectItem.addClass('_hidden');

      const subjectValue = $subjectItem.find(subjectCheckbox).attr('value');

      subjectsArray.find((subject) => {
        if (subject.id === parseInt(subjectValue)) {
          $subjectItem.removeClass('_hidden');
        }
      });
    });
  };

  const setCardValues = () => {
    $cardGrade.html(childData.gradeName);

    if (Array.isArray(childData.subjectsList)) {
      const subjectListHtml = childData.subjectsList.map((subject) => {
        const htmlSubjectElement = document.createElement('div');
        htmlSubjectElement.classList.add('kid-profile-create-page__card-item');
        htmlSubjectElement.innerHTML = subject;
        return htmlSubjectElement;
      });

      $cardSubject.html(subjectListHtml);
    }
  };

  const setSectionStepAttr = (step) => {
    if (!step) return;
    const attrName = 'data-step';
    switch(step) {
      case 2:
        $kidProfileSection.attr(attrName, 'grades-subjects');
        break;

      case 3:
        $kidProfileSection.attr(attrName, 'kid-profile-details');
        break;
    }
  };

  $(document)
    // on select grade
    .on('click', gradeRadioBtn, () => {
      const gradeId = getSelectedGrade().id;
      $subjectCheckbox.prop( 'checked', false );
      filterSubjectsToShow(gradeId);
      const isAnySubjectSelected = isAnySelected($subjectCheckbox);
      $subjectsBlock.show();

      if (isAnySubjectSelected) {
        $secondStepNextBtn.attr('disabled', false);
        return;
      }

      $secondStepNextBtn.attr('disabled', true);
    })
    // on select subjects
    .on('change', subjectCheckbox, () => {
      const isAnySubjectSelected = isAnySelected($subjectCheckbox);

      if (isAnySubjectSelected) {
        $secondStepNextBtn.attr('disabled', false);
        return;
      }

      $secondStepNextBtn.attr('disabled', true);
    })
    // second step (grade select) next btn click
    .on('click', secondStepNextBtn, () => {
      scrollToTop();

      ga('send', 'event', 'Grade', 'Next', 'Parent');

      setSectionStepAttr(3);
      $kidCreatePageTitle.text(kidProfilePageTitle);
      const grade = getSelectedGrade();

      childData.gradeName = grade.label;

      $secondStepBlock.hide();
      $thirdStepBlock.show();

      setSectionStepAttr('');

      childData.subjectsList = getSelectedSubjects('label');
      $gradeTitle.html(childData.gradeName);

      setCardValues();
      startProgressBar();

      const delay = $progressBarElem.data('delay');

      setTimeout(function() {
        $progressBar.hide();
        $thirdStepButtons.show();
      }, delay);

    })
    // third step next btn click (last step, go to parent plans)
    .on('click', thirdStepNextBtn, () => {
      toggleButtonLoading($thirdStepNextBtn, true);

      const kidData = getKidDataForRequest();
      const data = {
        'account_type': ACCOUNT_TYPES.KID,
        'source': 'website',
        'medium': 'kid-setup-page',
        'grade': kidData.grade,
        'data': {
          avatarId: kidData.avatar_id,
          subjects: kidData.subjects
        }
      };

      $.ajax({
        url: ENDPOINT.KID_PROFILE_CREATE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify(data)
      }).then(function () {
        ga('send', 'event', 'PlanReady', 'StartLearning', 'Parent');

        const providedCoupon = getUrlParam(GET_PARAMS.COUPON);
        const getParam = providedCoupon ? `?${GET_PARAMS.COUPON}=${providedCoupon}` : '';
        // if user used distributor code in sign up parent flow he will be redirected
        // to home page (by server), we need to redirect user to /subscription-success/
        window.location = parentSubscriptionPlansUrl + getParam;
      }, function (error) {
        toggleButtonLoading($thirdStepNextBtn, false);
        const errorText = getError(error);
        toastr.warning(errorText);
      });
    });

  setSectionStepAttr(2);
});
