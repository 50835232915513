import {
  ENDPOINT,
  URLS
} from '../config';

$(document).ready(function () {
  const $codeForm = $('#code-form');

  $codeForm.submit(function (e) {

    // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
    const hiddenInputVal = $('[data-selector="gift-code-special-input"]').val();
    if (hiddenInputVal) return;

    e.preventDefault();

    const codeParsley = $codeForm.parsley();

    if (codeParsley.isValid()) {
      $.post(ENDPOINT.GIFT_CODE_REDEEM, {
        code: $('#gift-code').val()
      }).then(function () {
        if (window.hasActiveSubscription) {
          window.location.href = URLS.GIFT_REDEEM_SUCCESS;
        } else {
          // TODO Consider using url '/subscription-success/' instead
          window.location.href = URLS.SIGN_UP_PARENT_SUCCESS;
        }
      }, function (errorResp) {

        // TODO add support for spanish lang
        const error = JSON.parse(errorResp.responseText).error || 'Code is invalid';
        const $error = $('#gift-code-error');
        $error.html(error);
        $error.show();
      });
    } else {
      codeParsley.validate();
    }
  });
});
