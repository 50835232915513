$(document).ready(function () {
  const $parentsPage = $('[data-selector="parents-page"]');
  const $parentsVideosCarousel = $('[data-selector="parents-page-videos-carousel"]');
  if (!$parentsPage.length) return;


  const initVideosSLickSlider = () => {
    if ($parentsVideosCarousel && $parentsVideosCarousel.length) {
      $parentsVideosCarousel.not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 2
          }
        },{
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }]
      });
    }
  };

  initVideosSLickSlider();
});
