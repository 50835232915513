import toastr from 'toastr';

import {copyStringToBuffer} from '../utils';

$(document).ready(function () {
  $(document)
    .on('click', '.copy-link', (event) => {
      const str = event.target.baseURI;
      copyStringToBuffer(str);
      toastr.success('Link copied', '', {timeOut: 3000});
    });
});
