import {
  ACCOUNT_TYPES,
  ENDPOINT,
  GET_PARAMS,
  URLS,
} from '../config';
import {
  toggleButtonLoading,
  getError,
  getUrlParam,
  toggleStepProgressBar,
} from '../utils';


$(document).ready(() => {
  const $signUpParent = $('[data-selector="sign-up-parent-complete"]');
  if (!$signUpParent.length) return;

  const $signUpBtn =  $('[data-selector="sign-up-parent-complete-btn"]');
  const signUpForm =  '[data-selector="sign-up-parent-complete-form"]';
  const $signUpEmail =  $('[data-selector="sign-up-parent-complete-email-input"]');
  const $signUpPassword =  $('[data-selector="sign-up-parent-complete-password-input"]');
  const $signUpError =  $('[data-selector="sign-up-parent-complete-error"]');
  const policyCheckbox =  '[data-selector="sign-up-parent-complete-policy-checkbox"]';
  const $mailingCheckbox =  $('[data-selector="sign-up-parent-complete-mailing-checkbox"]');
  const $stepProgressBar = $('[data-selector="step-progress-bar"]');

  const applyProvidedEmail = () => {
    const providedEmail = getUrlParam(GET_PARAMS.EMAIL);
    if (!providedEmail) return;

    $signUpEmail.val(providedEmail);
  };

  const getParentSignUpData = () => {
    const email = $signUpEmail.val();
    const password = $signUpPassword.val();
    const isSubscribedToMailing = $mailingCheckbox.prop('checked');

    const data = {
      account_type: ACCOUNT_TYPES.PARENT,
      email: email,
      password: password,
      subscribe_to_emails: isSubscribedToMailing,
    };

    return data;
  };

  // event listeners
  $(document)
    // sign up form submit
    .on('submit', signUpForm, (e) => {
      e.preventDefault();

      // clear error
      $signUpError.html();
      $signUpError.hide();

      // validate form
      const signUpFormParsley = $(e.target).closest(signUpForm).parsley();
      signUpFormParsley.validate();
      if (!signUpFormParsley.isValid()) return;

      toggleButtonLoading($signUpBtn, true);

      // submit sign up request
      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify(getParentSignUpData())
      }).then(() => {
        window.location = `/${URLS.PARENT_SUBSCRIPTION_SUCCESS}/`;
      }, (error) => {
        toggleButtonLoading($signUpBtn, false);
        const errorText = getError(error);
        $signUpError.html(errorText);
        $signUpError.show();
      });
    })
    .on('change', policyCheckbox, (e) => {
      $signUpBtn.attr('disabled', !e.target.checked);
    });

  applyProvidedEmail();
  toggleStepProgressBar($stepProgressBar);
});
