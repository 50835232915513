import toastr from 'toastr/toastr';
import {
  getError,
  toggleButtonLoading
} from '../utils';
import {
  ENDPOINT,
  GET_PARAMS,
  LOCAL_STORAGE_KEYS
} from '../config';
import Qs from 'qs';


$(document).ready(function () {
  const schoolSubscriptionPlansPage = '/school/subscriptions/';

  //sign up steps blocks
  const signUpStepBlock = '[data-selector*="sign-up-step"]';

  const $profileInfoBlock = $('[data-selector*="profile-block"]');
  const $schoolInfoBlock = $('[data-selector*="school-block"]');

  // school info block elements
  const $country = $schoolInfoBlock.find('#country');
  const $state = $schoolInfoBlock.find('#state');
  const $school = $schoolInfoBlock.find('#school-name');

  // signup page elements
  const selectedSubscriptionPlan = '[data-selector="subscription-plan"]:checked';
  let selectedSubscriptionPlanId = null;
  const signUpError = '[data-selector="sign-up-error"]';

  //buttons
  const profileBlockNextBtn = '[data-selector="profile-block-next-btn"]';
  const schoolBlockBackBtn = '[data-selector="school-block-back-btn"]';
  const schoolBlockNextBtn = '[data-selector="school-block-next-btn"]';
  const gotItBtn = '[data-selector="got-it-btn"]';
  const selectPlanBtn = '[data-selector="select-plan"]';

  $(document)
    // profile block next btn >> go to school block
    .on('click', profileBlockNextBtn, function () {
      if (validateProfileInputs()) {
        $profileInfoBlock.hide();
        $schoolInfoBlock.show();
        ga('send', 'event', 'Registration', 'CompleteStep1', 'Teacher');
      }
    })
    // school info block back btn >> go back to profile block
    .on('click', schoolBlockBackBtn, function () {
      $profileInfoBlock.show();
      $schoolInfoBlock.hide();
    })
    // school info passed >> go to select plan page
    .on('click', schoolBlockNextBtn, function (event) {
      if (isSchoolInfoValid()) {
        signUp(event);
      }
    })
    // plan selected >> go to payment
    .on('click', selectPlanBtn, function () {
      selectedSubscriptionPlanId = $(selectedSubscriptionPlan).val();
      /* eslint-disable no-console */
      console.log('plan selected - ', selectedSubscriptionPlanId);
    })
    // email sent block, "got it" btn click
    .on('click', gotItBtn, function () {
      //not from Clever
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUri = urlParams.get('redirect_uri');

      if (redirectUri) {
        // redirect to application
        // User are always active
        window.location.href = redirectUri + '?code=0&message=User is active';
      } else {
        //redirect to LMS
        window.location.href = '/p/';
      }
    });
  // TODO add submit on enter
  // .keydown(function(e) {
  //   if (e.keyCode === 13) { // enter
  //     e.preventDefault();
  //
  //     if ($('.profile-inputs').is(":visible")) {
  //       let isValid = validateProfileInputs();
  //       if (isValid) {
  //         $profileInfoBlock.hide();
  //         $schoolInfoBlock.show();
  //       }
  //     } else {
  //       if (isSchoolInfoValid()) {
  //         //we need to put event object in signUp method to show server errors correctly
  //         signUp();
  //       }
  //     }
  //   }
  // });

  // country change handler
  $($country).change(function () {
    const state = $('.state-select');
    if ($country.val() === '1') {
      state.show();
      state.find('select').attr('required', 'required');
    } else {
      state.hide();
      state.find('select').removeAttr('required');
    }
  });

  $country.select2({width: 'resolve'});  // need to override the changed default
  $state.select2({width: '100%'});  // need to override the changed default

  const states = [];
  const schools = [];

  function getUrlVars() {
    const vars = {};

    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });

    return vars;
  }

  function setSelectChangeHandler($el, $childEl, childCollection, url) {
    $el.on('change', function () {
      setSelectChange($el, $childEl, childCollection, url);
    });
  }

  function setSelectChange($el, $childEl, childCollection, url) {
    const id = $el.val();

    if ($childEl.attr('disabled')) {
      return;
    }

    $.getJSON(url + id, function (data) {
      Array.prototype.splice.apply(childCollection, [0, childCollection.length].concat(data));
      if ($el === $country) {
        let optionsHtml = '<option disabled selected value=""></option>';
        childCollection.forEach(function (item) {
          optionsHtml += '<option value="' + item.id + '">' + item.name + '</option>';
        });
        $childEl.html(optionsHtml);
        $childEl.select2();
      } else {
        if ($childEl && typeof $childEl.autocomplete === 'function') {
          $childEl.autocomplete({
            source: data.map(function (item) {
              return item.name;
            })
          });
        }
      }
    });
  }

  function setChangeHandler($el, collection, url) {
    $el.on('input', function () {
      $.getJSON(url + '?q=' + $el.val(), function (data) {
        Array.prototype.splice.apply(collection, [0, collection.length].concat(data));
        if ($el.length && typeof $el.autocomplete === 'function') {
          $el.autocomplete({
            source: data.map(function (item) {
              return item.name;
            })
          });
        }
      });
    });
  }

  function validateProfileInputs() {
    const $profileInputs = $('.profile-inputs').find('.form-control');

    const profileParsley = $profileInputs.parsley();
    profileParsley.forEach(function (input) {
      input.validate();
    });

    return !profileParsley.some(function (input) {
      return !input.isValid();
    });
  }

  function isSchoolInfoValid() {
    const $schoolInputs = $('.school-inputs :input');
    const $profileInputs = $('.profile-inputs').find('.form-control');
    const profileParsley = $profileInputs.parsley();
    profileParsley.forEach(function (input) {
      input.validate();
    });

    const schoolParsley = $schoolInputs.parsley();
    schoolParsley.forEach(function (input) {
      input.validate();
    });

    let isValid = !profileParsley.some(function (input) {
      return !input.isValid();
    });
    isValid = isValid && !schoolParsley.some(function (input) {
      return !input.isValid();
    });

    return isValid;
  }

  function getDataForRequest() {
    const publicSchool = 0;
    const schoolAdmin = 3;

    const userData = {
      clever_id: $('#clever-id').val(),
      first_name: $('#first-name').val(),
      last_name: $('#last-name').val(),
      phone: $('#phone').val(),
      email: $('#email').val(),
      account_type: schoolAdmin,
      teacher_job_title: $('#teacher-job-title').val()
    };

    const schoolData = {
      name: $('#school-name').val(),
      school_type: publicSchool,
      city: $('#city').val(),
      country: $('#country').val(),
      state: $('#state').val(),
    };

    const data = {
      school: schoolData,
      user: userData,
      generate_demo_content: false
    };

    return data;
  }

  function showError(error, event) {
    const errors = JSON.parse(error.responseText);
    const $signupError = $(event.target).closest(signUpStepBlock).find(signUpError);
    $signupError.html(errors.error);
    $signupError.show();
  }

  function hideError(event) {
    const $signupError = $(event.target).closest(signUpStepBlock).find(signUpError);
    $signupError.hide();
  }

  function onSignUpSuccess($submitBtn) {
    const partnerCouponCode = localStorage.getItem(LOCAL_STORAGE_KEYS.PARTNER_COUPON_CODE);

    if (partnerCouponCode) {
      $.post(ENDPOINT.GIFT_CODE_REDEEM, {
        code: partnerCouponCode
      }).then(function () {
        toggleButtonLoading($submitBtn, false);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.PARTNER_COUPON_CODE);
        window.location = '/activate-license/success';
      }, function (error) {
        toggleButtonLoading($submitBtn, false);
        const errorText = getError(error);
        toastr.warning(errorText);
      });
      return;
    }

    // from Clever
    if (window.isClever) {
      ga('send', 'event', 'Registration', 'Clever', 'Teacher');
      const redirect_to = getUrlVars()['redirect_uri'];

      if (redirect_to) {
        window.location = redirect_to;
        return;
      }

      // registered > redirect to LMS
      window.location.replace('/p/');
      return;
    }

    // not form Clever
    ga('send', 'event', 'Registration', 'CompleteStep2', 'Teacher');
    window.location = schoolSubscriptionPlansPage;
  }

  function signUp(event) {
    hideError(event);
    const data = getDataForRequest();
    const $schoolBlockSubmitBtn = $(schoolBlockNextBtn);
    const $schoolBlockBackBtn = $(schoolBlockBackBtn);

    toggleButtonLoading($schoolBlockSubmitBtn, true);
    $schoolBlockBackBtn.hide();

    const queryObject = Qs.parse(location.search.substr(1));
    if (queryObject[GET_PARAMS.FROM_DISTRIBUTOR_SIGN_UP_FLOW] === 'true') {
      data.user.data = {'abort_welcome_email': true};
    }

    $.ajax({
      url: ENDPOINT.TEACHER_SIGN_UP,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data)
    }).then(function () {
      ga('send', 'event', 'Registration', 'CompleteStep2', 'Teacher');
      onSignUpSuccess($schoolBlockSubmitBtn);
    }, function (error) {
      showError(error, event);
      toggleButtonLoading($schoolBlockSubmitBtn, false);
      $schoolBlockBackBtn.show();
    });
  }

  if ($country.length) {
    setSelectChangeHandler($country, $state, states, '/api/school/states/?country_id=');
    setSelectChange($country, $state, states, '/api/school/states/?country_id=');
    setChangeHandler($school, schools, '/api/school/public-schools/');
  }
});
