$(document).ready(function () {
  const $subjectsSlider = $('#subject-carousel');

  if ($subjectsSlider && $subjectsSlider.length) {
    $subjectsSlider.not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      adaptiveHeight: true,
      swipeToSlide: true,
      pauseOnHover: true,
      dots: true,
      appendDots: '.subject-section__dots-container',
      dotsClass: 'subject-section__dots',
      customPaging : function(slider, i) {
        const title = $(slider.$slides[i]).data('title');
        return title;
      },
      nextArrow: '<span class="slick-next"/>',
      prevArrow: '<span class="slick-prev"/>'
    });
  }
});
