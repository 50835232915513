$(document).ready(function () {
  const $upgradeSubscription = $('[data-selector="upgrade-subscription"]');
  const $upgradeSubscriptionItem = $('[data-selector="upgrade-subscription-item"]');
  const $upgradeSubscriptionSlider = $('[data-selector="upgrade-subscription-slider"]');

  if (!$upgradeSubscription.length) return;

  const initUpgradeSlider = () => {
    $upgradeSubscriptionSlider.not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplay: false,
      autoplaySpeed: 3000,
    });
  };

  if ($upgradeSubscriptionItem.length > 1) initUpgradeSlider();

  $upgradeSubscriptionSlider.show();
});
